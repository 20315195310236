export const createElement = (id: string, tag: string) => {
  let element = document.getElementById(id);
  if (!element) {
    element = document.createElement(tag);
    element.setAttribute('id', id);
  }
  return element;
};

export const createDivElement = (id: string): Element => {
  return createElement(id, 'div');
};

export const prependElement = (target: Element, destinationId: string) => {
  const destination = document.getElementById(destinationId);
  if (destination) {
    if (!destination.contains(target)) {
      destination.prepend(target);
    }
  }
  return target;
};

export const scrollElement = (target: Element, change: number, duration: number, callback?: () => void) => {
  const [start, increment] = [target.scrollLeft, 20];
  let currentTime = 0;

  // t = current time
  // s = start value
  // c = change in value
  // d = duration
  const easeInOut = (t: number, s: number, c: number, d: number) => {
    t /= d/2;
    if (t < 1) return c/2*t*t + s;
    t--;
    return -c/2 * (t*(t-2) - 1) + s;
  };

  const scroll = () => {
    currentTime += increment;
    target.scrollLeft = easeInOut(currentTime, start, change, duration);
    if(currentTime < duration) {
      setTimeout(scroll, increment);
    } else {
      callback && callback();
    }
  };

  scroll();
};

export const getById = (id: string) => document.getElementById(id);