import { cloneDeep, trim } from "lodash";
import { isEven, isNotEmpty, reallyNotEmpty } from "../../common/utils/common";
import {
  BasicInfo,
  OrganizationOverview,
  PharmacyProfile,
  PharmacySectionName,
  PharmacySectionTocName,
} from "../../model/pharmacy";
import {
  Cell,
  findLastWordWithDot,
  in2pt,
  normalizeBgColors,
  splitAcquisitionContent,
  splitToPages,
  ungroupCellsIfDoesntFit,
} from "../common";

// commonly used colors
const COLOR1 = "#eb8a23";
const COLOR2 = "#565a5c";
const COLOR3 = "#04306A";
const COLOR4 = "#848787";
const COLOR5 = "#ffffff";
const COLOR6 = "#f2f2f2";
// ending slide colors
const COLOR7 = "#74A7B8";
const COLOR8 = "#0F7496";

interface Images {
  MainLogo: string;
  LargeBrLogo: string;
  EndingBgLeft: string;
  EndingBgRight: string;
  EversanaLogoWhite: string;
  HsiLogoWhite: string;
  LargeBlLogo: string;
}

let dateGlobal = "";

export const exportPdf = async (
  profile: PharmacyProfile,
  name: string,
  date: string
): Promise<void> => {
  dateGlobal = date;
  const images = (await import("./images")).default as Images;
  const pdfMake = (await import("pdfmake/build/pdfmake")).default;
  const pdfFonts = (await import("./fonts/vfs_fonts")).vfs;
  pdfMake.vfs = pdfFonts;
  pdfMake.fonts = {
    arial: {
      normal: "arial.ttf",
      bold: "arialbd.ttf",
      italics: "ariali.ttf",
      bolditalics: "arialbi.ttf",
    },
  };

  const getFooter = (currentPage: number, pageCount: number) => {
    if (currentPage === 1) {
      return {
        stack: [
          {
            text: "Health Strategies Insights by EVERSANA™",
            fontSize: 16,
            color: COLOR3,
            absolutePosition: { x: in2pt(4.42), y: 0 },
          },
        ],
      };
    } else if (currentPage === pageCount) {
      return {
        stack: [
          {
            text: currentPage.toString(),
            fontSize: 11,
            color: COLOR7,
            absolutePosition: { x: in2pt(0.7), y: 2 },
          },
          {
            text: "© 2020 EVERSANA.  All Rights Reserved",
            fontSize: 11,
            color: COLOR7,
            absolutePosition: { x: in2pt(1.2), y: 2 },
          },
        ],
        // margin: [0, 0, 0, 34]
      };
    } else {
      return {
        stack: [
          {
            text: currentPage.toString(),
            fontSize: 11,
            color: COLOR4,
            absolutePosition: { x: in2pt(0.7), y: 2 },
          },
          {
            text: "© 2020 EVERSANA.  All Rights Reserved",
            color: COLOR4,
            fontSize: 11,
            absolutePosition: { x: in2pt(1.2), y: 2 },
          },
          {
            image: images.MainLogo,
            width: in2pt(1.82),
            height: in2pt(0.32),
            absolutePosition: { x: in2pt(10.85), y: 0 },
          },
        ],
      };
    }
  };

  const info = getMetadata("Test");
  const pageSize = getLayout();

  const docDef = {
    ...info,
    ...pageSize,
    defaultStyle: {
      font: "arial",
    },
    content: [
      ...getStartingSlide(name, date, images),
      ...getTocSlide(profile),
      ...getInsightsSlides(profile, images),
      ...getAccountSummarySlides(profile, name),
      ...getSwotAnalysisSlides(profile, name),
      ...getOrgOverviewSlides(profile, name),
      ...getCorporateStrategySlides(profile, name),
      ...getBusinessPerformanceSlides(profile, name),
      ...getTherapeuticAreasSlides(profile, name),
      ...getServicesSlides(profile, name),
      ...getTechnologyOfferingsSlides(profile, name),
      ...getPayerNetworkSlides(profile, name),
      ...getBiopharmaNetworkSlides(profile, name),
      ...getLdnSlides(profile, name),
      ...getEndingSlide(images),
    ],
    footer: getFooter,
    styles: getStyles(),
  };
  pdfMake.createPdf(docDef as any).download(`${name}.pdf`);
};

const getStyles = () => ({
  title: {
    fontSize: 12,
    bold: true,
  },
  text: {
    fontSize: 10,
  },
  tableElement: {
    fontSize: 10,
  },
  header: {
    bold: true,
    fontSize: 10,
  },
  subHeader: {
    bold: true,
    fontSize: 10,
    color: "gray",
  },
  table: {
    margin: [0, 5, 0, 15],
  },
});

const getStartingSlide = (name: string, date: string, images: Images) => {
  return [
    {
      image: images.MainLogo,
      width: in2pt(6.91),
      height: in2pt(1.2),
      absolutePosition: { x: in2pt(0.66), y: in2pt(1.94) },
    },
    {
      image: images.LargeBrLogo,
      width: in2pt(4.73),
      height: in2pt(5.23),
      absolutePosition: { x: in2pt(8.61), y: in2pt(2.27) },
    },
    // title
    {
      text: name,
      fontSize: 26,
      color: COLOR3,
      bold: true,
      absolutePosition: { x: in2pt(0.67), y: in2pt(3.76) },
    },
    // subtitle 1
    {
      text: "Specialty Pharmacy Optimizer",
      fontSize: 20,
      color: COLOR1,
      absolutePosition: { x: in2pt(0.67), y: in2pt(5.51) },
    },
    // subtitle 2
    {
      text: "Specialty Pharmacy Profile",
      fontSize: 20,
      color: COLOR2,
      absolutePosition: { x: in2pt(0.67), y: in2pt(5.93) },
    },
    // date
    {
      text: "Retrieved in " + date,
      fontSize: 16,
      color: COLOR2,
      absolutePosition: { x: in2pt(0.67), y: in2pt(6.59) },
      pageBreak: "after",
    },
  ];
};
const getEndingSlide = (images: Images) => {
  return [
    {
      pageBreak: "before",
      table: {
        widths: [958],
        heights: [540],
        body: [[{ text: "", fillColor: COLOR8 }]],
      },
      layout: "noBorders",
    },
    {
      image: images.EndingBgLeft,
      width: in2pt(4.61),
      height: in2pt(4.3),
      absolutePosition: { x: in2pt(-0.79), y: in2pt(1.3) },
    },
    {
      image: images.EndingBgRight,
      width: in2pt(5.58),
      height: in2pt(5.81),
      absolutePosition: { x: in2pt(7.66), y: in2pt(1.55) },
    },
    {
      image: images.EversanaLogoWhite,
      width: in2pt(1.76),
      height: in2pt(0.3),
      absolutePosition: { x: in2pt(10.83), y: in2pt(7.01) },
    },
    {
      image: images.HsiLogoWhite,
      width: in2pt(7.74),
      height: in2pt(1.33),
      absolutePosition: { x: in2pt(2.8), y: in2pt(3.08) },
    },
    {
      text: [
        { text: "Office: ", bold: true },
        "790 Township Line Road, Suite 300, Yardley, PA 19067  |  v: 609.397.5282  f: 609.397.5283",
        "\nEVERSANA.com",
      ],
      fontSize: 9,
      color: COLOR5,
      absolutePosition: { x: in2pt(3.92), y: in2pt(4.4) },
    },
    {
      table: {
        widths: [in2pt(11.92)],
        body: [
          [
            {
              text: `This Report is licensed only to the original party licensed by Health Strategies Insights, (respectively the “Licensee” and “Health Strategies Insights”) and is subject to a binding license agreement between Licensee and Health Strategies Insights. Health Strategies Insights continues to retain title to and ownership of this Report.   All copies and portions of this Report, in any form, belong to Health Strategies Insights, which retains all rights not expressly granted. Licensee is entitled to use this Report solely for its own internal business purposes and is prohibited from modifying, translating, or otherwise creating derivative works based on this Report. Licensee is further prohibited from licensing, selling, leasing, distributing, lending or otherwise transferring this Report to any third party. Licensee may not make any copies of Health Strategies Insights' Reports except for internal distribution purposes as described and agreed to in the license agreement provided that all such copies are reproduced with and incorporate all of Health Strategies Insights' protective notices, including this and all copyright notices. Nothing in this Report and/or any license agreement applicable thereto constitute a waiver of Health Strategies Insights' rights under United States copyright law or any other law.  This information has been obtained from sources which Health Strategies Insights believes to be reliable but we do not guarantee the accuracy or completeness of this information.`,
              fontSize: 8,
              color: COLOR5,
            },
          ],
        ],
      },
      layout: "noBorders",
      absolutePosition: { x: in2pt(0.75), y: in2pt(5.33) },
    },
    /*     {
      text: '© 2020 EVERSANA.  All Rights Reserved',
      fontSize: 11,
      color: COLOR7,
      absolutePosition: {x: in2pt(1.2), y: in2pt(7.01)}
    } */
  ];
};
const getTocSlide = (profile: PharmacyProfile) => {
  const getRef = (ref: string) => `${ref}-1`;
  const getTocSlideRow = (
    { ref, name }: { ref: string; name: string },
    rowIdx: number
  ) => {
    const bgColor = isEven(rowIdx) ? COLOR6 : COLOR5;
    const options = {
      fontSize: 16,
      color: COLOR2,
      fillColor: bgColor,
    };
    return [
      {
        text: name,
        ...options,
      },
      {
        pageReference: getRef(ref),
        alignment: "right",
        ...options,
      },
    ];
  };
  const toc: { name: string; ref: string }[] = [];

  if (isNotEmpty(profile.insights)) {
    toc.push({
      ref: PharmacySectionName.Insights,
      name: PharmacySectionTocName.Insights,
    });
  }
  if (isNotEmpty(profile.accountSummary)) {
    toc.push({
      ref: PharmacySectionName.AccountSummary,
      name: PharmacySectionTocName.AccountSummary,
    });
  }
  if (isNotEmpty(profile.swotAnalysis)) {
    toc.push({
      ref: PharmacySectionName.SwotAnalysis,
      name: PharmacySectionTocName.SwotAnalysis,
    });
  }
  if (
    isNotEmpty(profile.organizationOverview) ||
    isNotEmpty(profile.basicInfo)
  ) {
    toc.push({
      ref: PharmacySectionName.Overview,
      name: PharmacySectionTocName.Overview,
    });
  }
  if (isNotEmpty(profile.corporateStrategy)) {
    toc.push({
      ref: PharmacySectionName.CorporateStrategy,
      name: PharmacySectionTocName.CorporateStrategy,
    });
  }
  if (isNotEmpty(profile.businessPerformance)) {
    toc.push({
      ref: PharmacySectionName.BusinessPerformance,
      name: PharmacySectionTocName.BusinessPerformance,
    });
  }
  if (isNotEmpty(profile.therapeuticAreas)) {
    toc.push({
      ref: PharmacySectionName.TherapeuticAreas,
      name: PharmacySectionTocName.TherapeuticAreas,
    });
  }
  if (isNotEmpty(profile.services)) {
    toc.push({
      ref: PharmacySectionName.Services,
      name: PharmacySectionTocName.Services,
    });
  }
  if (isNotEmpty(profile.technologyOfferings)) {
    toc.push({
      ref: PharmacySectionName.TechnologyOfferings,
      name: PharmacySectionTocName.TechnologyOfferings,
    });
  }
  if (isNotEmpty(profile.payerNetwork)) {
    toc.push({
      ref: PharmacySectionName.PayerNetwork,
      name: PharmacySectionTocName.PayerNetwork,
    });
  }
  if (isNotEmpty(profile.biopharmaNetwork)) {
    toc.push({
      ref: PharmacySectionName.BiopharmaNetwork,
      name: PharmacySectionTocName.BiopharmaNetwork,
    });
  }
  if (isNotEmpty(profile.ldn)) {
    toc.push({
      ref: PharmacySectionName.LimitedDistributionNetwork,
      name: PharmacySectionTocName.LimitedDistributionNetwork,
    });
  }

  const rows = toc.map(getTocSlideRow);

  return [
    {
      text: "Table of Contents",
      fontSize: 26,
      bold: true,
      color: COLOR3,
      absolutePosition: { x: in2pt(0.67), y: in2pt(0.56) },
    },
    {
      layout: "noBorders",
      table: {
        widths: [in2pt(10), in2pt(2)],
        body: rows,
      },
      pageBreak: "after",
      absolutePosition: { x: in2pt(0.75), y: in2pt(1.17) },
    },
  ];
};
const getInsightsSlides = (profile: PharmacyProfile, images: Images) => {
  if (isNotEmpty(profile?.insights?.content)) {
    const words = profile!.insights!.content!.split(" ");
    const maxSize = 150;
    let slideContentSize = words.length;
    let numSlides = 1;
    while (slideContentSize > maxSize) {
      numSlides++;
      slideContentSize = Math.ceil(slideContentSize / numSlides);
    }
    const res = [];
    for (let i = 0, num = 1; i < words.length; i += slideContentSize, num++) {
      const slideContent = words.slice(i, i + slideContentSize).join(" ");
      res.push(...getInsightsSlide(slideContent, num, images));
    }
    return res;
  } else {
    return [];
  }
};
const getInsightsSlide = (
  content: string,
  slideNumber: number,
  images: Images
) => {
  return [
    {
      canvas: [
        {
          type: "rect",
          x: 0,
          y: 0,
          h: in2pt(6.75),
          w: in2pt(4.76),
          color: COLOR3,
        },
        {
          type: "rect",
          color: COLOR3,
          x: 0,
          y: in2pt(6.75),
          h: 4,
          w: in2pt(13.33),
        },
      ],
    },
    {
      image: images.LargeBlLogo,
      height: in2pt(4.16),
      width: in2pt(3.41),
      absolutePosition: { x: 0, y: in2pt(2.64) },
    },
    {
      table: {
        widths: [in2pt(3.51)],
        heights: [in2pt(2)],
        body: [
          [
            {
              text: "EVERSANA's \nInsights",
              color: COLOR5,
              fontSize: 26,
              alignment: "center",
            },
          ],
        ],
      },
      layout: "noBorders",
      absolutePosition: { x: in2pt(0.67), y: in2pt(3) },
    },
    {
      table: {
        widths: [in2pt(7.44)],
        body: [
          [
            {
              id: `${PharmacySectionName.Insights}-${slideNumber}`,
              text: content,
              color: COLOR3,
              fontSize: 18,
            },
          ],
        ],
      },
      layout: "noBorders",
      absolutePosition: { x: in2pt(5.34), y: in2pt(0.33) },
    },
  ];
};
const getAccountSummarySlides = (profile: PharmacyProfile, spName: string) => {
  if (isNotEmpty(profile?.accountSummary?.content)) {
    return getListSlides(
      "Account Summary and Analysis",
      profile!.accountSummary!.content!,
      spName,
      PharmacySectionName.AccountSummary,
      18
    ); // title differs from PharmacySectionTocName.AccountSummary, hardcode is used
  } else {
    return [];
  }
};

const getListSlides = (
  title: string,
  content: string[],
  spName: string,
  sectionName: string,
  fontSize: number
) => {
  const countLinesInItem = (item: string) => {
    const charsInLine = 80;
    return Math.ceil(item.length / charsInLine);
  };
  const linesPerSlide = 10;
  let res: string[][] = [];
  let slideContent: string[] = [];
  let linesInCurrentSlide = 0;
  // let slideNum = 0;
  content.forEach((item) => {
    const lines = countLinesInItem(item);
    if (
      linesInCurrentSlide + lines > linesPerSlide &&
      isNotEmpty(slideContent)
    ) {
      res.push(slideContent);
      slideContent = [];
      linesInCurrentSlide = 0;
      // slideNum++;
    }
    linesInCurrentSlide += lines;
    slideContent.push(item);
  });
  // push whatever remains in buffer when we finished traversing the contents
  if (isNotEmpty(slideContent)) {
    res.push(slideContent);
    // slideNum++;
  }
  const slides = res.map((c, i) => {
    return getListSlide(title, c, spName, sectionName, i + 1, fontSize);
  });
  if (slides[0]) {
    // console.log("FIRST SLIDE BEFORE: ", slides[0]);
    // @ts-ignore
    // slides[0].unshift({ kind: "reference" as "reference", pageReference: 'header1' })
    // console.log("FIRST SLIDE AFTER: ", slides[0]);
  }
  return slides;
};

const getListSlide = (
  title: string,
  content: string[],
  spName: string,
  sectionName: string,
  slideNumber: number,
  fontSize: number
) => {
  return [
    ...getHeader(spName),
    getSlideTitle(title, sectionName, slideNumber),
    {
      ul: content,
      style: {
        fontSize: fontSize,
        color: COLOR2,
        lineHeight: 1.5,
        margin: [in2pt(0.1), in2pt(0.05), in2pt(0.1), in2pt(0.05)],
      },
      absolutePosition: { x: in2pt(0.67), y: in2pt(1.54) },
    },
  ];
};
const getOrgOverviewSlides = (profile: PharmacyProfile, spName: string) => {
  const genericPlaceholder = "Information not currently available";
  const orgOverview = profile?.organizationOverview;
  const basicInfo = profile?.basicInfo;
  let accreditationTypes: string[] = [genericPlaceholder];

  if (isNotEmpty(orgOverview?.accreditationType)) {
    accreditationTypes = orgOverview!
      .accreditationType!.map((x) => x.content)
      .filter(reallyNotEmpty);
  }
  return [
    ...getParentOrgAndDescriptionSlides(orgOverview, basicInfo, spName),
    ...getMarketCoverageSlides(genericPlaceholder, orgOverview, spName),
    ...getOrganizationSlides(genericPlaceholder, basicInfo, spName),
    ...getAccreditationSlides(accreditationTypes, spName),
  ];
};
const getParentOrgAndDescriptionSlides = (
  orgOverview: OrganizationOverview | null | undefined,
  basicInfo: BasicInfo | null | undefined,
  spName: string
) => {
  const genericPlaceholder = "Not Applicable";
  const mergersPlaceholder =
    "No mergers or acquisitions in 2018 or 2019 were identified for this company.";

  let parentOrgAndDescriptionData = [];

  const parentOrgName = isNotEmpty(orgOverview?.parentOrganizationName)
    ? orgOverview!.parentOrganizationName!
    : genericPlaceholder;

  const parentOrgNameContent = getOrgOverviewRegularSectionContent(
    "Parent Organization:",
    parentOrgName
  );

  parentOrgAndDescriptionData.push(parentOrgNameContent);

  const ownershipType = isNotEmpty(basicInfo?.ownershipType)
    ? basicInfo!.ownershipType!
    : genericPlaceholder;

  const ownershipTypeContent = getOrgOverviewRegularSectionContent(
    "Ownership Type:",
    ownershipType
  );

  parentOrgAndDescriptionData.push(ownershipTypeContent);

  let acquisitionContentRemainder: string[] = [];

  const acquisition = isNotEmpty(orgOverview?.acquisition)
    ? orgOverview!.acquisition!
    : mergersPlaceholder;

  const acWords = acquisition.replace(/\r?\n|\r/gm, " \n").split(" ");
  let firstSlideLimit = findLastWordWithDot(acWords.slice(0, 100)) + 1;
  const doesFitIntoFirstSlide = acWords.length < firstSlideLimit;
  let firstSlideAcWords: string[] = [];
  if (doesFitIntoFirstSlide) {
    firstSlideAcWords = acWords;
  } else {
    firstSlideAcWords = acWords.slice(0, firstSlideLimit);
    acquisitionContentRemainder = splitAcquisitionContent(
      acWords.slice(firstSlideLimit, acWords.length)
    );
  }
  const firstSlideAc = getOrgOverviewRegularSectionContent(
    "Mergers and Acquisition:",
    firstSlideAcWords.join(" ")
  );
  parentOrgAndDescriptionData.push(firstSlideAc);

  const firstSlide = getOrgOverviewSlide(
    parentOrgAndDescriptionData.map((x) => [x]),
    spName,
    "Parent Organization and Description",
    1
  );

  const additionalSlides = acquisitionContentRemainder
    .map((c) => {
      const cell = getOrgOverviewRegularSectionContent(
        "Mergers and Acquisition:",
        c
      );
      return getOrgOverviewSlide(
        [[cell]],
        spName,
        "Parent Organization and Description"
      );
    })
    .flat();

  return [...firstSlide, ...additionalSlides];
};
const getOrgOverviewSlide = (
  rows: Cell[][],
  spName: string,
  subtitle: string,
  slideNumber?: number
) => {
  return [
    ...getHeader(spName),
    getSlideTitle(
      PharmacySectionTocName.Overview,
      PharmacySectionName.Overview,
      slideNumber
    ),
    {
      kind: "text",
      text: subtitle,
      color: COLOR3,
      bold: true,
      fontSize: 20,
      absolutePosition: { x: in2pt(0.67), y: in2pt(1.54) },
    },
    {
      layout: "noBorders",
      table: {
        widths: [in2pt(12)],
        body: rows,
      },
      absolutePosition: { x: in2pt(0.67), y: in2pt(2.1) },
    },
  ];
};
const getOrgOverviewRegularSectionContent = (
  subtitle: string,
  content: string
) => {
  return {
    kind: "stack" as "stack",
    stack: [
      {
        kind: "list" as "list",
        ul: [
          {
            kind: "text" as "text",
            text: subtitle,
            // margin: [in2pt(0.1), in2pt(0.05), in2pt(0.1), in2pt(0.05)],
          },
        ],
        style: {
          bold: true,
          color: COLOR2,
          fontSize: 18,
        },
      },
      {
        kind: "text" as "text",
        text: content,
        fontSize: 18,
        color: COLOR2,
        margin: [in2pt(0.3), in2pt(0.05), in2pt(0.1), in2pt(0.05)],
      },
    ],
  };
};
const getMarketCoverageSlides = (
  genericPlaceholder: string,
  orgOverview: OrganizationOverview | null | undefined,
  spName: string
) => {
  const content = [];

  const marketCoverageDescription = isNotEmpty(
    orgOverview?.marketCoverageDescription
  )
    ? orgOverview!.marketCoverageDescription!
    : genericPlaceholder;

  const mcDesc = getOrgOverviewRegularSectionContent(
    "Description:",
    marketCoverageDescription
  );
  content.push(mcDesc);

  const orgFacility = isNotEmpty(orgOverview?.organizationFacility)
    ? orgOverview!.organizationFacility!
    : genericPlaceholder;

  const orgF = getOrgOverviewRegularSectionContent(
    "Organization Facility:",
    orgFacility
  );

  content.push(orgF);

  const facilityName = isNotEmpty(orgOverview?.facilityName)
    ? orgOverview!.facilityName!
    : genericPlaceholder;

  const facN = getOrgOverviewRegularSectionContent(
    "Facility Name:",
    facilityName
  );

  content.push(facN);

  const marketCoverageCount = isNotEmpty(orgOverview?.marketCoverageCount)
    ? orgOverview!.marketCoverageCount!.toString()
    : genericPlaceholder;

  const mcc = getOrgOverviewRegularSectionContent(
    "Count:",
    marketCoverageCount
  );

  content.push(mcc);

  const splitted = splitToPages(
    content.map((x) => [x]),
    700
  );
  return splitted
    .map((x) => {
      return getOrgOverviewSlide(x, spName, "Market Coverage");
    })
    .flat();
};

const getOrganizationSlides = (
  genericPlaceholder: string,
  basicInfo: BasicInfo | null | undefined,
  spName: string
) => {
  const content = [];

  let address = genericPlaceholder;

  if (isNotEmpty(basicInfo?.address1)) {
    const a2 = basicInfo?.address2 ? basicInfo.address2 + "\n" : "";
    const cityStateZip =
      "" +
      (basicInfo?.city ?? "") +
      " " +
      (basicInfo?.state ?? "") +
      " " +
      (basicInfo?.zipcode ?? "");
    address = basicInfo!.address1! + `\n` + a2 + cityStateZip;
  }

  const addrContent = getOrgOverviewRegularSectionContent("Address:", address);
  content.push(addrContent);

  const website = isNotEmpty(basicInfo?.website)
    ? basicInfo?.website!
    : genericPlaceholder;

  const wsContent = getOrgOverviewRegularSectionContent("Website:", website);
  content.push(wsContent);

  const phone1 = isNotEmpty(basicInfo?.phone1)
    ? basicInfo!.phone1!
    : genericPlaceholder;

  const phoneContent = getOrgOverviewRegularSectionContent("Phone:", phone1);
  content.push(phoneContent);

  const foundedYear = isNotEmpty(basicInfo?.foundedYear)
    ? basicInfo!.foundedYear!.toString()
    : genericPlaceholder;

  const fyContent = getOrgOverviewRegularSectionContent(
    "Founded Year:",
    foundedYear
  );
  content.push(fyContent);

  const splitted = splitToPages(
    content.map((x) => [x]),
    700
  );
  return splitted
    .map((x) => {
      return getOrgOverviewSlide(x, spName, "Organization");
    })
    .flat();
};

const getAccreditationSlides = (
  accreditationTypes: string[],
  spName: string
) => {
  const listItems = accreditationTypes.map((x) => {
    return {
      kind: "text" as "text",
      text: x,
      margin: [in2pt(0.1), in2pt(0.05), in2pt(0.1), in2pt(0.05)],
    };
  });
  if (isNotEmpty(accreditationTypes)) {
    const content = {
      kind: "list" as "list",
      ul: listItems,
      style: {
        color: COLOR2,
        fontSize: 18,
      },
    };
    return [getOrgOverviewSlide([[content]], spName, "Accreditation Type")];
  } else {
    return [];
  }
};

const getSwotAnalysisSlides = (profile: PharmacyProfile, spName: string) => {
  if (isNotEmpty(profile?.swotAnalysis)) {
    const strengths = trim(profile?.swotAnalysis?.strength?.content ?? "");
    const weaknesses = trim(profile?.swotAnalysis?.weakness?.content ?? "");
    const opportunities = trim(
      profile?.swotAnalysis?.opportunity?.content ?? ""
    );
    const threats = trim(profile?.swotAnalysis?.threat?.content ?? "");

    const makeSwotCell = (
      subtitle: string,
      content: string,
      fillColor: string
    ) => {
      const cell = {
        kind: "stack" as "stack",
        stack: [
          {
            kind: "text" as "text",
            text: subtitle,
            fontSize: 18,
            color: COLOR3,
            lineHeight: 1.5,
            bold: true,
            margin: [in2pt(0.1), in2pt(0.05), in2pt(0.1), in2pt(0.05)],
          },
          {
            kind: "list" as "list",
            ul: [
              {
                kind: "text" as "text",
                text: content,
                margin: [in2pt(0.1), in2pt(0.05), in2pt(0.1), in2pt(0.05)],
              },
            ],
            style: {
              fontSize: 16,
              color: COLOR2,
            },
          },
        ],
        style: {
          fillColor: fillColor,
        },
      };
      return cell;
    };
    const sCell = makeSwotCell("Strengths", strengths, COLOR5);
    const wCell = makeSwotCell("Weaknesses", weaknesses, COLOR5);
    const oCell = makeSwotCell("Opportunities", opportunities, COLOR6);
    const tCell = makeSwotCell("Threats", threats, COLOR6);
    const rows = [
      [sCell, wCell],
      [oCell, tCell],
    ];
    const maxContentLengthPerSlide = 700;
    const maybeUngrouped = ungroupCellsIfDoesntFit(
      rows,
      maxContentLengthPerSlide
    );
    const splitted = splitToPages(maybeUngrouped, maxContentLengthPerSlide);

    const normalized = splitted.map((rows) => {
      if (rows.length === 1) {
        return rows.map((row) =>
          row.map((cell) => {
            // @ts-ignore
            if (cell.style?.fillColor != null) {
              let normalized = cloneDeep(cell);
              //@ts-ignore
              normalized.style!.fillColor = COLOR5;
              return normalized;
            } else {
              return cell;
            }
          })
        );
      } else {
        return rows;
      }
    });

    return normalized
      .map((rows, i) => {
        return getSwotAnalysisSlide(rows, spName, i + 1);
      })
      .flat();
  } else {
    return [];
  }
};

const getSwotAnalysisSlide = (
  rows: Cell[][],
  spName: string,
  slideNumber: number
) => {
  const w = isNotEmpty(rows) && rows[0].length === 1 ? ["*"] : ["*", "*"];
  return [
    ...getHeader(spName),
    getSlideTitle(
      PharmacySectionTocName.SwotAnalysis,
      PharmacySectionName.SwotAnalysis,
      slideNumber
    ),
    {
      layout: "noBorders",
      table: {
        widths: w,
        body: rows,
      },
      absolutePosition: { x: in2pt(0.67), y: in2pt(1.54) },
    },
  ];
};

const getCorporateStrategySlides = (
  profile: PharmacyProfile,
  spName: string
) => {
  if (isNotEmpty(profile?.corporateStrategy)) {
    const content = profile!.corporateStrategy!.map((x) => ({
      goal: (x?.content?.goal ?? "").replace(/\r?\n|\r/gm, " "),
      tactic: (x?.content?.tactic ?? "").replace(/\r?\n|\r/gm, " "),
    }));

    const rows = content.map(({ goal, tactic }, index) => {
      const bgColor = isEven(index) ? COLOR6 : COLOR5;
      const contentOptions = {
        color: COLOR2,
        fontSize: 18,
        fillColor: bgColor,
        margin: [in2pt(0.1), in2pt(0.05), in2pt(0.1), in2pt(0.05)],
      };
      const cell1 = {
        kind: "list" as "list",
        ul: [
          {
            kind: "text" as "text",
            text: goal,
          },
        ],
        ...contentOptions,
      };
      const cell2 = {
        kind: "list" as "list",
        ul: [
          {
            kind: "text" as "text",
            text: tactic,
          },
        ],
        ...contentOptions,
      };
      return [cell1, cell2];
    });
    const maxContentLengthPerSlide = 600;
    const splittedRows = splitToPages(rows, maxContentLengthPerSlide);
    const normalizedSplitted = normalizeBgColors(splittedRows, COLOR5);
    return normalizedSplitted
      .map((rows, i) => {
        return getCorporateStrategySlide(rows, spName, i + 1);
      })
      .flat();
  } else {
    return [];
  }
};

const getCorporateStrategySlide = (
  rows: Cell[][],
  spName: string,
  slideNumber: number
) => {
  const headerOptions = {
    fontSize: 18,
    color: COLOR3,
    bold: true,
  };
  const headerRow = [
    { kind: "text" as "text", text: "Goals", ...headerOptions },
    { kind: "text" as "text", text: "Tactics", ...headerOptions },
  ];
  rows.unshift(headerRow);
  return [
    ...getHeader(spName),
    getSlideTitle(
      PharmacySectionTocName.CorporateStrategy,
      PharmacySectionName.CorporateStrategy,
      slideNumber
    ),
    {
      layout: "noBorders",
      table: {
        widths: [in2pt(3), in2pt(9)],
        body: rows,
      },
      absolutePosition: { x: in2pt(0.67), y: in2pt(1.54) },
    },
  ];
};

const getBusinessPerformanceSlides = (
  profile: PharmacyProfile,
  spName: string
) => {
  const firstRowOptions = {
    fontSize: 16,
    color: COLOR2,
    fillColor: COLOR5,
    bold: true,
  };
  const secondRowOptions = {
    fontSize: 16,
    color: COLOR2,
    fillColor: COLOR5,
  };

  if (isNotEmpty(profile?.businessPerformance)) {
    const content = profile!.businessPerformance!.map((x) => ({
      year: x?.content?.financialYear?.toString() ?? "",
      description: x?.content?.description ?? "",
    }));
    const rows = content.map(({ year, description }) => {
      const cell1 = {
        kind: "text" as "text",
        text: year,
        ...firstRowOptions,
      };
      const cell2 = {
        kind: "text" as "text",
        text: description,
        ...secondRowOptions,
      };
      return [cell1, cell2];
    });
    return getBusinessPerformanceSlide(rows, spName);
  } else {
    return [];
  }
};

const getBusinessPerformanceSlide = (rows: Cell[][], spName: string) => {
  return [
    ...getHeader(spName),
    getSlideTitle(
      PharmacySectionTocName.BusinessPerformance,
      PharmacySectionName.BusinessPerformance,
      1
    ),
    {
      layout: "noBorders",
      table: {
        widths: [in2pt(2), in2pt(10)],
        body: rows,
      },
      absolutePosition: { x: in2pt(0.67), y: in2pt(1.54) },
    },
  ];
};

const getTherapeuticAreasSlides = (
  profile: PharmacyProfile,
  spName: string
) => {
  if (isNotEmpty(profile?.therapeuticAreas)) {
    const content = profile!.therapeuticAreas!.map((x) => ({
      name: x?.content?.name ?? "",
      treatmentCategory: x?.content?.treatmentCategory ?? "",
    }));
    const rows = content.map(({ name, treatmentCategory }, index) => {
      const bgColor = isEven(index) ? COLOR6 : COLOR5;
      const contentOptions = {
        fillColor: bgColor,
        color: COLOR2,
        fontSize: 20,
      };
      const cell1 = {
        kind: "text" as "text",
        text: treatmentCategory,
        ...contentOptions,
      };
      const cell2 = {
        kind: "text" as "text",
        text: name,
        ...contentOptions,
      };
      return [cell1, cell2];
    });
    const maxContentLengthPerSlide = 230;
    const splittedRows = splitToPages(rows, maxContentLengthPerSlide);
    return splittedRows.map((rows, i) => {
      return getTherapeuticAreasSlide(rows, spName, i + 1);
    });
  } else {
    return [];
  }
};

const getTherapeuticAreasSlide = (
  rows: Cell[][],
  spName: string,
  slideNumber: number
) => {
  return [
    ...getHeader(spName),
    getSlideTitle(
      PharmacySectionTocName.TherapeuticAreas,
      PharmacySectionName.TherapeuticAreas,
      slideNumber
    ),
    {
      layout: "noBorders",
      table: {
        widths: [in2pt(6), in2pt(6)],
        body: rows,
      },
      absolutePosition: { x: in2pt(0.67), y: in2pt(1.54) },
    },
  ];
};

const getServicesSlides = (profile: PharmacyProfile, spName: string) => {
  if (isNotEmpty(profile?.services)) {
    const content = profile!
      .services!.map((x) => x?.content?.name)
      .filter(reallyNotEmpty);
    return getListSlides(
      PharmacySectionTocName.Services,
      content,
      spName,
      PharmacySectionName.Services,
      20
    );
  } else {
    return [];
  }
};
const getTechnologyOfferingsSlides = (
  profile: PharmacyProfile,
  spName: string
) => {
  if (isNotEmpty(profile?.technologyOfferings)) {
    const content = profile!.technologyOfferings!.map((x) => ({
      name: x?.content?.name ?? "",
      description: x?.content?.description ?? "",
    }));
    const rows = content.map(({ name, description }, index) => {
      const bgColor = isEven(index) ? COLOR5 : COLOR6;
      const rowOptions = {
        fillColor: bgColor,
        color: COLOR2,
        fontSize: 16,
      };
      const cell1 = {
        kind: "text" as "text",
        text: name,
        ...rowOptions,
        bold: true,
      };
      const cell2 = {
        kind: "text" as "text",
        text: description,
        ...rowOptions,
      };
      return [cell1, cell2];
    });
    const maxContentLengthPerSlide = 1200;
    const splittedRows = splitToPages(rows, maxContentLengthPerSlide);
    const normalizedSplitted = normalizeBgColors(splittedRows, COLOR5);
    return normalizedSplitted.map((rows, i) => {
      return getTechnologyOfferingsSlide(rows, spName, i + 1);
    });
  } else {
    return [];
  }
};

const getTechnologyOfferingsSlide = (
  rows: Cell[][],
  spName: string,
  slideNumber: number
) => {
  return [
    ...getHeader(spName),
    getSlideTitle(
      PharmacySectionTocName.TechnologyOfferings,
      PharmacySectionName.TechnologyOfferings,
      slideNumber
    ),
    {
      layout: "noBorders",
      table: {
        widths: [in2pt(3), in2pt(9)],
        body: rows,
      },
      absolutePosition: { x: in2pt(0.67), y: in2pt(1.54) },
    },
  ];
};
const getPayerNetworkSlides = (profile: PharmacyProfile, spName: string) => {
  if (isNotEmpty(profile?.payerNetwork)) {
    const content = profile!
      .payerNetwork!.map((x) => x?.content?.name)
      .filter(reallyNotEmpty);
    return getListSlides(
      PharmacySectionTocName.PayerNetwork,
      content,
      spName,
      PharmacySectionName.PayerNetwork,
      20
    );
  } else {
    return [];
  }
};
const getBiopharmaNetworkSlides = (
  profile: PharmacyProfile,
  spName: string
) => {
  if (isNotEmpty(profile?.biopharmaNetwork)) {
    const content = profile!
      .biopharmaNetwork!.map((x) => x?.content?.name)
      .filter(reallyNotEmpty);
    return getListSlides(
      PharmacySectionTocName.BiopharmaNetwork,
      content,
      spName,
      PharmacySectionName.BiopharmaNetwork,
      20
    );
  } else {
    return [];
  }
};
const getLdnSlides = (profile: PharmacyProfile, spName: string) => {
  if (isNotEmpty(profile?.ldn)) {
    const content = profile!.ldn!.map((x) => ({
      brandName: x?.content?.brandName ?? "",
      genericName: x?.content?.genericName ?? "",
      manufacturer: x?.content?.manufacturer ?? "",
    }));
    const rows = content.map(
      ({ brandName, genericName, manufacturer }, index) => {
        const bgColor = isEven(index) ? COLOR6 : COLOR5;
        const contentOptions = {
          fillColor: bgColor,
          color: COLOR2,
          fontSize: 20,
        };
        const cell1 = {
          kind: "text" as "text",
          text: brandName,
          ...contentOptions,
        };
        const cell2 = {
          kind: "text" as "text",
          text: genericName,
          ...contentOptions,
        };
        const cell3 = {
          kind: "text" as "text",
          text: manufacturer,
          ...contentOptions,
        };
        return [cell1, cell2, cell3];
      }
    );
    const maxContentLengPerSlide = 135;
    const splittedRows = splitToPages(rows, maxContentLengPerSlide);
    return splittedRows
      .map((rows, i) => {
        return getLdnSlide(rows, spName, i + 1);
      })
      .flat();
  } else {
    return [];
  }
};

const getLdnSlide = (rows: Cell[][], spName: string, slideNumber: number) => {
  const headerOptions = {
    bold: true,
    fontSize: 20,
    color: COLOR2,
  };

  const headerRow = [
    { kind: "text" as "text", text: "Brand Name", ...headerOptions },
    { kind: "text" as "text", text: "Generic Name", ...headerOptions },
    {
      kind: "text" as "text",
      text: "Biopharmaceutical Company",
      ...headerOptions,
    },
  ];
  rows.unshift(headerRow);
  return [
    ...getHeader(spName),
    getSlideTitle(
      PharmacySectionTocName.LimitedDistributionNetwork,
      PharmacySectionName.LimitedDistributionNetwork,
      slideNumber
    ),
    {
      layout: "noBorders",
      table: {
        widths: [in2pt(4), in2pt(4), in2pt(4)],
        body: rows,
      },
      absolutePosition: { x: in2pt(0.67), y: in2pt(1.54) },
    },
  ];
};

const getSlideTitle = (
  title: string,
  sectionName?: string,
  slideNumber?: number
) => {
  const t = {
    text: title,
    absolutePosition: { x: in2pt(0.67), y: in2pt(0.97) },
    fontSize: 26,
    bold: true,
    color: COLOR3,
  };
  if (sectionName != null && slideNumber != null) {
    return {
      id: `${sectionName}-${slideNumber}`,
      ...t,
    };
  } else {
    return t;
  }
};

const getHeader = (name: string) => {
  const options = {
    color: COLOR2,
    fontSize: 12,
    italics: true,
    noWrap: true,
  };
  return [
    {
      ...options,
      text: name,
      absolutePosition: { x: in2pt(0.67), y: in2pt(0.14) },
      pageBreak: "before",
    },
    {
      ...options,
      text: "Retrieved in " + dateGlobal,
      absolutePosition: { x: in2pt(10.5), y: in2pt(0.14) },
    },
  ];
};

const getMetadata = (spName: string) => {
  return {
    info: {
      title: spName,
      author: "EVERSANA",
      subject: "Specialty Pharmacy Profile",
    },
  };
};

const getLayout = () => {
  // Layout should be 13.3 x 7.5 inches to be on par with PPTX.
  // See the discussion at https://github.com/bpampuch/pdfmake/issues/359 regarding the units of measure that are used for pageSize
  // TLDR: the coefficient is 72.
  // (13.3 * 72) x (7.5 * 72) = 957.6 x 540 ~= 958 x 540
  return {
    pageSize: {
      height: 540,
      width: 958,
    },
    pageMargins: [0, 0, 72, 34],
  };
};
