import {useLocation} from "react-router";
import queryString, {ParsedQuery} from 'query-string';
import {useState, Dispatch} from "react";
import {setSearchParams} from "../../helpers/router";

type Params = ParsedQuery<string | boolean | number>;
type HandleParams = <T extends {}>(params: Params) => Params;

export const parse = <T extends {}>(query: string, handleParams?: HandleParams): T => {
  const params = queryString.parse(query, {
    arrayFormat: 'comma',
    parseNumbers: true
  });
  return (handleParams ? handleParams(params) : params) as T;
};

export const stringify = <T extends {}>(params: T): string => (
  queryString.stringify(params, {
    arrayFormat: 'comma'
  })
);

const useParams = <T extends {}>(handleParams?: HandleParams): [T, Dispatch<T>] => {

  const { search } = useLocation();
  const [params, setParams] = useState<T>(() => {
    const params = parse<T>(search, handleParams);
    setSearchParams(stringify(params));
    return params;
  });

  const updateParams = (params: T) => {
    setSearchParams(stringify(params));
    setParams(params);
  };

  return [params, updateParams];
};

export default useParams;