import React, {createContext, Dispatch, useEffect, useReducer} from 'react';
import { getContextFactory } from './ContextFactory';
import useSectionPopups, {defaultSectionPopups, SectionPopups} from "../hooks/useSectionPopups";
import {PayloadAction} from "../common/utils/types";
import {PharmacySectionName as PopupName} from "../model/pharmacy";

export enum SectionPopupActionType {
  Popup, PopupShowed
}

export type Action<P> =  PayloadAction<SectionPopupActionType, P>

export type PopupAction = Action<PopupName>
export type PopupShowedAction = Action<boolean>

export type SectionPopupAction = PopupAction | PopupShowedAction

const reducer = (state: SectionPopups, action: SectionPopupAction) => {
  switch (action.type) {
    case SectionPopupActionType.Popup: {
      return {
        popups: {
          ...state.popups, [action.payload as PopupName]: true
        },
        popupShowed: true
      } as SectionPopups
    }
    case SectionPopupActionType.PopupShowed: {
      return {
        ...state,
        popupShowed: action.payload as boolean
      } as SectionPopups
    }
    default: {
      throw new Error('Unknown SectionPopup Action type');
    }
  }
};

const SectionPopupContext = createContext<SectionPopups>(defaultSectionPopups);
const SectionPopupDispatchContext = createContext<Dispatch<SectionPopupAction>>(
  () => undefined
);

export const useSectionPopupContext = getContextFactory(SectionPopupContext);
export const useSectionPopupDispatchContext = getContextFactory(SectionPopupDispatchContext);

const SectionPopupContextProvider: React.FC = props => {

  const [sectionPopups, setSectionPopups] = useSectionPopups();
  const [popups, dispatch] = useReducer(reducer, sectionPopups || defaultSectionPopups);

  useEffect(() => {
    setSectionPopups(popups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popups]);

  return (
    <SectionPopupContext.Provider value={popups}>
      <SectionPopupDispatchContext.Provider value={dispatch}>
        {props.children}
      </SectionPopupDispatchContext.Provider>
    </SectionPopupContext.Provider>
  );
};

export default SectionPopupContextProvider;