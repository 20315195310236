import React, {useState} from 'react';
import styles from '../../styles/components/pages/FavoritesPage.module.css';
import DefaultPage from "./DefaultPage";
import {RouteComponentProps, withRouter} from "react-router";
import {FavoritesRouterParams} from "../../router/params/FavoritesRouterParams";
import PharmacySnapshotList from "../pharmacy/list/PharmacySnapshotList";
import {
  defaultPharmacyListPaginationParams as defaultPaginationParams,
  OnlyFavoritesParams,
  OwnershipTypeParams,
  PharmacyListFilterParams
} from "../../model/pharmacy";
import { HomeTabs } from '../pharmacy/tabs/HomeTabs';

type FavoritesPageProps = RouteComponentProps<FavoritesRouterParams>;

const FavoritesPage: React.FC<FavoritesPageProps> = ({ history, match }) => {

  const [listFilter, setListFilter] = useState<PharmacyListFilterParams>({
    ot: OwnershipTypeParams.All,
    f: OnlyFavoritesParams.Yes,
    ...defaultPaginationParams
  });

  return (
    <DefaultPage
      name='Favorites'
      header={{
        title: (
          <span className={styles.headerTitle}>
            My <span className={styles.headerTitleAbbreviation}>SP</span>OPTIMIZER
          </span>
        ),
        subtitle: 'Find the right specialty pharmacy based on your business requirements.'
      }}
      wideContent={true}
    >
      <HomeTabs activeTab="favorites"></HomeTabs>
      <div className={styles.container}>
        <div className={styles.content}>
          <PharmacySnapshotList
            listFilter={listFilter}
            onPaginationChange={updatedPagination => setListFilter({...listFilter, ...updatedPagination})}
            pagination={{
              label: 'Showing Favorites'
            }}
            emptyMessage={() => (
              <div className={styles.emptyList}>
                <div className={styles.image}>
                  <img src={require("../../assets/images/no_favorites.png")} alt="" />
                </div>
                <div className={styles.title}>
                  You Have No Favorites
                </div>
                <div className={styles.description}>
                  Keep looking through results to find specialty pharmacies that meet your business requirements.
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </DefaultPage>
  )
};

export default withRouter(FavoritesPage);
