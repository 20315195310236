import React from 'react';
import {
  Table, TableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import CommaList from "../../../common/components/CommaList";
import ContentElement from "../../../common/components/Content";
import _ from 'lodash';

export type TherapeuticAreaTableProps = Pick<TableProps, 'limit' | 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const TherapeuticAreaTable: React.FC<TherapeuticAreaTableProps> = ({ pharmacyProfile, ...props }) => {
  const { therapeuticAreas } = pharmacyProfile;
  return therapeuticAreas && isNotEmpty(therapeuticAreas) ? (
    <Table
      headers={['Therapeutic Category', 'Disease Area']}
      items={
        _.chain(therapeuticAreas)
          .filter(({content}) => _.every([content?.id, content?.name, content?.treatmentCategory], isNotEmpty))
          .groupBy('content.treatmentCategory')
          //.toPairs()
          //.sortBy(0)
          //.fromPairs()
          .flatMap((areas, categoryName) =>
            ([
              {content: categoryName},
              {content:
                <CommaList
                  items={
                    _.chain(areas)
                      //.sortBy('content.name')
                      .map(area =>
                        <ContentElement {...{content: area.content?.name || '', attributes: area.attributes }} />
                      )
                      .value()
                  }
                />
              }
            ])
          )
          .value()
      }
      {...props}
    />
  ) : <></>
};

export default TherapeuticAreaTable;