import React from 'react';
import styles from '../../styles/components/link/RouterLink.module.css';
import {Link} from "react-router-dom";

export interface RouterLinkProps {
  to: string;
  className?: string;
}

const RouterLink: React.FC<RouterLinkProps> = ({ to, className, children }) => {
  return (
    <Link to={to} className={`${styles.link} ${className || ''}`}>
      {children}
    </Link>
  )
};

export default RouterLink;