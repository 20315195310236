import React, { createContext, useState } from 'react';
import { getContextFactory } from './ContextFactory';
import {UISettings} from "../model/ui";

const UISettingsContext = createContext<UISettings>({});
const UISettingsUpdateContext = createContext<(settings: UISettings) => void>(
  () => undefined
);

export const useUISettingsContext = getContextFactory(UISettingsContext);
export const useUISettingsUpdateContext = getContextFactory(UISettingsUpdateContext);

const UISettingsContextProvider: React.FC = props => {
  const [uiSettings, updateUISettings] = useState<UISettings>({});
  return (
    <UISettingsContext.Provider value={uiSettings}>
      <UISettingsUpdateContext.Provider value={updateUISettings}>
        {props.children}
      </UISettingsUpdateContext.Provider>
    </UISettingsContext.Provider>
  );
};

export default UISettingsContextProvider;
