import React from 'react';
import styles from '../styles/components/ModalError.module.css';
import ActionButton from "./ActionButton";
import {RemoveScroll} from 'react-remove-scroll';
import {ReactComponent as CrossBlueIcon} from '../../common/assets/icons/cross_blue.svg';
import {AppError} from "../../model/error";

export interface ModalErrorProps {
  error: AppError;
  onClose: () => void;
}

const ModalError: React.FC<ModalErrorProps> = ({ error, onClose }) => {
  return (
    <RemoveScroll>
      <div className={styles.container}>
        <div className={styles.background} onClick={onClose} />
        <div className={styles.contentWrapper}>
          <>
            <div className={styles.content}>
              <span className={styles.errorText}>{error.text}</span>
            </div>
            <span className={styles.close}>
              <ActionButton
                type="icon"
                paddingVertical={8}
                paddingHorizontal={8}
                icon={{
                  source: <CrossBlueIcon/>,
                  size: 'large',
                }}
                onClick={onClose}
              />
            </span>
          </>
        </div>
      </div>
    </RemoveScroll>
  );
};

export default ModalError;
