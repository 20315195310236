import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from '../styles/components/TopBar.module.css';
import borders from '../styles/Breakpoints.module.css';
import { push as PushMenu } from 'react-burger-menu';
import useMediaQuery from '../hooks/useMediaQuery';
import { createDivElement, prependElement } from '../../utils/dom';
import {ReactComponent as MenuBlackIcon} from '../../common/assets/icons/menu_black.svg';
import _ from 'lodash';

export interface TopBarItemsProps {
  leftItems?: JSX.Element[];
  rightItems?: JSX.Element[];
}

export interface TopBarProps extends TopBarItemsProps {
  logo?: JSX.Element;
  shadowed?: boolean
}

const MenuContent: React.FC<TopBarItemsProps> = ({
  leftItems,
  rightItems,
}) => {
  return (
    <div className={styles.menu}>
      {leftItems && (<div className={styles.startMenuItems}>
        {_.map(leftItems, (item, idx) => (
          <div key={idx} className={styles.startMenuItem}>
            {item}
          </div>
        ))}
      </div>)}
      {rightItems && (<div className={styles.endMenuItems}>
        {_.map(rightItems, (item, idx) => (
          <div key={idx} className={styles.endMenuItem}>
            {item}
          </div>
        ))}
      </div>)}
    </div>
  );
};

const FullMenu: React.FC = ({ children }) => {
  return <>{children}</>;
};

const CompactMenu: React.FC = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const menuPortal = prependElement(createDivElement('menuPortal'), 'root');

  return (
    <>
      {ReactDOM.createPortal(
        <PushMenu
          pageWrapId="page"
          outerContainerId="root"
          width={250}
          right
          disableAutoFocus
          isOpen={expanded}
          onStateChange={state => setExpanded(state.isOpen)}
          burgerButtonClassName={styles.burgerMenuButton}
        >
          {children}
        </PushMenu>,
        menuPortal
      )}
      <div className={styles.menuIcon} onClick={() => setExpanded(true)}>
        <MenuBlackIcon/>
      </div>
    </>
  );
};

const TopBar: React.FC<TopBarProps> = ({
  logo,
  shadowed = true,
  ...props
}) => {
  const up1024 = useMediaQuery(borders.lg);
  const Menu = up1024 ? FullMenu : CompactMenu;
  return (
    <div className={`${styles.container} ${shadowed ? styles.shadowed : ''}`}>
      <div className={styles.content}>
        <div className={styles.logo}>{logo}</div>
        <Menu>
          <MenuContent {...props} />
        </Menu>
      </div>
    </div>
  );
};

export default TopBar;
