import { cloneDeep, some } from "lodash";
import { isNotEmpty } from "../common/utils/common";

// also rounds to 4 decimals
export const pt2in = (points: number) =>
  Math.round((points / 72 + Number.EPSILON) * 10000) / 10000;

export const in2pt = (inches: number) => Math.round(inches * 72);

export type Cell =
  | string
  | { kind: "text"; text: string | undefined | null }
  | { kind: "list"; ul: Cell[] }
  | { kind: "stack"; stack: Cell[] };
export const splitToPages = (
  rows: Cell[][],
  maxContentLengthPerSlide: number
): Cell[][][] => {
  let currSlideLength = 0;
  let slidesContent: Cell[][][] = [];
  let currentSlideContent: Cell[][] = [];

  rows.forEach((row) => {
    const maxLength = getMaxLengthInRow(row);
    if (
      currSlideLength + maxLength > maxContentLengthPerSlide &&
      isNotEmpty(currentSlideContent)
    ) {
      slidesContent.push(currentSlideContent);
      currentSlideContent = [];
      currSlideLength = 0;
    }
    currentSlideContent.push(row);
    currSlideLength += maxLength;
  });
  if (isNotEmpty(currentSlideContent)) {
    slidesContent.push(currentSlideContent);
  }
  return slidesContent;
};

export const getMaxLengthInRow = (row: Cell[]): number => {
  const contents = row.map(getTextFromCell);
  const contentLengths = contents.map((x) => x.length);
  const maxLength = Math.max(...contentLengths);
  return maxLength;
};

export const getTextFromCell = (cell: Cell): string => {
  const getTextFromCellArray = (cells: Cell[]): string => {
    return cells.reduce<string>((acc, x) => {
      const text = getTextFromCell(x);
      return acc + "|" + text;
    }, "");
  };
  if (typeof cell === "string") {
    return cell;
  } else if (cell.kind === "list") {
    return getTextFromCellArray(cell.ul);
  } else if (cell.kind === "stack") {
    return getTextFromCellArray(cell.stack);
  } else if (cell.text === undefined || cell.text === null) {
    return "";
  } else if (typeof cell.text === "string") {
    return cell.text;
  } else {
    console.log("Can't get text from cell, the cell is ", cell);
    return "";
  }
};

export const ungroupCellsIfDoesntFit = (
  rows: Cell[][],
  maxContentLengthPerSlide: number
): Cell[][] => {
  const ungroupCells = (rows: Cell[][]): Cell[][] => {
    const flattened = rows.flat();
    const ungrouped = flattened.map((x) => [x]);
    return ungrouped;
  };
  const isUngroupNeeded = some(rows, (row) => {
    const maxLength = getMaxLengthInRow(row);
    return maxLength > maxContentLengthPerSlide;
  });
  const result = isUngroupNeeded ? ungroupCells(rows) : rows;
  return result;
};

export const findLastWordWithDot = (a: string[]) => {
  const c = [...a].reverse();
  const index = c.findIndex((x) => x.includes("."));
  return a.length - index - 1; // index === -1 is handled by this as well.
};

export const splitAcquisitionContent = (content: string[]): string[] => {
  let res = [];
  const slideLimit = 150;
  let i = 0;
  while (i < content.length) {
    const rightBoundary = Math.min(i + slideLimit, content.length);
    const slideContentFirstTry = content.slice(i, rightBoundary);
    const endOfSentence = findLastWordWithDot(slideContentFirstTry) + 1;
    const step =
      endOfSentence > 0 && endOfSentence <= slideLimit
        ? endOfSentence
        : slideLimit;
    const slideContentFinal = content.slice(i, i + step);
    res.push(slideContentFinal.join(" "));
    i += step;
  }
  /* for (let i = 0; i < content.length; i+=slideLimit) {
    const rightBoundary = Math.min(i+slideLimit, content.length);
    const slideContent = content.slice(i, rightBoundary).join(" ");
    res.push(slideContent);
  } */
  return res;
};

type CellWithOptions = Cell & {
  fillColor?: string
  //add more options if needed
}

export const normalizeBgColors = (splittedRows: CellWithOptions[][][], color: string) => {
  return splittedRows.map((rows) => {
    if (rows.length === 1) {
      return rows.map((row) => row.map((cell) => {
        if (cell.fillColor != null) {
          let normalized = cloneDeep(cell)
          normalized.fillColor = color;
          return normalized
        } else {
          return cell
        }       
      }))  
    } else {
      return rows
    }
  })
}