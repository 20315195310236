import React from "react";
import RouterLink, {
  RouterLinkProps,
} from "../../../common/components/link/RouterLink";
import styles from "../../../styles/components/pharmacy/tabs/HomeTab.module.css";

export interface HomeTabProps {
  name: string;
  label: string;
  isActive?: boolean;
  icon?: JSX.Element;
}

export const HomeTab = ({
  name,
  label,
  isActive,
  icon,
  to,
}: HomeTabProps & RouterLinkProps) => {
  return (
    <RouterLink className={styles.link} to={to}>
      <div
        key={name + "-tab"}
        className={[styles.tab, isActive ? styles.active : ""].join(" ")}
      >
        <div className={styles.labelContainer}>
          <span className={styles.icon}>{icon}</span>
          <span className={isActive ? styles.labelActive : styles.label}>
            {label}
          </span>
        </div>
      </div>
    </RouterLink>
  );
};
