import React from 'react';
import {
  Table, TableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";

export type SWOTAnalysisTableProps = Pick<TableProps, 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const SWOTAnalysisTable: React.FC<SWOTAnalysisTableProps> = ({ pharmacyProfile, ...props }) => {
  const { swotAnalysis } = pharmacyProfile;
  return swotAnalysis && isNotEmpty(swotAnalysis) ? (
    <Table
      columns={2}
      items={[
        {...swotAnalysis.strength, label: 'Strengths'},
        {...swotAnalysis.weakness, label: 'Weaknesses'},
        {...swotAnalysis.opportunity, label: 'Opportunities'},
        {...swotAnalysis.threat, label: 'Threats'}
      ]}
      {...props}
    />
  ) : <></>
};

export default SWOTAnalysisTable;