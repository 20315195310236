import React, {useState} from 'react';
import styles from '../styles/components/ExpandablePanel.module.css';
import AnimateHeight from 'react-animate-height';
import {ReactComponent as ChevronDownRedIcon} from '../../common/assets/icons/chevron_down_red.svg';
import {ReactComponent as ChevronUpGreyIcon} from '../../common/assets/icons/chevron_up_grey.svg';
import {ReactComponent as ChevronUpRedIcon} from '../../common/assets/icons/chevron_up_red.svg';

export interface ExpandablePanelProps {
  picture?: React.ReactNode | string;
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  topBar?: React.ReactNode | string;
  header?: React.ReactNode;
  preview?: (expanded: boolean) => React.ReactNode
  expandLabel: string;
  reduceLabel: string;
  headerClosable?: boolean;
}

const ExpandablePanel: React.FC<ExpandablePanelProps> = ({
  picture,
  title,
  subtitle,
  topBar,
  header,
  preview,
  reduceLabel,
  expandLabel,
  headerClosable = false,
  children
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.container}>
      {topBar && (
        <div className={styles.topBar}>{topBar}</div>
      )}
      <div className={styles.header}>
        <div className={styles.headerTopContainer}>
          {header || (
            <>
              {picture && (
                <div className={styles.pictureContainer}>
                  {picture}
                </div>
              )}
              {(title || subtitle) && (
                <div className={styles.titleContainer}>
                  {title && (<div className={styles.title}>{title}</div>)}
                  {subtitle && (<div className={styles.subtitle}>{subtitle}</div>)}
                </div>
              )}
              {headerClosable && expanded && (
                <div className={styles.headerClose} onClick={() => setExpanded(false)}>
                  <span>{reduceLabel}</span>
                  <ChevronUpGreyIcon/>
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.headerBottomContainer}>
          {preview && preview(expanded)}
        </div>
      </div>
      <AnimateHeight
        className={styles.contentWrapper}
        contentClassName={styles.content}
        duration={600}
        height={expanded ? 'auto' : 0}
      >
        {children}
      </AnimateHeight>
      <div
        className={styles.footer}
        onClick={() => setExpanded(!expanded)}
      >
        <span>{expanded ? reduceLabel : expandLabel}</span>
        {expanded ? <ChevronUpRedIcon/> : <ChevronDownRedIcon/>}
      </div>
    </div>
  );
};

export default ExpandablePanel;
