import React from 'react';
import styles from '../../styles/components/page/PageFooter.module.css';
import twitterIcon from '../../assets/icons/twitter_white.svg';
import linkedinIcon from '../../assets/icons/linkedin_white.svg';
import logoImg from '../../assets/images/eversana_big_logo.png';

const PageFooter: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <a className={styles.branding} href="https://www.eversana.com">
            <img src={logoImg} alt="EVERSANA" />
          </a>
          <p className={styles.logoDescription}>
            ©2020 EVERSANA. All Rights Reserved.
            <br />
            <a href="https://www.eversana.com/legal/privacy/">Privacy Policy</a>
          </p>
        </div>
        <div className={styles.navigation}>
          <nav className={styles.navigationMenu}>
            <ul>
              <li key="0">
                <a href="https://www.eversana.com/">Home</a>
              </li>
              <li key="1">
                <a href="https://www.eversana.com/about/">About Us</a>
              </li>
              <li key="2">
                <a href="https://www.eversana.com/solutions/">Solutions</a>
              </li>
              <li key="3">
                <a href="https://www.eversana.com/careers/">Careers</a>
              </li>
              <li key="4">
                <a href="https://www.eversana.com/news/">News</a>
              </li>
              <li key="5">
                <a href="https://www.eversana.com/contact-us/">Contact Us</a>
              </li>
            </ul>
          </nav>
          <ul className={styles.socialBookmarks}>
            <li>
              <strong>Follow Us</strong>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/eversana/">
                <img src={linkedinIcon} alt="linkedin" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/eversanacompany">
                <img src={twitterIcon} alt="twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
