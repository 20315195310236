import React from 'react';
import styles from "../styles/components/ScrollToTopButton.module.css";
import {animateScroll} from "react-scroll/modules";
import {ReactComponent as ArrowUpGreyIcon} from "../assets/icons/arrow_up_grey.svg";
import CSSTransition from "react-transition-group/CSSTransition";
import {SVGComponent} from "../utils/types";

export type ScrollToTopButtonProps = {
  show: boolean
  icon?: SVGComponent
  className?: string
}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ show = false, icon, className }) => {
  const Icon = icon || ArrowUpGreyIcon;
  return (
    <CSSTransition
      in={show}
      timeout={200}
      classNames={styles}
      unmountOnExit={true}
    >
      <div
        className={className || ''}
        onClick={() =>
          animateScroll.scrollToTop({
            duration: 400,
            delay: 0,
            offset: 0,
            smooth: 'easeInOutQuart',
          })
        }
      >
        <Icon/>
      </div>
    </CSSTransition>
  )
};

export default ScrollToTopButton;