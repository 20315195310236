import {UserAccount} from "../model/user";
import config from "../config";
import {RealApi} from "./real-api";
import {FakeApi} from "./fake-api";
import {CustomAnalysisRequest} from "../model/customAnalysis";
import {
  PharmacyFavoriteUpdateRequest, PharmacyFavoriteUpdateResponse,
  PharmacyProfileRequest,
  PharmacyProfileResponse,
  PharmacyReferenceListResponse,
  PharmacySnapshotListRequest,
  PharmacySnapshotListResponse,
} from "../model/pharmacy";
import {AreaListResponse} from "../model/area";
import {ServiceListResponse} from "../model/service";
import {PayerParticipationListResponse} from "../model/payer";
import {BiopharmaParticipationListResponse} from "../model/biopharma";
import {SupportRequest, SupportTopicListResponse} from "../model/support";

export interface RestApi {
  findAreas(): Promise<AreaListResponse>
  findServices(): Promise<ServiceListResponse>
  findPayerParticipation(): Promise<PayerParticipationListResponse>
  findBiopharmaParticipation(): Promise<BiopharmaParticipationListResponse>
  // Pharmacy Profile
  findPharmacyProfile(request: PharmacyProfileRequest): Promise<PharmacyProfileResponse>
  // Pharmacy Snapshot
  findPharmacySnapshots(request: PharmacySnapshotListRequest, id?: number): Promise<PharmacySnapshotListResponse>
  // Pharmacy Favorite
  updatePharmacyFavorite(request: PharmacyFavoriteUpdateRequest): Promise<PharmacyFavoriteUpdateResponse>
  // Custom Analysis
  requestCustomAnalysis(request: CustomAnalysisRequest): Promise<string>
  // Support
  requestSupport(request: SupportRequest): Promise<string>
  findSupportTopics(): Promise<SupportTopicListResponse>
  // User
  findUser(): Promise<UserAccount>
  // Get the list of sp references
  getAllPharmacies(): Promise<PharmacyReferenceListResponse>
}

const api: RestApi = (config.type === 'fake') ? new FakeApi() : new RealApi();

export default api;