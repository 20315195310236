import React from 'react';
import {PharmacyProfile} from "../../../model/pharmacy";
import styles from "../../../styles/components/pharmacy/card/CustomAnalysisCard.module.css";
import ActionButton from "../../../common/components/ActionButton";
import {redirect} from "../../../helpers/router";

export interface CustomAnalysisCardProps {
  pharmacyProfile: PharmacyProfile
}

const CustomAnalysisCard: React.FC<CustomAnalysisCardProps> = ({ pharmacyProfile }) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <span className={styles.title}>
        <span>Get ahead with <br/> an expert analysis</span>
      </span>
      <span className={styles.description}>
        We have unique, up to date information on IDN-Owned Specialty Pharmacies. Move faster with our custom analysis.
      </span>
      <ActionButton
        text="Contact us about this SP"
        type="primary"
        onClick={() => redirect('/customAnalysis/' + pharmacyProfile.id)}
      />
    </div>
  </div>
);

export default CustomAnalysisCard;