import React from 'react';
import styles from '../../styles/components/logo/ImgLogo.module.css';

export interface TextLogoProps {
  source: string
  alt?: string
  link?: string
  onClick?: () => void
}

const ImgLogo: React.FC<TextLogoProps> = ({ source, alt, link, onClick }) => {
  const img = <img src={source} alt={alt} />;
  return (
    <div className={styles.container} onClick={onClick}>
      {link ? (
        <a className={styles.logo} href={link}>
          {img}
        </a>
      ) : img}
    </div>
  );
};

export default ImgLogo;
