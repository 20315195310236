import React, { useRef } from "react";
import styles from "../../../styles/components/pharmacy/card/DownloadButton.module.css";
import { ReactComponent as DownloadWhiteIcon } from "../../../common/assets/icons/download_white.svg";
import ActionButton, {
  ActionButtonProps,
} from "../../../common/components/ActionButton";
import ReactTooltip from "react-tooltip";

export type DownloadButtonProps = Omit<ActionButtonProps, "onClick"> & {
  onPdfExport: () => Promise<void>;
  onPptxExport: () => Promise<void>;
};

export const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  type TooltipRef = { tooltipRef: null } | null;
  const tooltip = useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const resetTooltip = () => {
    // see https://github.com/wwayne/react-tooltip/issues/449
    // Description from the link above: "when I click the button the tooltip doesn't hide immediately. It just hide after i move the away from the button."
    // start of hack
    const current: TooltipRef = tooltip.current;
    current!.tooltipRef = null;
    setIsOpen(false);
    ReactTooltip.hide();
    // end of hack
  };
  const onClickPdf = async () => {
    try {
      setIsDownloading(true);
      resetTooltip();
      await props.onPdfExport();
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };
  const onClickPptx = async () => {
    try {
      setIsDownloading(true);
      resetTooltip();
      await props.onPptxExport();
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };
  return (
    <div data-tip="" data-offset="{'right': 82}" data-event="click focus">
      <ReactTooltip
        ref={tooltip}
        className={styles.tooltip}
        place="bottom"
        effect="solid"
        type="light"
        afterShow={() => setIsOpen(true)}
        afterHide={() => setIsOpen(false)}
        clickable={true}
        isCapture={true}
      >
        <div onClick={onClickPdf} className={styles.firstItem}>
          PDF
        </div>
        <hr className={styles.divider}></hr>
        <div onClick={onClickPptx} className={styles.lastItem}>
          PPT
        </div>
      </ReactTooltip>
      <ActionButton
        {...props}
        onClick={() => {}}
        loading={isDownloading}
        icon={{
          source: <DownloadWhiteIcon />,
          position: "right",
        }}
        type="link"
        color="white"
      />
    </div>
  );
};
