import React, {useState} from 'react';
import Box from "../../../common/components/Box";
import styles from "../../../styles/components/pharmacy/card/PharmacySnapshotFilterCard.module.css";
import {AreaId} from "../../../model/area";
import {ReactComponent as ArrowLeftWhiteIcon} from '../../../common/assets/icons/arrow_left_white.svg';
import {ReactComponent as ArrowRightWhiteIcon} from '../../../common/assets/icons/arrow_right_white.svg';
import {ReactComponent as StarGreyIcon} from '../../../common/assets/icons/star_grey.svg';
import {ReactComponent as StarYellowIcon} from '../../../common/assets/icons/star_yellow.svg';
import {ReactComponent as CheckboxCheckedBlueIcon} from '../../../common/assets/icons/checkbox_checked_blue.svg';
import {ReactComponent as CheckboxHoverBlueIcon} from '../../../common/assets/icons/checkbox_hover_blue.svg';
import {isNotEmpty} from "../../../common/utils/common";
import {ServiceId} from "../../../model/service";
import {PayerParticipationId} from "../../../model/payer";
import {BiopharmaParticipationId} from "../../../model/biopharma";
import {CategoryName} from "../../../model/category";
import {OnlyFavoritesParams, OwnershipTypeParams, PharmacyFilterParams} from "../../../model/pharmacy";
import TreatmentCategorySelect from "../field/TreatmentCategorySelect";
import ServiceSelect from "../field/ServiceSelect";
import PayerParticipationSelect from "../field/PayerParticipationSelect";
import BiopharmaParticipationSelect from "../field/BiopharmaParticipationSelect";
import TherapeuticAreaSelect from "../field/TherapeuticAreaSelect";
import _ from 'lodash';

export interface PharmacySnapshotFilterCardProps {
  filter: PharmacyFilterParams
  onFilterChange: (filter: PharmacyFilterParams) => void
  expanded: boolean
  setExpanded: (state: boolean) => void
}

const PharmacySnapshotFilterCard: React.FC<PharmacySnapshotFilterCardProps> = ({ filter, onFilterChange, expanded, setExpanded }) => {

  const { Independent, IDN, All } = OwnershipTypeParams;
  const { Yes } = OnlyFavoritesParams;

  const [categoryName, setCategoryName] = useState<CategoryName | undefined>(undefined);

  const [expandedCategory, setExpandedCategory] = useState(false);
  const [expandedAreas, setExpandedAreas] = useState(false);
  const [expandedServices, setExpandedServices] = useState(false);
  const [expandedPayerParticipation, setExpandedPayerParticipation] = useState(false);
  const [expandedBiopharmaParticipation, setExpandedBiopharmaParticipation] = useState(false);

  return (
    <Box>
      <div className={`${styles.container} ${expanded ? styles.expandedWidth : styles.collapsedWidth}`}>

        <div className={styles.header} onClick={() => setExpanded(!expanded)}>
          <span className={styles.headerTitle}>
            {expanded ? 'Find the Right Specialty Pharmacy' : 'Filter'}
          </span>
          <span className={styles.headerIcon}>
            {expanded ? <ArrowLeftWhiteIcon/> : <ArrowRightWhiteIcon/>}
          </span>
        </div>

        <div className={styles.content}>

          <div className={styles.section}>
            <div className={styles.description}>
              <span>Choose a therapeutic category in order to select a disease area.</span><br/>
              <span className={styles.bold}>Results will not filter by only selecting therapeutic category.</span>
            </div>
            <TreatmentCategorySelect
              value={categoryName}
              className={styles.field}
              expanded={expandedCategory}
              onExpanded={(expanded) => {
                if(expanded) {
                  setExpandedAreas(false);
                }
                setExpandedCategory(expanded);
              }}
              onLoad={categories => {
                const areaId = _.head(filter.ta);
                if(isNotEmpty(areaId)) {
                  const categoryName = _.findKey(categories, areas => _.some(areas, area => area.id === areaId));
                  setCategoryName(categoryName);
                }
              }}
              onChange={(categoryName) => {
                setCategoryName(categoryName as CategoryName);
                if(isNotEmpty(filter.ta)) {
                  onFilterChange({...filter, ta: []})
                }
                if(isNotEmpty(categoryName)) {
                  setTimeout(() => setExpandedAreas(true), 500);
                }
              }}
            />
            <TherapeuticAreaSelect
              value={filter.ta || []}
              categoryName={categoryName}
              className={styles.field}
              expanded={expandedAreas}
              onExpanded={setExpandedAreas}
              onChange={(areaIds) => onFilterChange({ta: areaIds as AreaId[]})}
            />
          </div>

          <div className={styles.section}>
            <ServiceSelect
              value={filter.s || []}
              className={styles.field}
              expanded={expandedServices}
              onExpanded={setExpandedServices}
              onChange={(serviceIds) => onFilterChange({s: serviceIds as ServiceId[]})}
            />
            <PayerParticipationSelect
              value={filter.pnp || []}
              className={styles.field}
              expanded={expandedPayerParticipation}
              onExpanded={setExpandedPayerParticipation}
              onChange={(payerParticipationIds) => onFilterChange({pnp: payerParticipationIds as PayerParticipationId[]})}
            />
            <BiopharmaParticipationSelect
              value={filter.bnp || []}
              className={styles.field}
              expanded={expandedBiopharmaParticipation}
              onExpanded={setExpandedBiopharmaParticipation}
              onChange={(biopharmaParticipationIds) => onFilterChange({bnp: biopharmaParticipationIds as BiopharmaParticipationId[]})}
            />
          </div>

          <div className={styles.section}>
            <div className={styles.description}>
              <span>Choose at least one type of SP to display.</span>
            </div>
            {/* TODO: refactor fields (dry) */}
            <div className={styles.fieldGroup}>
              <div
                className={styles.customField}
                onClick={() => {
                  const ownershipType = (filter.ot === All && IDN) || (filter.ot === IDN && All);
                  if(ownershipType) {
                    onFilterChange({ot: ownershipType});
                  }
                }}
              >
                <span className={styles.customFieldIcon}>
                  {_.includes([Independent, All], filter.ot)  ? <CheckboxCheckedBlueIcon/> : <CheckboxHoverBlueIcon/>}
                </span>
                <span className={styles.customFieldLabel}>
                  Independent Owned Specialty Pharmacies
                </span>
              </div>
              <div
                className={styles.customField}
                onClick={() => {
                  const ownershipType = (filter.ot === All && Independent) || (filter.ot === Independent && All);
                  if(ownershipType) {
                    onFilterChange({ot: ownershipType});
                  }
                }}
              >
                <span className={styles.customFieldIcon}>
                  {_.includes([IDN, All], filter.ot) ? <CheckboxCheckedBlueIcon/> : <CheckboxHoverBlueIcon/>}
                </span>
                <span className={styles.customFieldLabel}>
                  IDN-Owned / Institutional Specialty Pharmacies
                </span>
              </div>
            </div>
          </div>

          {/* Favorites */}
          <div className={styles.section}>
            <div className={styles.description}>
              <span className={styles.bold}>Results will also filter selections from the Specialty Pharmacy List.</span>
            </div>
            <div
              className={styles.customField}
              onClick={() => onFilterChange({f: !filter.f ? Yes : undefined})}
            >
              <span className={styles.customFieldIcon}>
                {filter.f === Yes ? <StarYellowIcon/> : <StarGreyIcon/>}
              </span>
              <span className={styles.customFieldLabel}>
                Only Show Favorites
              </span>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PharmacySnapshotFilterCard;