import React, {useEffect, useState} from "react";
import {useUserContext, useUserUpdateContext} from "../contexts/UserContext";
import {useErrorUpdateContext} from "../contexts/ErrorContext";
import {isEmpty} from "../common/utils/common";
import {ErrorType} from "../model/error";
import Loading from "../common/components/Loading";
import {redirectWindowToAuth} from "../helpers/window";
import api from "../rest/api";

export type AuthWrapperProps = {}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const user = useUserContext();
  const updateUser = useUserUpdateContext();
  const updateError = useErrorUpdateContext();

  const [userLoading, setUserLoading] = useState(isEmpty(user));

  useEffect(() => {
    let ignore = false;
    async function fetchUser() {
      try {
        if(isEmpty(user)) {
          const data = await api.findUser();
          if(!ignore) {
            updateUser(data);
            setUserLoading(false);
          }
        }
      }
      catch(error) {
        if(error.type === ErrorType.AuthApi) {
          redirectWindowToAuth()
        } else if(!ignore) {
          setUserLoading(false);
          updateError(error);
        }
      }
    }
    fetchUser();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(userLoading) {
    return <Loading paddingVertical={0} paddingHorizontal={0} />;
  }

  return <>{children}</>;
};

export default AuthWrapper;