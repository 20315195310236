import React from 'react';
import {
  BulletedTable, BulletedTableProps,
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";

export type AccreditationListProps = Pick<BulletedTableProps, 'columns' | 'limit' | 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const AccreditationList: React.FC<AccreditationListProps> = ({ pharmacyProfile, ...props }) => {
  const { accreditation } = pharmacyProfile;
  return accreditation && isNotEmpty(accreditation) ? (
    <BulletedTable
      items={accreditation}
      {...props}
    />
  ) : <></>
};

export default AccreditationList;