export const isNumber = (value: any): value is number =>
  !isNaN(parseInt(value));

export const isNegative = (value: number): boolean =>
  value < 0;

export const isPositive = (value: number): boolean =>
  !isNegative(value);

export const ifNegative = (value: number, then: number): number =>
  isNegative(value) ? then : value;

export const ifPositive = (value: number, then: number): number =>
  isPositive(value) ? then : value;

export const parseNumber = (value: any): number | undefined => {
  const number = parseInt(value);
  return !isNaN(number) ? number : undefined;
};