import React from 'react';
import {
  Table, TableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import _ from 'lodash';

export type TechnologyOfferingTableProps = Pick<TableProps, 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const TechnologyOfferingTable: React.FC<TechnologyOfferingTableProps> = ({ pharmacyProfile, ...props }) => {
  const { technologyOfferings } = pharmacyProfile;
  return technologyOfferings && isNotEmpty(technologyOfferings) ? (
    <Table
      headers={['Technology Name', 'Description']}
      items={
        _.flatMap(technologyOfferings, offering => (_.map(offering.content, (value, key) => ({content: value, attributes: offering.attributes}))))
      }
      {...props}
    />
  ) : <></>
};

export default TechnologyOfferingTable;