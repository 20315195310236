import React from 'react';
import styles from '../../../styles/components/pharmacy/panel/PharmacySnapshotPanel.module.css';
import Box from "../../../common/components/Box";
import ExpandablePanel from "../../../common/components/ExpandablePanel";
import {isNotEmpty} from "../../../common/utils/common";
import {ReactComponent as ArrowRightBlueIcon} from '../../../common/assets/icons/arrow_right_blue.svg';
import {ReactComponent as StarGreyIcon} from '../../../common/assets/icons/star_grey.svg';
import {ReactComponent as StarYellowIcon} from '../../../common/assets/icons/star_yellow.svg';
import Loading from "../../../common/components/Loading";
import RouterLink from "../../../common/components/link/RouterLink";
import {PharmacyFavorite, PharmacySnapshot} from "../../../model/pharmacy";
import TherapeuticAreaTable from "../table/TherapeuticAreaTable";
import ServiceList from "../list/ServiceList";
import PayerNetworkList from "../list/PayerNetworkList";
import BiopharmaNetworkList from "../list/BiopharmaNetworkList";
import LimitedDistributionNetworkTable from "../table/LimitedDistributionNetworkTable";
import AccreditationList from "../list/AccreditationList";
import RedirectButton from "../../../common/components/RedirectButton";

type SectionProps = {
  title: string
}
const Section: React.FC<SectionProps> = ({title, children}) => (
  <div className={styles.section}>
    <span className={styles.sectionTitle}>{title}</span>
    <div className={styles.sectionContent}>
      {children}
    </div>
  </div>
);

type PanelFavorite = {
  onUpdate: (favorite: PharmacyFavorite) => void,
  updating?: boolean,
  disable?: boolean
}

export interface PharmacySnapshotPanelProps {
  pharmacySnapshot: PharmacySnapshot
  favorite: PanelFavorite
}

const PharmacySnapshotPanel: React.FC<PharmacySnapshotPanelProps> = ({ pharmacySnapshot, favorite }) => {

  const {
    basicInfo,
    therapeuticAreas,
    services,
    payerNetwork,
    biopharmaNetwork,
    ldn,
    accreditation
  } = pharmacySnapshot || {};

  const isFavorite = !!basicInfo?.isFavorite;

  return (
    <Box>
      <ExpandablePanel
        topBar={basicInfo?.ownershipType === 'IDN' && (
          <div className={styles.topBar}>
            IDN-Owned / Institutional Specialty Pharmacy
          </div>
        )}
        header={
          <div className={styles.header}>
            <RouterLink to={'/profile/' + pharmacySnapshot.id} className={styles.title}>
              {isNotEmpty(basicInfo?.name) ? basicInfo?.name : 'Other'}
            </RouterLink>
            <div className={styles.info}>
              {isNotEmpty(pharmacySnapshot.percentMatch) && (
                <span className={styles.match}>{pharmacySnapshot.percentMatch}% Match</span>
              )}
              {favorite.updating ? <Loading size={10} paddingVertical={0} paddingHorizontal={16} /> : (
                <div
                  className={`${styles.favorite} ${favorite.disable ? styles.disabled : ''}`}
                  onClick={() =>
                    favorite.onUpdate({id: pharmacySnapshot.id, isFavorite: !isFavorite})
                  }
                >
                  <span className={styles.favoriteIcon}>
                    {isFavorite ? <StarYellowIcon/> : <StarGreyIcon/>}
                  </span>
                  <span className={styles.favoriteText}>Favorite</span>
                </div>
              )}
            </div>
          </div>
        }
        preview={(expanded) => (
          <div className={`${styles.preview} ${expanded ? styles.expanded : ''}`}>
            {isNotEmpty(therapeuticAreas) && (
              <Section title="Areas of Therapeutic Focus">
                <TherapeuticAreaTable
                  pharmacyProfile={pharmacySnapshot}
                  limit={expanded ? undefined : {
                    rows: 3,
                    label: 'Click ‘See More Attribute Highlights’ below',
                    className: styles.tableLimit
                  }}
                  className={styles.histogramTable}
                />
              </Section>
            )}
            {isNotEmpty(services) && (
              <Section title="Services Offered">
                <ServiceList
                  pharmacyProfile={pharmacySnapshot}
                  limit={expanded ? undefined : {
                    rows: 3,
                    label: 'Click ‘See More Attribute Highlights’ below',
                    className: styles.tableLimit
                  }}
                  className={styles.bulletedListTable}
                />
              </Section>
            )}
            {isNotEmpty(payerNetwork) && (
              <Section title="Payer Network Participation">
                <PayerNetworkList
                  pharmacyProfile={pharmacySnapshot}
                  columns={3}
                  limit={expanded ? undefined : {
                    rows: 1,
                    label: 'Click ‘See More Attribute Highlights’ below',
                    className: styles.tableLimit
                  }}
                  className={styles.bulletedTable}
                />
              </Section>
            )}
            {isNotEmpty(biopharmaNetwork) && (
              <Section title="Biopharma Network Participation">
                <BiopharmaNetworkList
                  pharmacyProfile={pharmacySnapshot}
                  columns={3}
                  limit={expanded ? undefined : {
                    rows: 1,
                    label: 'Click ‘See More Attribute Highlights’ below',
                    className: styles.tableLimit
                  }}
                  className={styles.bulletedTable}
                />
              </Section>
            )}
            {isNotEmpty(ldn) && (
              <Section title="Limited Distribution Network">
                <LimitedDistributionNetworkTable
                  pharmacyProfile={pharmacySnapshot}
                  limit={expanded ? undefined : {
                    rows: 3,
                    label: 'Click ‘See More Attribute Highlights’ below',
                    className: styles.tableLimit
                  }}
                  className={styles.centeredTable}
                />
              </Section>
            )}
            {basicInfo?.ownershipType === 'IDN' && (
              <Section title="IDN-Owned">
                <div className={`${styles.paragraph} ${styles.centerAlign}`}>
                  This profile is an IDN-Owned Specialty Pharmacy. If you would like a custom analysis on this bonus profile, please get in touch.
                </div>
                <div className={styles.buttonGroup}>
                  <RedirectButton
                    text="Request Custom Analysis"
                    to={'/customAnalysis/' + pharmacySnapshot.id}
                  />
                  <RedirectButton
                    text="View Partial Profile"
                    type="secondary"
                    icon={{
                      source: <ArrowRightBlueIcon/>,
                      position: "right"
                    }}
                    to={'/profile/' + pharmacySnapshot.id}
                  />
                </div>
              </Section>
            )}
          </div>
        )}
        expandLabel="See more attribute highlights"
        reduceLabel="See less attribute highlights"
      >
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {isNotEmpty(accreditation) && (
              <Section title="Accreditation">
                <AccreditationList
                  pharmacyProfile={pharmacySnapshot}
                  columns={3}
                  className={styles.bulletedTable}
                />
              </Section>
            )}
          </div>
        </div>

        <div className={styles.separator} />

        {basicInfo?.ownershipType !== 'IDN' && (
          <div className={styles.footerWrapper}>
            <div className={styles.footer}>
              <div className={styles.buttonGroup}>
                <RedirectButton
                  text={"View Full Profile"}
                  type="secondary"
                  icon={{
                    source: <ArrowRightBlueIcon/>,
                    position: "right"
                  }}
                  to={'/profile/' + pharmacySnapshot.id}
                />
              </div>
            </div>
          </div>
        )}
      </ExpandablePanel>
    </Box>
  );
};

export default PharmacySnapshotPanel;