import React, { createContext, useState } from 'react';
import {getContextFactory} from "./ContextFactory";
import {UserAccount} from "../model/user";

const UserContext = createContext<UserAccount | null>(null);
const UserUpdateContext = createContext<(user: UserAccount | null) => void>(
  () => undefined
);

export const useUserContext = getContextFactory(UserContext);
export const useUserUpdateContext = getContextFactory(UserUpdateContext);

const UserContextProvider: React.FC = props => {
  const [user, updateUser] = useState<UserAccount | null>(null);
  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={updateUser}>
        {props.children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
};

export default UserContextProvider;
