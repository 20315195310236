import {createHashHistory} from 'history';
import {isNotEmpty} from "../common/utils/common";
import {buildUrl} from "../common/utils/url";

export const history = createHashHistory();

export const redirect = (path: string) =>
  history.push(path);

export const setSearchParams = (params: string) =>
  history.replace(buildUrl(history.location.pathname, params));

/**
 * Listen to anchor updates.
 * @param onAnchor Callback for when anchor changes
 * @param callImmediately If this flag is set, the callback will be called immediately if anchor is not empty.
 */
export const anchorListener = (
  onAnchor: (name: string) => void,
  callImmediately: boolean = true
): (() => void) => {
  if (callImmediately) {
    const name = history.location.hash.replace('#', '');
    if (name.length > 0) {
      onAnchor(name);
    }
  }
  return history.listen(location => {
    const { hash } = location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const name = hash.replace('#', '');
        if (name.length > 0) {
          onAnchor(name);
        }
      }, 0);
    }
  });
};

export const pathListener = (
  onPath: (path: string) => void,
  onlyWhenEmptyHash: boolean = true
): (() => void) => {
  return history.listen(location => {
    const { hash, pathname } = location;
    if (onlyWhenEmptyHash && isNotEmpty(hash)) {
      return;
    }
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      onPath(pathname);
    }, 0);
  });
};