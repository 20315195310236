import React from "react";
import styles from "../../../styles/components/pharmacy/autocompletion/AutocompletionDropdown.module.css";
import { Scrollbars } from "react-custom-scrollbars";
import RouterLink from "../../../common/components/link/RouterLink";

export interface DropdownOption {
  title: string | null;
  value: string;
}

export interface AutocompletionDropdownProps {
  options: DropdownOption[];
}
export const AutocompletionDropdown: React.FC<AutocompletionDropdownProps> = ({
  options,
}) => {
  return (
    <div className={styles.optionsWrapper}>
      <div className={styles.optionsContainer}>
        <Scrollbars
          autoHeight
          renderTrackVertical={(props) => (
            <div {...props} className={styles.scrollTrackVertical} />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className={styles.scrollThumbVertical} />
          )}
          hideTracksWhenNotNeeded={true}
        >
          {options
            .filter((o) => !!o.title)
            .map((o) => (
              <RouterLink
                to={"/profile/" + o.value}
                className={styles.link}
                key={o.value}
              >
                <div className={styles.optionWrapper}>
                  <span className={styles.optionText}>{o.title}</span>
                </div>
              </RouterLink>
            ))}
        </Scrollbars>
      </div>
    </div>
  );
};
