import React from 'react';
import {RouteProps, Route, RouteComponentProps} from 'react-router-dom';
import AuthWrapper from "../components/AuthWrapper";

type Component =
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;

interface InnerComponentData {
  component: Component;
  data: RouteComponentProps<any>;
}

export const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={data => (
        <InnerComponent component={component as Component} data={data} />
      )}
    />
  );
};

// TODO: replace FC with more appropriate type
export const PrivateContextRoute: React.FC<RouteProps & { contextComponent: React.FC }> = ({ component, contextComponent, ...rest }) => {
  const ContextComponent = contextComponent;
  return (
    <Route
      {...rest}
      render={data => (
        <ContextComponent>
          <InnerComponent component={component as Component} data={data} />
        </ContextComponent>
      )}
    />
  );
};

const InnerComponent: React.FC<InnerComponentData> = props => {
  const Component = props.component;
  return (
    <AuthWrapper>
      <Component {...props.data} />
    </AuthWrapper>
  )
};