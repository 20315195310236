import React, {useEffect, useState} from 'react';
import Loading from "../../../common/components/Loading";
import {CheckboxSelectProps, RadioSelect} from "../../../common/components/select/SelectOption";
import {Categories, CategoryName} from "../../../model/category";
import {ActionType, useStoreContext, useStoreDispatchContext} from "../../../contexts/StoreContext";
import {useErrorUpdateContext} from "../../../contexts/ErrorContext";
import {action} from "../../../common/utils/types";
import api from "../../../rest/api";
import _ from 'lodash';

export type TreatmentCategorySelectProps = Pick<CheckboxSelectProps<CategoryName>, 'value' | 'expanded' | 'onExpanded' | 'onChange'> & {
  className?: string
  onLoad: (categories: Categories) => void
}

const TreatmentCategorySelect: React.FC<TreatmentCategorySelectProps> = ({ className, onLoad, ...props }) => {

  const store = useStoreContext();
  const storeDispatch = useStoreDispatchContext();
  const updateError = useErrorUpdateContext();

  const { categories } = store;

  const [loading, setLoading] = useState(!categories.loaded);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        if(loading) {
          const areas = await api.findAreas();
          if(!ignore) {
            const categories =
              _.chain(areas)
                .groupBy('treatmentCategory')
                .toPairs()
                .orderBy(([categoryName]) => categoryName !== 'Other' ? categoryName : null)
                .fromPairs()
                .value();
            storeDispatch(action(ActionType.InitCategories, categories));
            onLoad(categories);
            setLoading(false);
          }
        } else {
          onLoad(categories.data)
        }
      }
      catch(error) {
        if(!ignore) {
          storeDispatch(action(ActionType.InitCategories, {}));
          setLoading(false);
          updateError(error);
        }
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      {loading ? <Loading size={10} paddingVertical={18} /> : (
        <RadioSelect
          placeholder="Therapeutic Category"
          items={
            _.chain(categories.data)
              .keys()
              .map(name => ({title: name, value: name}))
              .value()
          }
          {...props}
        />
      )}
    </div>
  );
};

export default TreatmentCategorySelect;