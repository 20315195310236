import React from 'react';
import {
  ListTable,
  Table, TableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isEmpty, isNotEmpty} from "../../../common/utils/common";
import {isSingleArray} from "../../../common/utils/array";
import {ItemClassNames} from "../../../common/components/Content";
import _ from 'lodash';

export type OverviewTablesProps = Pick<TableProps, 'className'> & {
  pharmacyProfile: PharmacyProfile
  itemClassName?: ItemClassNames
}

const OverviewTables: React.FC<OverviewTablesProps> = ({ pharmacyProfile, ...props }) => {
  const { basicInfo, organizationOverview } = pharmacyProfile;
  const { ownershipType, address1, address2, phone1, foundedYear, website } = basicInfo || {};
  const classNames = props.itemClassName;
  return organizationOverview && isNotEmpty(organizationOverview) ? (
    <>
      <Table
        key='1'
        headers={['Parent Organization and Description', 'Market Coverage']}
        items={[
          [{
            label: 'Parent Organization:',
            content: organizationOverview.parentOrganizationName,
            emptyMessage: 'Not Applicable',
            classNames
          }, {
            label: 'Ownership Type:',
            content: ownershipType,
            emptyMessage: 'Not Applicable',
            classNames
          }, {
            label: 'Mergers and Acquisition:',
            content: organizationOverview.acquisition,
            emptyMessage: 'No mergers or acquisitions in 2018 or 2019 were identified for this company.',
            classNames
          }],
          [
            {label: 'Description:', content: organizationOverview.marketCoverageDescription, emptyMessage, classNames},
            {label: 'Organization Facility:', content: organizationOverview.organizationFacility, emptyMessage, classNames},
            {label: 'Facility Name:', content: organizationOverview.facilityName, emptyMessage, classNames},
            {label: 'Count:', content: organizationOverview.marketCoverageCount, emptyMessage, classNames}
          ]
        ]}
        {...props}
      />
      <Table
        key='2'
        headers={['Organization', 'Accreditation']}
        items={[
          [
            {label: 'Address:', content: isEmpty(address1) && isEmpty(address2) ? emptyMessage : (
              <ListTable
                bulleted={false}
                items={
                  _.chain([address1, address2])
                    .compact()
                    .map(address => ({content: address}))
                    .value()
                }
              />
            ), classNames},
            {label: 'Website:', content: website, emptyMessage, classNames},
            {label: 'Phone:', content: phone1, emptyMessage, classNames},
            {label: 'Founded Year:', content: foundedYear, emptyMessage, classNames},
          ], [
            {label: 'Accreditation Type:', content: isEmpty(organizationOverview.accreditationType) ? emptyMessage : (
              <ListTable
                bulleted={!isSingleArray(organizationOverview.accreditationType)}
                items={organizationOverview.accreditationType || []}
              />
            ), classNames}
          ]
        ]}
        {...props}
      />
    </>
  ) : <></>
};

const emptyMessage = 'Information not currently available';

export default OverviewTables;