import React from 'react';
import { useField, FieldAttributes } from 'formik';
import styles from '../../styles/components/form/TextField.module.css';

export interface TextFieldProps {
  label?: string;
  labelNote?: string;
  requiredValue?: boolean;
  placeholderText?: string;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  inputClassName?: string;
  variant?: 'thick';
}

const TextField: React.FC<FieldAttributes<TextFieldProps>> = props => {
  const [field, meta] = useField(props);
  const {
    label,
    labelNote,
    requiredValue,
    placeholderText,
    wrapperClassName,
    inputWrapperClassName,
    inputClassName,
    variant,
    ...inputProps
  } = props;
  const withError = meta.touched && meta.error;
  return (
    <div className={[styles.wrapper, wrapperClassName].join(' ')}>
      <label>
        {(label || labelNote) && (
          <span className={styles.labelWrapper}>
          {label && (
            <span className={styles.label}>{label}</span>
          )}
            {labelNote && (
              <span className={styles.labelNote}> &mdash; {labelNote}</span>
            )}
        </span>
        )}
        <span
          className={[
            styles.inputWrapper,
            withError ? styles.inputWithError : '',
            inputWrapperClassName,
            variant === 'thick' ? styles.thick : '',
          ].join(' ')}
        >
          <input
            required
            className={[styles.input, inputClassName].join(' ')}
            {...field}
            {
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              ...(inputProps as any)
            }
          />
          <span className={styles.placeholder}>
            {placeholderText ? (
              <>
                {placeholderText}&nbsp;
                {requiredValue ?
                  <span className={styles.required}>*</span> :
                  <span className={styles.optional}>(optional)</span>
                }
              </>
            ) : '--'}
          </span>
        </span>
      </label>
      {withError && (
        <div className={styles.error}>{meta.error}&nbsp;</div>
      )}
    </div>
  );
};

export default TextField;
