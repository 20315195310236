import React, { createContext, useState } from 'react';
import { getContextFactory } from './ContextFactory';
import {AppError} from "../model/error";

const ErrorContext = createContext<AppError | null>(null);
const ErrorUpdateContext = createContext<(error: AppError | null) => void>(
  () => undefined
);

export const useErrorContext = getContextFactory(ErrorContext);
export const useErrorUpdateContext = getContextFactory(ErrorUpdateContext);

const ErrorContextProvider: React.FC = props => {
  const [error, setError] = useState<AppError | null>(null);
  return (
    <ErrorContext.Provider value={error}>
      <ErrorUpdateContext.Provider value={setError}>
        {props.children}
      </ErrorUpdateContext.Provider>
    </ErrorContext.Provider>
  );
};

export default ErrorContextProvider;
