import React, {useEffect, useState} from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ActionButton from '../../common/components/ActionButton';
import formStyles from '../../common/styles/components/form/Forms.module.css';
import styles from '../../styles/components/pages/SupportPage.module.css';
import DefaultPage from './DefaultPage';
import TextField from '../../common/components/form/TextField';
import TextArea from '../../common/components/form/TextArea';
import DropdownField from '../../common/components/form/DropdownField';
import {useUserContext} from "../../contexts/UserContext";
import {useErrorUpdateContext} from "../../contexts/ErrorContext";
import Loading from "../../common/components/Loading";
import {SupportTopic} from "../../model/support";
import api from "../../rest/api";
import _ from 'lodash';

interface SupportFormValues {
  firstName: string;
  lastName: string;
  email: string;
  topicId: string;
  txt: string;
}

const supportSchema = (topics: SupportTopic[]) => Yup.object<SupportFormValues>().shape({
  firstName: Yup.string()
    .trim()
    .min(1, 'Too short')
    .max(50, 'Too long')
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .min(1, 'Too short')
    .max(50, 'Too long')
    .required('Last name is required'),
  email: Yup.string()
    .trim()
    .email('Must be a valid email')
    .required('Email is required'),
  topicId: Yup.string()
    .oneOf(_.map(topics, ([topicId]) => topicId), 'Please select a support need')
    .required('Support need is required'),
  txt: Yup.string()
    .trim()
    .min(10, 'Please provide more detailed description')
    .required('Description is required'),
});

export interface SupportFromProps {
  onComplete?: () => void;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const SupportForm: React.FC<SupportFromProps> = ({
  onComplete,
  firstName,
  lastName,
  email
}) => {
  const updateError = useErrorUpdateContext();

  const [topics, setTopics] = useState<SupportTopic[]>([]);
  const [topicLoading, setTopicLoading] = useState(true);

  const initialValues: SupportFormValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    topicId: '',
    txt: '',
  };

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        const data = await api.findSupportTopics();
        if(!ignore) {
          setTopics(data.topics);
          setTopicLoading(false);
        }
      }
      catch(error) {
        if(!ignore) {
          setTopicLoading(false);
          updateError(error);
        }
      }
    }
    fetchData();
    return () => {
      ignore = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={supportSchema(topics)}
      onSubmit={(values, { setSubmitting }) => {
        async function sendData() {
          try {
            setSubmitting(true);
            await api.requestSupport({
              firstName: _.trim(values.firstName),
              lastName: _.trim(values.lastName),
              email: _.trim(values.email),
              topicId: values.topicId,
              txt: _.trim(values.txt),
            });
            setSubmitting(false);
            onComplete && onComplete();
          }
          catch(error) {
            setSubmitting(false);
            updateError(error);
          }
        }
        sendData();
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={formStyles.form}>
          <div className={formStyles.formItems}>
            <div
              className={[
                formStyles.formRow,
                formStyles.formItemFullWidth,
              ].join(' ')}
            >
              <TextField
                name="firstName"
                label="First Name"
                labelNote="required"
                wrapperClassName={formStyles.formRowCell}
              />
              <TextField
                name="lastName"
                label="Last Name"
                labelNote="required"
                wrapperClassName={formStyles.formRowCell}
              />
            </div>
            <TextField
              name="email"
              label="Email"
              labelNote="required, work email preferred"
              wrapperClassName={formStyles.formItemFullWidth}
            />
            {topicLoading ? <Loading size={10} paddingVertical={29} /> : (
              <DropdownField
                name="topicId"
                options={_.map(topics, ([topicId, topicText]) => ({ value: topicId, title: topicText }))}
                label="Select Your Support Need"
                labelNote="required"
                wrapperClassName={formStyles.formItemFullWidth}
              />
            )}
            <TextArea
              name="txt"
              label="Description of Your Support Need"
              labelNote="required"
              wrapperClassName={formStyles.formItemFullWidth}
              rows={4}
            />
            <div className={formStyles.formButtons}>
              <ActionButton
                onClick={() => !isSubmitting && handleSubmit()}
                loading={isSubmitting}
                type="primary"
                text="Submit"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

const Support: React.FC<RouteComponentProps> = ({ location }) => {
  const [complete, setComplete] = useState(false);
  const user = useUserContext();
  return (
    <DefaultPage
      name='Support'
      header={{ title: 'Support' }}
    >
      <div className={styles.container}>
        {complete ? (
          <p className={styles.completeText}>
            Thank you for contacting our support!
            <br />
            <span
              className={styles.link}
              onClick={() => {
                setComplete(false);
                return false;
              }}
            >
              Have another request?
            </span>
          </p>
        ) : (
          <SupportForm
            onComplete={() => setComplete(true)}
            firstName={(user && user.givenName) || ''}
          />
        )}
      </div>
    </DefaultPage>
  );
};

export default withRouter(Support);
