import React, {Key, useEffect, useRef} from 'react';
import styles from '../styles/components/Dropdown.module.css';
import {Scrollbars} from 'react-custom-scrollbars';
import {findObj} from '../utils/array';
import {ReactComponent as ChevronDownBlueIcon} from '../../common/assets/icons/chevron_down_blue.svg';
import {ReactComponent as ChevronUpBlueIcon} from '../../common/assets/icons/chevron_up_blue.svg';
import _ from 'lodash';

export interface DropdownOption<T extends Key> {
  title: string | null;
  value: T;
}

export interface DropdownProps<T extends Key> {
  value?: T;
  options: DropdownOption<T>[];
  disabled?: boolean;
  onChange?: (value: T) => void;
  inForm?: boolean;
  placeholder?: string | JSX.Element;
  paddingVertical?: number
  paddingHorizontal?: number
}

interface EmptyTextProps {
  text?: string | JSX.Element;
}

const EmptyText = ({ text }: EmptyTextProps) => (
  <span className={styles.emptyText}>{text || '——'}</span>
);

const Dropdown = <T extends Key>({
  value,
  options,
  disabled = false,
  onChange,
  inForm = false,
  placeholder,
  paddingVertical = 12,
  paddingHorizontal = 24
}: DropdownProps<T>) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const hideDropdownMenu = useRef<(event: MouseEvent) => void>((event: MouseEvent) => {});

  useEffect(() => {
    let ignore = false; // to prevent accessing state of an unmounted component
    hideDropdownMenu.current = (event: MouseEvent) => {

      const target = event.target ? event.target as Element : null;
      const isScrollTarget = target &&
        _.some(target.classList, targetClass =>
          _.some(['scrollThumbVertical', 'scrollTrackVertical', 'scrollThumbHorizontal', 'scrollTrackHorizontal'], scrollClass =>
            _.includes(targetClass, scrollClass)
          )
        );
      if(!isScrollTarget) {
        if (!ignore && isVisible) {
          setIsVisible(false);
        }
        document.removeEventListener('mousedown', hideDropdownMenu.current);
      }
    };

    document.addEventListener('mousedown', hideDropdownMenu.current);

    return () => {
      ignore = true;
      document.removeEventListener('mousedown', hideDropdownMenu.current);
    };
  }, [isVisible]);

  const showDropdownMenu = (event: React.MouseEvent) => {
    if (!isVisible) {
      event.preventDefault();
      setIsVisible(true);
      document.addEventListener('mousedown', hideDropdownMenu.current);
    }
  };

  return (
    <div
      className={`${styles.dropdownWrapper} ${disabled ? styles.disabled : ''}`}
    >
      <div
        className={`${styles.selectionContainer} ${inForm ? styles.selectionContainerInForm : ''}`}
        onMouseDown={showDropdownMenu}
      >
        <span
          style={{ padding: `${paddingVertical}px ${paddingHorizontal}px` }}
          className={styles.selectionPlaceholderText}
        >
          {(value &&
            findObj(options, o => _.isEqual(o.value, value)).title) || (
            (placeholder && placeholder !== 'string') ? placeholder : <EmptyText text={placeholder} />
          )}
        </span>
        <div className={styles.selectionIcon}>
          {isVisible ? <ChevronUpBlueIcon/> : <ChevronDownBlueIcon/>}
        </div>
      </div>
      {isVisible && (
        <div className={styles.optionsWrapper}>
          <div className={styles.optionsContainer}>
            <Scrollbars
              autoHeight
              renderTrackVertical={props => (
                <div {...props} className={styles.scrollTrackVertical} />
              )}
              renderThumbVertical={props => (
                <div {...props} className={styles.scrollThumbVertical} />
              )}
              hideTracksWhenNotNeeded={true}
            >
              {options
                .filter(option => !!option.title)
                .map(option => (
                  <div
                    style={{ padding: `${paddingVertical}px ${paddingHorizontal}px` }}
                    className={styles.optionWrapper}
                    key={option.value}
                    onMouseDown={() => {
                      setIsVisible(false);
                      onChange && onChange(option.value);
                    }}
                  >
                    <span className={styles.optionText}>{option.title}</span>
                  </div>
                ))}
            </Scrollbars>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
