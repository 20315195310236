import React from 'react';
import RouterLink, {RouterLinkProps} from "./link/RouterLink";
import ActionButton, {ActionButtonProps} from "./ActionButton";

export type RedirectButtonProps = RouterLinkProps & Omit<ActionButtonProps, 'onClick'>

const RedirectButton: React.FC<RedirectButtonProps> = props => {
  return (
    <RouterLink to={props.to} className={props.className}>
      <ActionButton
        onClick={() => {}}
        {...props}
      />
    </RouterLink>
  )
};

export default RedirectButton;