import React, { useEffect, useState } from "react";
import { AutocompletionDropdown } from "./AutocompletionDropdown";
import { AutocompletionTextField } from "./AutocompletionTextField";
import style from "../../../styles/components/pharmacy/autocompletion/Autocompletion.module.css";
import Fuse from "fuse.js";
import { isNotEmpty } from "../../../common/utils/common";
import api from "../../../rest/api";
import { useErrorUpdateContext } from "../../../contexts/ErrorContext";
import Loading from "../../../common/components/Loading";
import { useHistory } from "react-router-dom";

export interface AutoCompletionProps {}

export const AutoCompletion: React.FC<AutoCompletionProps> = () => {
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [pharmacies, setPharmacies] = useState<
    { title: string; value: string }[]
  >([]);
  const [suggestions, setSuggestions] = useState<
    { title: string; value: string }[]
  >([]);

  const updateError = useErrorUpdateContext();

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        const data = await api.getAllPharmacies();
        if (!ignore) {
          setLoading(false);
          const res = data.map((x) => ({
            title: x.name,
            value: x.id.toString(),
          })).sort();
          setPharmacies(res);
          setSuggestions(res);
        }
      } catch (error) {
        setLoading(false);
        updateError(error);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    includeMatches: true,
    includeScore: true,
    keys: ["title"],
  };

  const handleInputChange = (input: string) => {
    if (isNotEmpty(input)) {
      const result = fuse.search(input);
      // console.log(input, result);
      setInputValue(input);
      const suggestions = result.map((x) => x.item);
      setSuggestions(suggestions);
    } else {
      setSuggestions(pharmacies);
    }
  };

  const history = useHistory();

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.keyCode === 13) {
      if (isNotEmpty(suggestions)) {
        const to = '/profile/' + suggestions[0].value
        history.push(to);
      }
    }
  }

  const fuse = new Fuse(pharmacies, options);

  return (
    <div className={style.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <AutocompletionTextField
            placeholderText="Specialty Pharmacy..."
            onInput={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <AutocompletionDropdown options={suggestions} />
        </>
      )}
      <div className={style.freeSpace}></div>
    </div>
  );
};
