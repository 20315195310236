import React from 'react';
import Loading from "../../../common/components/Loading";
import {CheckboxSelect, CheckboxSelectProps} from "../../../common/components/select/SelectOption";
import {pharmacyFilterSelectedItemsLimit as selectedItemsLimit} from "../../../model/pharmacy";
import {AreaId} from "../../../model/area";
import {useStoreContext} from "../../../contexts/StoreContext";
import {CategoryName} from "../../../model/category";
import {isEmpty, isNotEmpty} from "../../../common/utils/common";
import _ from 'lodash';

export type TherapeuticAreaSelectProps = Pick<CheckboxSelectProps<AreaId>, 'value' | 'expanded' | 'onExpanded' | 'onChange'> & {
  categoryName?: CategoryName
  className?: string
}

const TherapeuticAreaSelect: React.FC<TherapeuticAreaSelectProps> = ({ categoryName, className, ...props }) => {
  const { categories } = useStoreContext();
  return (
    <div className={className}>
      {!categories.loaded ? <Loading size={10} paddingVertical={18} /> : (
        <CheckboxSelect
          placeholder="Disease Area"
          multiple={true}
          limit={selectedItemsLimit}
          disabled={isEmpty(categoryName)}
          items={
            categoryName && isNotEmpty(categoryName) ? (
              _.chain(categories.data)
                .get(categoryName, [])
                .map(area => ({title: area.name, value: area.id}))
                .value()
            ) : []
          }
          {...props}
        />
      )}
    </div>
  );
};

export default TherapeuticAreaSelect;