import React from 'react';
import styles from '../../../styles/components/pharmacy/tabs/HomeTabs.module.css'
import { HomeTab } from './HomeTab';
import { ReactComponent as FindIcon } from "../../../common/assets/icons/filter.svg"
import { ReactComponent as SearchIcon } from "../../../common/assets/icons/search.svg"
import { ReactComponent as FavoritesIcon } from "../../../common/assets/icons/star_yellow.svg"

export interface HomeTabProps {
  activeTab: string
}



export const HomeTabs: React.FC<HomeTabProps> = ( { activeTab } ) => {  
  const tabs = [
    
    {
      name: 'find',
      label: "FIND SP BASED ON CRITERIA",
      icon: <FindIcon />,
      to: '/'
    },
    {
      name: 'search',
      label: 'SEARCH FOR SP BY NAME',
      icon: <SearchIcon />,
      to: '/search'
    },
    {
      name: 'favorites',
      label: 'VIEW FAVORITES',
      icon: <FavoritesIcon />,
      to: '/favorites'
    }
  ];

  const tabElems = tabs.map((tab) => {
    return (
      <HomeTab
        name = {tab.name}
        key = {tab.name}
        label = {tab.label}
        isActive = {tab.name === activeTab}
        icon = {tab.icon}
        to = {tab.to}
      />
    )
    });

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {tabElems}
      </div>
      
    </div>
  )
}

