import React from 'react';
import {
  BulletedListTable, BulletedListTableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import _ from 'lodash';

export type AccountSummaryListProps = Pick<BulletedListTableProps, 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const AccountSummaryList: React.FC<AccountSummaryListProps> = ({ pharmacyProfile, ...props }) => {
  const { accountSummary } = pharmacyProfile;
  return accountSummary && isNotEmpty(accountSummary) ? (
    <BulletedListTable
      items={
        _.chain(accountSummary.content)
          .filter(isNotEmpty)
          .map(value => ({content: value, attributes: accountSummary.attributes}))
          .value()
      }
      {...props}
    />
  ) : <></>
};

export default AccountSummaryList;