import React from 'react';
import {RouteProps, Route, RouteComponentProps} from 'react-router-dom';
import AuthWrapper from "../components/AuthWrapper";

type Component =
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;

interface InnerComponentData {
  component: Component;
  data: RouteComponentProps<any>;
}

export const PublicRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={data => (
        <InnerComponent component={component as Component} data={data} />
      )}
    />
  );
};

const InnerComponent: React.FC<InnerComponentData> = props => {
  const Component = props.component;
  return (
    <AuthWrapper>
      <Component {...props.data} />
    </AuthWrapper>
  );
};
