import React from 'react';
import { useField, FieldAttributes } from 'formik';
import styles from '../../styles/components/form/TextField.module.css';
import areaStyles from '../../styles/components/form/TextArea.module.css';

export interface TextAreaProps {
  label?: string;
  labelNote?: string;
  requiredValue?: boolean;
  placeholderText?: string;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  inputClassName?: string;
  variant?: 'thick';
}

const TextArea: React.FC<FieldAttributes<TextAreaProps>> = props => {
  const [field, meta] = useField(props);
  const {
    label,
    labelNote,
    requiredValue,
    placeholderText,
    wrapperClassName,
    inputWrapperClassName,
    inputClassName,
    variant,
    ...inputProps
  } = props;
  const withError = meta.touched && meta.error;
  return (
    <div className={[styles.wrapper, wrapperClassName].join(' ')}>
      <label>
        {(label || labelNote) && (
          <span className={styles.labelWrapper}>
          {label && (
            <span className={styles.label}>{label}</span>
          )}
            {labelNote && (
              <span className={styles.labelNote}> &mdash; {labelNote}</span>
            )}
        </span>
        )}
        <span
          className={[
            styles.inputWrapper,
            areaStyles.inputWrapper,
            withError ? styles.inputWithError : '',
            inputWrapperClassName,
            variant === 'thick' ? styles.thick + ' ' + areaStyles.thick : '',
          ].join(' ')}
        >
          <textarea
            required
            className={[styles.input, areaStyles.input, inputClassName].join(
              ' '
            )}
            {...field}
            {
              ...(inputProps as any)
            }
          />
          <span className={`${styles.placeholder} ${areaStyles.placeholder}`}>
            {props.placeholderText ? (
              <>
                {props.placeholderText}&nbsp;
                {props.requiredValue ?
                  <span className={styles.required}>*</span> :
                  <span className={styles.optional}>(optional)</span>
                }
              </>
            ) : '--'}
          </span>
        </span>
      </label>
      {withError && (
        <div className={styles.error}>{meta.error}&nbsp;</div>
      )}
    </div>
  );
};

export default TextArea;
