import React from 'react';
import {
  Table, TableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import _ from 'lodash';

export type LimitedDistributionNetworkTableProps = Pick<TableProps, 'limit' | 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const LimitedDistributionNetworkTable: React.FC<LimitedDistributionNetworkTableProps> = ({ pharmacyProfile, ...props }) => {
  const { ldn } = pharmacyProfile;
  return ldn && isNotEmpty(ldn) ? (
    <Table
      headers={['Brand Name', 'Generic Name', 'Biopharmaceutical company']}
      items={
        _.flatMap(ldn, network => (_.map(network.content, (value, key) => ({content: value, attributes: network.attributes}))))
      }
      {...props}
    />
  ) : <></>
};

export default LimitedDistributionNetworkTable;