import _ from 'lodash';

/**
 * Rest Errors
 */
export interface RestError {
  category: ErrorCategory
  reason: ErrorReason
  description: string
}
export const isRestError = (data: any): data is RestError => {
  return _.isObject(data) && ('category' in data) && ('reason' in data) && ('description' in data);
};
export enum ErrorCategory {
  Api = 'Api',
  Domain = 'Domain'
}
export enum ErrorReason {
  /* Api */
  UriSchemeNotAllowed = 'UriSchemeNotAllowed',
  HttpMethodNotAllowed = 'HttpMethodNotAllowed',
  AuthorizationFailed = 'AuthorizationFailed',
  MalformedFormField = 'MalformedFormField',
  MalformedHeader = 'MalformedHeader',
  MalformedQueryParam = 'MalformedQueryParam',
  MalformedRequestContent = 'MalformedRequestContent',
  RequestContentToLarge = 'RequestContentToLarge',
  MissingCookie = 'MissingCookie',
  MissingFormField = 'MissingFormField',
  MissingHeader = 'MissingHeader',
  InvalidOrigin = 'InvalidOrigin',
  MissingQueryParam = 'MissingQueryParam',
  InvalidRequiredValueForQueryParam = 'InvalidRequiredValueForQueryParam',
  RequestEntityExpected = 'RequestEntityExpected',
  TooManyRanges = 'TooManyRanges',
  CircuitBreakerOpen = 'CircuitBreakerOpen',
  UnsatisfiableRange = 'UnsatisfiableRange',
  AuthenticationFailed = 'AuthenticationFailed',
  UnacceptedResponseContentType = 'UnacceptedResponseContentType',
  UnacceptedResponseEncoding = 'UnacceptedResponseEncoding',
  UnsupportedRequestContentType = 'UnsupportedRequestContentType',
  UnsupportedRequestEncoding = 'UnsupportedRequestEncoding',
  ExpectedWebSocketRequest = 'ExpectedWebSocketRequest',
  UnsupportedWebSocketSubprotocol = 'UnsupportedWebSocketSubprotocol',
  Validation = 'Validation',
  UnhandledError = 'UnhandledError',
  UrlPathNotFound = 'UrlPathNotFound',

  /* Domain */
  InternalError = 'InternalError',
  UnexpectedData = 'UnexpectedData',
  OrganizationTypeNotFound = 'OrganizationTypeNotFound',
  SpecialtyPharmacyNotFound = 'SpecialtyPharmacyNotFound',
  TherapeuticAreaNotFound = 'TherapeuticAreaNotFound',
  InsightsServiceNotFound = 'InsightsServiceNotFound',
  OrganizationNotFound = 'OrganizationNotFound',
  ProductNotFound = 'ProductNotFound',
  OAuthClient = 'OAuthClient',
  Auth = 'Auth',
  ApplicationNotFound = 'ApplicationNotFound',
  DataNotAvailable = 'DataNotAvailable'
}

/**
 * App Errors
 */
export class AppError {
  public constructor(readonly type: ErrorType, readonly text: string) {}
}
export enum ErrorType {
  Api = 'Api',
  AuthApi = 'AuthApi',
  Network = 'Network',
  Frontend = 'Frontend',
  Backend = 'Backend'
}

/**
 * Common Errors
 */
export interface BaseError {
  response?: { status: number };
}