import React, {useEffect, useRef, useState} from 'react';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import styles from "../../../styles/components/pharmacy/list/PharmacySnapshotList.module.css";
import transitionStyles from "../../../styles/components/pharmacy/panel/PharmacySnapshotPanelTransitions.module.css";
import {
  OnlyFavoritesParams,
  PharmacyFavorite,
  PharmacyListFilterParams,
  PharmacySnapshotListResponse,
} from "../../../model/pharmacy";
import {useErrorUpdateContext} from "../../../contexts/ErrorContext";
import Loading from "../../../common/components/Loading";
import PharmacySnapshotPanel from "../panel/PharmacySnapshotPanel";
import ReactPaginate from 'react-paginate';
import {isNotEmpty} from "../../../common/utils/common";
import {ReactComponent as ArrowLeftGreyIcon} from '../../../common/assets/icons/arrow_left_grey.svg';
import {ReactComponent as ArrowRightGreyIcon} from '../../../common/assets/icons/arrow_right_grey.svg';
import {animateScroll} from "react-scroll/modules";
import {PaginationParams} from "../../../model/common";
import {Cancelable} from "../../../common/utils/types";
import api from "../../../rest/api";
import _ from 'lodash';

type DelayedRequest = (filter: PharmacyListFilterParams, isIgnore: () => boolean, callback?: () => void) => void

export interface PharmacySnapshotListPagination {
  label?: string
  align?: 'left' | 'center' | 'right'
}

export interface PharmacySnapshotListProps {
  listFilter: PharmacyListFilterParams
  onPaginationChange: (pagination: PaginationParams) => void
  delayedLoad?: boolean
  pagination?: PharmacySnapshotListPagination
  emptyMessage?: (listFilter: PharmacyListFilterParams) => React.ReactNode
}

const PharmacySnapshotList: React.FC<PharmacySnapshotListProps> = ({
  listFilter,
  onPaginationChange,
  delayedLoad = false,
  pagination = {},
  emptyMessage
}) => {
  const updateError = useErrorUpdateContext();

  const [listPagination, setListPagination] = useState<PaginationParams>(listFilter);

  const [{snapshots, total}, setPharmacySnapshotList] = useState<PharmacySnapshotListResponse>({snapshots: [], total: 0});
  const [loading, setLoading] = useState(false);
  const [updatingFavorite, setUpdatingFavorite] = useState<PharmacyFavorite | null>(null);

  const requestList: DelayedRequest = (filter, isIgnore, callback) => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await api.findPharmacySnapshots(filter);
        if(!isIgnore()) {
          setPharmacySnapshotList(response);
          setLoading(false);
          callback && callback();
        }
      }
      catch(error) {
        if(!isIgnore()) {
          setLoading(false);
          updateError(error);
          callback && callback();
        }
      }
    }
    fetchData();
  };

  const delayedRequest = useRef<DelayedRequest & Cancelable>(_.debounce(requestList, 400));

  const Pagination = ({showTotal = true}: {showTotal?: boolean}) => (
    <div className={`${styles.paginationWrapper} ${styles[pagination.align || 'center']}`}>
      {showTotal && (
        <span className={styles.total}>
          {pagination.label || 'Showing Results'}: {listPagination.os + 1}-{listPagination.os + snapshots.length} of {total}
        </span>
      )}
      <ReactPaginate
        forcePage={Math.floor(listPagination.os / listPagination.li)}
        pageCount={Math.ceil(total / listPagination.li)}
        pageRangeDisplayed={7}
        marginPagesDisplayed={2}
        onPageChange={(selectedItem: { selected: number }) => {
          onPaginationChange({os: selectedItem.selected * listPagination.li, li: listPagination.li});
          animateScroll.scrollToTop({
            duration: 400,
            delay: 0,
            offset: 0,
            smooth: 'easeInOutQuart',
          });
        }}
        previousLabel={<ArrowLeftGreyIcon/>}
        nextLabel={<ArrowRightGreyIcon/>}
        breakLabel={'...'}
        containerClassName={`${styles.pagination} ${loading || updatingFavorite ? styles.disabled : ''}`}
        pageClassName={styles.page}
        pageLinkClassName={styles.pageLink}
        activeLinkClassName={styles.pageLinkActive}
        nextClassName={styles.navigation}
        nextLinkClassName={styles.navigationLink}
        previousClassName={styles.navigation}
        previousLinkClassName={styles.navigationLink}
        disabledClassName={styles.navigationDisabled}
        breakClassName={styles.break}
        breakLinkClassName={styles.breakLink}
      />
    </div>
  );

  useEffect(() => {
    let ignore = false;
    if(delayedLoad) {
      delayedRequest.current(listFilter, () => ignore, () => setListPagination(listFilter));
    } else {
      delayedRequest.current.cancel();
      setListPagination(listFilter);
      requestList(listFilter, () => ignore);
    }
    return () => {
      ignore = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFilter]);

  useEffect(() => {
    let ignore = false;
    async function updateData() {
      try {
        if(updatingFavorite) {
          await api.updatePharmacyFavorite(updatingFavorite);
          // remove from favorites (only favorites checked)
          if(!updatingFavorite.isFavorite && listFilter.f === OnlyFavoritesParams.Yes) {
            // remove from the last page
            if(snapshots.length === 1) {
              const pageCount = Math.ceil(total / listPagination.li);
              onPaginationChange({os: (pageCount ? pageCount - 2 : 0) * listPagination.li, li: listPagination.li});
              setUpdatingFavorite(null);
            } else {
              const snapshotListResponse = await api.findPharmacySnapshots(listFilter, !updatingFavorite.isFavorite ? updatingFavorite.id : undefined);
              setPharmacySnapshotList({
                snapshots: _.filter(snapshots, snapshot => snapshot.id !== updatingFavorite.id),
                total
              });
              setTimeout(() => {
                setPharmacySnapshotList(snapshotListResponse);
                setUpdatingFavorite(null);
              }, 400);
            }
          }
          // add to favorites or remove from favorites (only favorites unchecked)
          else {
            setPharmacySnapshotList({
              snapshots: (
                _.map(snapshots, snapshot => snapshot.id !== updatingFavorite.id ? snapshot : ({
                  ...snapshot,
                  basicInfo: {...snapshot.basicInfo, isFavorite: updatingFavorite.isFavorite}
                }))
              ),
              total
            });
            setUpdatingFavorite(null);
          }
        }
      }
      catch(error) {
        if(!ignore) {
          setUpdatingFavorite(null);
          updateError(error);
        }
      }
    }
    updateData();
    return () => {
      ignore = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingFavorite]);

  return (
    <div className={styles.container}>
      {(total > listPagination.li) && (
        <Pagination key="pagination_top"/>
      )}
      {loading ? <Loading /> : (
        <div className={styles.list}>
          {
            isNotEmpty(snapshots) ?
              <TransitionGroup className={styles.transitionList}>
                {
                  _.map(snapshots, snapshot =>
                    <CSSTransition
                      key={snapshot.id}
                      timeout={400}
                      classNames={transitionStyles}
                      unmountOnExit
                    >
                      <PharmacySnapshotPanel
                        key={snapshot.id}
                        pharmacySnapshot={snapshot}
                        favorite={{
                          onUpdate: (favorite) => setUpdatingFavorite(favorite),
                          updating: updatingFavorite?.id === snapshot.id,
                          disable: !!updatingFavorite && (updatingFavorite.id !== snapshot.id)
                        }}
                      />
                    </CSSTransition>
                  )
                }
              </TransitionGroup> :
              emptyMessage && emptyMessage(listPagination)
          }
        </div>
      )}
      {!loading && (total > listPagination.li) && (
        <Pagination key="pagination_bottom" showTotal={false}/>
      )}
    </div>
  );
};

export default PharmacySnapshotList;