import React, {useEffect, useState} from 'react';
import Loading from "../../../common/components/Loading";
import {CheckboxSelect, CheckboxSelectProps} from "../../../common/components/select/SelectOption";
import {pharmacyFilterSelectedItemsLimit as selectedItemsLimit} from "../../../model/pharmacy";
import {ActionType, useStoreContext, useStoreDispatchContext} from "../../../contexts/StoreContext";
import {useErrorUpdateContext} from "../../../contexts/ErrorContext";
import {BiopharmaParticipationId} from "../../../model/biopharma";
import {action} from "../../../common/utils/types";
import api from "../../../rest/api";
import _ from 'lodash';

export type BiopharmaParticipationSelectProps = Pick<CheckboxSelectProps<BiopharmaParticipationId>, 'value' | 'expanded' | 'onExpanded' | 'onChange'> & {
  className?: string
}

const BiopharmaParticipationSelect: React.FC<BiopharmaParticipationSelectProps> = ({ className, ...props }) => {

  const store = useStoreContext();
  const storeDispatch = useStoreDispatchContext();
  const updateError = useErrorUpdateContext();

  const { biopharmaParticipation } = store;

  const [loading, setLoading] = useState(!biopharmaParticipation.loaded);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        if(loading) {
          const data = await api.findBiopharmaParticipation();
          if(!ignore) {
            storeDispatch(action(ActionType.InitBiopharmaParticipation, data));
            setLoading(false);
          }
        }
      }
      catch(error) {
        if(!ignore) {
          setLoading(false);
          updateError(error);
        }
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      {loading ? <Loading size={10} paddingVertical={18} /> : (
        <CheckboxSelect
          placeholder="Biopharma Network Participation"
          multiple={true}
          limit={selectedItemsLimit}
          items={_.map(biopharmaParticipation.data, participation => ({title: participation.name, value: participation.id}))}
          {...props}
        />
      )}
    </div>
  )
};

export default BiopharmaParticipationSelect;