import React from 'react';
import styles from "../styles/components/Content.module.css";
import {PartialNullable, SectionItem} from "../utils/types";
import {isEmpty, isNotEmpty} from "../utils/common";

export type ItemClassNames = PartialNullable<{
  label: string
  content: string
}>

export type Item<T> = Pick<SectionItem<T>, 'content'> & PartialNullable<{
  label:  string
  emptyMessage?: string
  classNames?: ItemClassNames
}>
export type StringItem = Item<string>
export type HighlightedItem = Item<string | number> & Pick<SectionItem<string | number>, 'attributes'>
export type ElementItem = Item<React.ReactNode>

export type ContentElementProps = StringItem | HighlightedItem | ElementItem

export const ContentElement: React.FC<ContentElementProps> = (item) => {
  const classNames = item.classNames || {};
  return (
    <div className={styles.container}>
      {isNotEmpty(item.label) && (
        <div className={`${styles.label} ${classNames.label || ''}`}>
          {item.label}
        </div>
      )}
      {isEmpty(item.content) ? item.emptyMessage : (
        <div className={`${styles['content' + ((isHighlighted(item) && item.attributes?.isMatch) || '')]} ${classNames.content || ''}`}>
          {item.content}
        </div>
      )}
    </div>
  )
};

function isHighlighted(item: ContentElementProps): item is HighlightedItem {
  return !!(item as HighlightedItem).attributes;
}

export default ContentElement;