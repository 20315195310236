import React, {useEffect, useRef} from 'react';
import styles from '../../../styles/components/pharmacy/popup/NotifyPopup.module.css';
import ActionButton from "../../../common/components/ActionButton";
import RedirectButton from "../../../common/components/RedirectButton";
import {ReactComponent as CrossBlueIcon} from "../../../common/assets/icons/cross_blue.svg";

export type NotifyPopupProps = {
  message: string
  onHide: () => void
  target: HTMLDivElement
}

const NotifyPopup: React.FC<NotifyPopupProps> = ({ message, target, onHide }) => {

  const popupRef = useRef<HTMLDivElement>(null);
  const hidePopupRef = useRef<(event: MouseEvent) => void>(() => {});
  const scrollRef = useRef<() => void>(() => {});

  useEffect(() => {
    let ignore = false;
    hidePopupRef.current = (event: MouseEvent) => {
      const eventTarget = event.target ? event.target as Element : null;
      if(!popupRef.current?.contains(eventTarget) && !ignore) {
        onHide();
      }
    };
    scrollRef.current = () => {
      const popup = popupRef.current;
      if(popup) {
        const additionalOffsetXY = 20;

        const scrollTopY = (document.documentElement.scrollTop || document.body.scrollTop) + additionalOffsetXY;
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const headerHeight = document.getElementById('header')?.offsetHeight || 0;

        const targetWidth = target.offsetWidth;
        const targetLeft = target.offsetLeft;
        const targetMiddleY = target.offsetTop + target.offsetHeight + headerHeight;

        const popupHeight = popup.offsetHeight;
        const popupWidth = popup.offsetWidth;
        const popupTopY = targetMiddleY - popupHeight/2 - additionalOffsetXY;
        const popupBottomY = targetMiddleY + popupHeight/2 + additionalOffsetXY;

        if(scrollTopY > popupTopY) {
          popup.style.position = 'fixed';
          popup.style.top = additionalOffsetXY + 'px';
          popup.style.bottom = 'auto';
        }
        else if((scrollTopY + windowHeight) < popupBottomY) {
          popup.style.position = 'fixed';
          popup.style.top = 'auto';
          popup.style.bottom = additionalOffsetXY + 'px';
        }
        else {
          popup.style.position = 'absolute';
          popup.style.top = popupTopY - headerHeight + 'px';
          popup.style.bottom = 'auto';
        }

        const rightSideWidth = windowWidth - targetWidth - targetLeft - additionalOffsetXY;
        if(rightSideWidth > popupWidth) {
          popup.style.left = targetLeft + targetWidth + additionalOffsetXY + 'px';
        } else {
          popup.style.left = 'auto';
        }
        popup.style.right = additionalOffsetXY + 'px';
        popup.style.opacity = '1';
      }
    };
    scrollRef.current();
    document.addEventListener('click', hidePopupRef.current);
    document.addEventListener('scroll', scrollRef.current);
    return () => {
      ignore = true;
      document.removeEventListener('click', hidePopupRef.current);
      document.removeEventListener('scroll', scrollRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={popupRef}
      className={styles.container}
    >
      <div className={styles.image}>
        <img src={require("../../../assets/images/notify_popup.svg")} alt="" />
      </div>
      <div className={styles.text}>
        {message}
      </div>
      <div className={styles.contactUs}>
        <RedirectButton
          text="Contact us"
          type="secondaryAlt"
          to="/support"
        />
      </div>
      <span className={styles.close}>
        <ActionButton
          type="icon"
          paddingVertical={4}
          paddingHorizontal={4}
          icon={{
            source: <CrossBlueIcon/>,
            size: 'large',
          }}
          onClick={onHide}
        />
      </span>
    </div>
  )
};

export default NotifyPopup;