import React, {Key} from 'react';
import styles from '../../styles/components/select/Select.module.css';
import AnimateHeight from "react-animate-height";
import {SelectOption} from "./SelectOption";
import {addUnique, removeByValue} from "../../utils/array";
import {isNotEmpty} from "../../utils/common";
import {ReactComponent as ArrowUpBlueIcon} from "../../assets/icons/arrow_up_blue.svg";
import {ReactComponent as ArrowDownBlueIcon} from "../../assets/icons/arrow_down_blue.svg";
import {useErrorUpdateContext} from "../../../contexts/ErrorContext";
import {ErrorType} from "../../../model/error";
import _ from 'lodash';

export interface SelectItem<T extends Key> {
  title: string | null
  value: T
}

export interface SelectProps<T extends Key> {
  value?: T | T[]
  items: SelectItem<T>[]
  placeholder: string
  multiple?: boolean
  limit?: number
  disabled?: boolean
  expanded?: boolean
  onExpanded: (expanded: boolean) => void
  onChange: (value: T | T[] | undefined) => void
  OptionComponent: (option: SelectOption<T>) => JSX.Element;
}

const Select = <T extends Key>({
  value,
  items = [],
  placeholder,
  multiple = false,
  limit = 50,
  disabled,
  expanded = false,
  onExpanded,
  onChange,
  OptionComponent
}: SelectProps<T>) => {

  const updateError = useErrorUpdateContext();

  const normalizedValue = !multiple ?
    _.chain(items)
      .find(item => item.value === value)
      .get('value')
      .value() :
    _.chain(items)
      .filter(item => _.includes(value as [], item.value))
      .map(item => item.value)
      .value();

  return (
    <div className={styles.container}>
      <div className={`${styles.selection} ${disabled || !items.length ? styles.disabled : ''}`} onClick={() => onExpanded(!expanded)}>
        <span className={styles.placeholder}>
          {placeholder}
          <span className={styles.selectedInfo}>
            {multiple ? (
              isNotEmpty(normalizedValue) ? `(${(normalizedValue as []).length} selected)` : ''
            ) : (
              isNotEmpty(normalizedValue) ? '' : '(choose one)'
            )}
          </span>
        </span>
        <span className={styles.selectionIcon}>
          {expanded ? <ArrowUpBlueIcon/> : <ArrowDownBlueIcon/>}
        </span>
      </div>
      {!disabled && (
        <AnimateHeight
          duration={600}
          height={expanded ? 'auto' : 0}
        >
          <div className={styles.list}>
            {multiple && (
              <div className={styles.actions}>
                <div
                  className={`${styles.clearAction} ${isNotEmpty(normalizedValue) ? styles.activeAction : styles.inactiveAction}`}
                  onClick={() => onChange([])}
                >
                  Clear All
                </div>
              </div>
            )}
            <div className={styles.options}>
              {_.map(items, item => (
                <OptionComponent
                  key={item.value}
                  item={item}
                  active={
                    _.isArray(normalizedValue) ? _.some(normalizedValue, v => v === item.value) : normalizedValue === item.value
                  }
                  onChange={(active) => {
                    if(_.isArray(normalizedValue)) {
                      if(active && (normalizedValue.length >= limit)) {
                        updateError({
                          type: ErrorType.Frontend,
                          text: `Maximum number of selected items reached for filter "${placeholder}" (max. 50 items)`
                        })
                      } else {
                        onChange(active ? addUnique(normalizedValue, item.value) : removeByValue(normalizedValue, item.value));
                      }
                    } else {
                      onChange(active ? item.value : undefined);
                      if(active) {
                        onExpanded(false);
                      }
                    }
                  }}
                />
              ))}
            </div>
            <div className={styles.actions}>
              {multiple && (
                <div
                  className={`${styles.clearAction} ${isNotEmpty(normalizedValue) ? styles.activeAction : styles.inactiveAction}`}
                  onClick={() => onChange([])}
                >
                  Clear All
                </div>
              )}
              <div
                className={`${styles.closeAction} ${styles.activeAction}`}
                onClick={() => onExpanded(false)}
              >
                Close <ArrowUpBlueIcon/>
              </div>
            </div>
          </div>
        </AnimateHeight>
      )}
    </div>
  )
};

export default Select;