import React from 'react';
import styles from '../styles/components/BrowserChecker.module.css';
import {isUnsupportedBrowser} from "../utils/browser";

const BrowserChecker: React.FC = props => {
  const isUnsupported = isUnsupportedBrowser();
  return (
    <>
      {isUnsupported ? (
        <div className={styles.container}>
          <div>
            <div className={styles.contentWrapper}>
              <div className={styles.content}>
                <span className={styles.errorText}>
                  You are using an unsupported browser.
                  <br />
                  To proceed use one of the supported browsers.
                </span>
              </div>
              <div className={styles.actions}>
                <a
                  className={styles.action}
                  href="https://www.google.com/chrome/"
                >
                  Chrome
                </a>
                <a
                  className={styles.action}
                  href="https://www.mozilla.org/firefox/"
                >
                  Firefox
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};

export default BrowserChecker;