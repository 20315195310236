import React from 'react';
import {
  Table, TableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import _ from 'lodash';

export type CorporateStrategyTableProps = Pick<TableProps, 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const CorporateStrategyTable: React.FC<CorporateStrategyTableProps> = ({ pharmacyProfile, ...props }) => {
  const { corporateStrategy } = pharmacyProfile;
  return corporateStrategy && isNotEmpty(corporateStrategy) ? (
    <Table
      headers={['Goals', 'Tactics']}
      items={
        _.flatMap(corporateStrategy, strategy => (_.map(strategy.content, (value, key) => ({content: value, attributes: strategy.attributes}))))
      }
      {...props}
    />
  ) : <></>
};

export default CorporateStrategyTable;