import { PartialNullable } from './types';
import {isNotEmpty} from "./common";
import _ from 'lodash';

export const transfer = <T>(
  source: T[],
  destination: T[],
  predicate: (element: T) => boolean
) => {
  const [_source, _destination] = [[...source], [...destination]];
  const position = _.findIndex(_source, predicate);
  if (position !== -1) {
    _destination.push(_source[position]);
    _source.splice(position, 1);
  }
  return [_source, _destination];
};

export const move = <T>(array: T[], fromIndex: number, toIndex: number) => {
  const _array = [...array];
  _array.splice(toIndex, 0, _array.splice(fromIndex, 1)[0]);
  return _array;
};

export const moveToEnd = <T>(array: T[], predicate: (element: T) => boolean) => {
  const [movableElements, baseElements] = _.partition(array, predicate);
  return _.concat(baseElements, movableElements);
};

export const add = <T>(array: T[], value: T): T[] => {
  const _array = [...array];
  _array.push(value);
  return _array;
};

export const addUnique = <T>(array: T[], value: T): T[] => {
  return _.includes(array, value) ? array : add(array, value);
};

export const remove = <T>(array: T[], predicate: ((element: T) => boolean) | number) => {
  const _array = [...array];
  if(typeof predicate === "number") {
    _array.splice(predicate, 1);
  } else {
    _.remove(_array, predicate);
  }
  return _array;
};

export const removeByValue = <T>(array: T[], value: T) => {
  const _array = [...array];
  _.remove(_array, element => element === value);
  return _array;
};

export const replaceAt = <T>(array: T[], index: number, value: T) => {
  const _array = [...array];
  _array[index] = value;
  return _array;
};

export const headObj = <T extends object>(
  array: T[]
): T | PartialNullable<T> => {
  return _.head(array) || {};
};

export const findNext = <T>(
  array: T[],
  predicate: (element: T) => boolean
): T | null => {
  const _array = [...array];
  const position = _.findIndex(_array, predicate);
  if (position !== -1 && _array.length > position + 1) {
    return _array[position + 1];
  }
  return null;
};

export const findObj = <T extends object>(
  array: T[],
  predicate: (object: T) => boolean
): T | PartialNullable<T> => {
  return _.find(array, predicate) || {};
};

export const random = <T>(array: T[]): T | undefined => {
  return _.isEmpty(array) ? undefined : array[_.random(0, array.length - 1)];
};

export const joinNotEmpties = <T>(array: T[], separator?: string) =>
  _.chain(array)
    .filter(isNotEmpty)
    .join(separator)
    .value();

export const isSingleArray = (value: any): value is [] =>
  _.isArray(value) && value.length === 1;