import React, {Key} from 'react';
import styles from '../../styles/components/select/SelectOption.module.css';
import Select, {SelectItem, SelectProps} from "./Select";
import {ReactComponent as CheckboxHoverBlueIcon} from "../../assets/icons/checkbox_hover_blue.svg";
import {ReactComponent as CheckboxCheckedBlueIcon} from "../../assets/icons/checkbox_checked_blue.svg";
import {ReactComponent as RadioHoverBlueIcon} from "../../assets/icons/radio_hover_blue.svg";
import {ReactComponent as RadioCheckedBlueIcon} from "../../assets/icons/radio_checked_blue.svg";

export interface SelectOption<T extends Key> {
  item: SelectItem<T>
  active?: boolean
  onChange: (active: boolean) => void
}

export type CheckboxSelectProps<T extends Key> = Omit<SelectProps<T>, 'OptionComponent'>

export const CheckboxSelect = <T extends Key>(props: CheckboxSelectProps<T>) => {
  return (
    <Select
      OptionComponent={({ item, active, onChange }) => (
        <div className={styles.option} onClick={() => onChange(!active)}>
          <span className={styles.checkbox}>
            {active ? <CheckboxCheckedBlueIcon/> : <CheckboxHoverBlueIcon/>}
          </span>
          <span className={styles.title}>{item.title}</span>
        </div>
      )}
      {...props}
    />
  )
};

export type RadioSelectProps<T extends Key> = Omit<SelectProps<T>, 'OptionComponent'>

export const RadioSelect = <T extends Key>(props: RadioSelectProps<T>) => {
  return (
    <Select
      OptionComponent={({ item, active, onChange }) => (
        <div className={styles.option} onClick={() => onChange(!active)}>
          <span className={styles.radio}>
            {active ? <RadioCheckedBlueIcon/> : <RadioHoverBlueIcon/>}
          </span>
          <span className={styles.title}>{item.title}</span>
        </div>
      )}
      {...props}
    />
  )
};