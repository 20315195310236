import React from 'react';
import {Router, Switch} from 'react-router-dom';
import {history} from './helpers/router';
import {PublicRoute} from "./router/PublicRoute";
import {PrivateContextRoute, PrivateRoute} from "./router/PrivateRoute";
import EngagementPage from "./components/pages/EngagementPage";
import HomePage from "./components/pages/HomePage";
import FavoritesPage from "./components/pages/FavoritesPage";
import { SearchPage } from "./components/pages/SearchPage";
import ProfilePage from "./components/pages/ProfilePage";
import ErrorContextProvider from "./contexts/ErrorContext";
import UserContextProvider from "./contexts/UserContext";
import StoreContextProvider from "./contexts/StoreContext";
import UISettingsContextProvider from "./contexts/UISettingsContext";
import PageHistoryContextProvider from "./contexts/PageHistoryContext";
import CustomAnalysisPage from "./components/pages/CustomAnalysisPage";
import SupportPage from "./components/pages/SupportPage";
import NotFoundPage from "./components/pages/error/NotFoundPage";
import ScrollToTop from "./common/components/ScrollToTop";
import BrowserChecker from "./common/components/BrowserChecker";
import PharmacyListFilterContextProvider from "./contexts/PharmacyFilterContext";
import SectionPopupContextProvider from "./contexts/SectionPopupContext";

const App: React.FC = () => {
  return (
    <BrowserChecker>
      <ErrorContextProvider>
        <UserContextProvider>
          <PageHistoryContextProvider defaultPage={{name: "Search", path: "/home"}}>
            <StoreContextProvider>
              <UISettingsContextProvider>
                <SectionPopupContextProvider>
                  <Router history={history}>
                    <ScrollToTop />
                    <Switch>
                      <PublicRoute exact path="/" component={EngagementPage} />
                      <PrivateContextRoute exact path="/home" component={HomePage} contextComponent={PharmacyListFilterContextProvider} />
                      <PrivateRoute exact path="/favorites" component={FavoritesPage} />
                      <PrivateRoute exact path="/search" component={SearchPage} />
                      <PrivateRoute exact path="/profile/:pharmacyId" component={ProfilePage} />
                      <PrivateRoute exact path="/customAnalysis/:pharmacyId" component={CustomAnalysisPage} />
                      <PrivateRoute exact path="/support" component={SupportPage} />
                      <PrivateRoute component={NotFoundPage} />
                    </Switch>
                  </Router>
                </SectionPopupContextProvider>
              </UISettingsContextProvider>
            </StoreContextProvider>
          </PageHistoryContextProvider>
        </UserContextProvider>
      </ErrorContextProvider>
    </BrowserChecker>
  );
};

export default App;
