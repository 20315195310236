import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {AppError, BaseError, ErrorCategory, ErrorReason, ErrorType, isRestError} from "../model/error";
import {redirectWindowToAuth} from "../helpers/window";
import config from "../config";

const clientConfig = {
  baseURL: config.apiUrl,
  timeout: 30000,
  withCredentials: true
};

let [instanceWithInterceptors, instanceWithoutInterceptors]: (AxiosInstance | null)[] = [null, null];
const retryCount: number = 30;

const init = () => {
  instanceWithInterceptors = axios.create(clientConfig);
  instanceWithInterceptors.interceptors.response.use(
    response => response,
    error => authErrorInterceptor(error)
  );
  instanceWithoutInterceptors = axios.create(clientConfig);
};

const authErrorInterceptor = (error: BaseError) => {
  if(error.response && error.response.status === 401) {
    redirectWindowToAuth();
  }
  return Promise.reject({ ...error });
};

const request = <T>(config: RequestConfig, retryCount: number): Promise<T> => {
  const instance = config.withoutInterceptors ? instanceWithoutInterceptors : instanceWithInterceptors;
  if(!instance) {
    return Promise.reject(new AppError(
      ErrorType.Frontend,
      'Something went wrong. The network client wasn\'t initialized before call'
    ))
  }
  return instance(config.url, config)
    .then(response => response.data)
    .catch((error: AxiosError) => {
      console.log(error.toJSON());
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const data = error.response.data;
        if(isRestError(data)) {
          throw new AppError(
            data.category === ErrorCategory.Api && data.reason === ErrorReason.AuthenticationFailed ? ErrorType.AuthApi : ErrorType.Api,
            `Something went wrong. API Error Code: ${data.reason} (${data.description})`
          );
        }
        throw new AppError(
          ErrorType.Backend,
          `Something went wrong. Error Code: ${error.response.status}`
        );
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        if(retryCount) {
          return request(config, retryCount - 1);
        } else {
          throw new AppError(
            ErrorType.Network,
            'Network Issue. The request was made but no response was received'
          );
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new AppError(
          ErrorType.Frontend,
          `Something happened in setting up the request that triggered an Error. ${error.message}`
        );
      }
    });
};

interface RequestConfig extends AxiosRequestConfig {
  url: string
  withoutInterceptors?: boolean
  retry?: boolean | null
}

const get = <T>(config: RequestConfig): Promise<T> => doRequest(config, 'get');
const post = <T>(config: RequestConfig): Promise<T> => doRequest(config, 'post');
const put = <T>(config: RequestConfig): Promise<T> => doRequest(config, 'put');
const del = <T>(config: RequestConfig): Promise<T> => doRequest(config, 'delete');

const doRequest = <T>(config: RequestConfig, method: 'get' | 'post' | 'put' | 'delete'): Promise<T> =>
  request({...config, method}, config.retry ? retryCount : 0);

export default { init, get, post, put, del };