export interface AppConfig {
  type: 'fake' | 'local' | 'dev' | 'uat' | 'prod' | 'staging'
  publicUrl: string
  apiUrl: string | undefined
}

const fakeConfig: AppConfig = {
  type: 'fake',
  publicUrl: 'http://' + document.location.hostname,
  apiUrl: undefined
};
const localConfig: AppConfig = {
  type: 'local',
  publicUrl: 'http://localhost',
  apiUrl: 'http://localhost/svc',
};
const devConfig: AppConfig = {
  type: 'dev',
  publicUrl: 'https://dev.spo.healthstrategies.com',
  apiUrl: 'https://dev-svc.spo.healthstrategies.com'
};
const uatConfig: AppConfig = {
  type: 'uat',
  publicUrl: 'https://testing.spo.healthstrategies.com',
  apiUrl: 'https://testing-svc.spo.healthstrategies.com'
};
const prodConfig: AppConfig = {
  type: 'prod',
  publicUrl: 'https://spo.healthstrategies.com',
  apiUrl: 'https://spo-svc.healthstrategies.com'
};
const stagingConfig: AppConfig = {
  type: 'staging',
  publicUrl: 'https://staging.spo.healthstrategies.com',
  apiUrl: 'https://staging-svc.spo.healthstrategies.com'
}

const getConfig = (): AppConfig => {
  switch (process.env.REACT_APP_STAGE) {
    case 'fake':
      return fakeConfig;
    case 'local':
      return localConfig;
    case 'dev':
      return devConfig;
    case 'uat':
      return uatConfig;
    case 'prod':
      return prodConfig;
    case 'staging':
      return stagingConfig;
    default:
      throw new Error(
        `Unknown REACT_APP_STAGE env was used in app configuration`
      );
  }
};

const config = getConfig();

export default config;
