import React from 'react';
import {PharmacyProfile} from "../../../model/pharmacy";
import ContentElement from "../../../common/components/Content";
import {isNotEmpty} from "../../../common/utils/common";

export type InsightsTextProps = {
  pharmacyProfile: PharmacyProfile
}

const InsightsText: React.FC<InsightsTextProps> = ({ pharmacyProfile }) => {
  const { insights } = pharmacyProfile;
  return insights && isNotEmpty(insights) ? (
    <ContentElement {...insights} />
  ) : <></>
};

export default InsightsText;