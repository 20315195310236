import React, {useState} from 'react';
import styles from '../../styles/components/pages/HomePage.module.css';
import DefaultPage from "./DefaultPage";
import {RouteComponentProps, withRouter} from "react-router";
import {HomeRouterParams} from "../../router/params/HomeRouterParams";
import PharmacySnapshotFilterCard from "../pharmacy/card/PharmacySnapshotFilterCard";
import PharmacySnapshotList from "../pharmacy/list/PharmacySnapshotList";
import useMediaQuery from "../../common/hooks/useMediaQuery";
import {usePharmacyListFilterContext, usePharmacyListFilterUpdateContext} from "../../contexts/PharmacyFilterContext";
import {
  defaultPharmacyListPaginationParams as defaultPaginationParams,
  OwnershipTypeParams
} from "../../model/pharmacy";
import {ReactComponent as CheckboxCheckedGreyIcon} from '../../common/assets/icons/checkbox_checked_grey.svg';
import { HomeTabs } from '../pharmacy/tabs/HomeTabs';

type HomePageProps = RouteComponentProps<HomeRouterParams>;

const HomePage: React.FC<HomePageProps> = ({ history, match }) => {

  const { Independent, All } = OwnershipTypeParams;

  const [listFilter, setListFilter] = [usePharmacyListFilterContext(), usePharmacyListFilterUpdateContext()];

  const [delayedLoad, setDelayedLoad] = useState<boolean>(false);
  const [filterExpanded, setFilterExpanded] = useState<boolean>(true);

  const up1980 = useMediaQuery('(min-width: 1980px)');

  return (
    <DefaultPage
      name='Find'
      header={{
        title: (
          <span className={styles.headerTitle}>
            My <span className={styles.headerTitleAbbreviation}>SP</span>OPTIMIZER
          </span>
        ),
        subtitle: 'Find the right specialty pharmacy based on your business requirements.'
      }}
      wideContent={true}
    >
      <HomeTabs activeTab="find"></HomeTabs>
      <div className={styles.container}>
        <div className={`${styles.content} ${(!filterExpanded && styles.centered) || (up1980 && styles.spaced) || ''}`}>
          <div className={`${styles.filter} ${!filterExpanded ? styles.absolute : ''}`}>
            <PharmacySnapshotFilterCard
              filter={listFilter}
              onFilterChange={updatedFilter => {
                setDelayedLoad(true);
                setListFilter({...listFilter, ...updatedFilter, ...defaultPaginationParams});
              }}
              expanded={filterExpanded}
              setExpanded={setFilterExpanded}
            />
          </div>
          <div className={styles.list}>
            <PharmacySnapshotList
              listFilter={listFilter}
              onPaginationChange={updatedPagination => {
                setDelayedLoad(false);
                setListFilter({...listFilter, ...updatedPagination});
              }}
              pagination={{
                align: filterExpanded ? 'left' : 'center'
              }}
              delayedLoad={delayedLoad}
              emptyMessage={(listFilter) => (
                <div className={styles.emptyList}>
                  <div className={styles.image}>
                    <img src={require("../../assets/images/no_results.png")} alt="" />
                  </div>
                  <div className={styles.title}>
                    No Results Found!
                  </div>
                  {listFilter.ot !== All && (
                    <div className={styles.description}>
                      <span className={styles.icon}>
                        <CheckboxCheckedGreyIcon/>
                      </span>
                      <span>
                        Try checking&nbsp;
                        <span className={styles.bold}>
                          ‘{listFilter.ot === Independent ? 'IDN-Owned / Institutional' : 'Independent Owned'} Specialty Pharmacies’
                        </span>
                        &nbsp;in the left panel to see your results.
                      </span>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
          {filterExpanded && up1980 && (
            <div className={styles.blank}>
              {/* Blank element to keep list centered if filter is expanded */}
            </div>
          )}
        </div>
      </div>
    </DefaultPage>
  )
};

export default withRouter(HomePage);
