import { RestApi } from "./api";
import { UserAccount } from "../model/user";
import { CustomAnalysisRequest } from "../model/customAnalysis";
import {
  OnlyFavoritesParams,
  PharmacyFavoriteUpdateRequest,
  PharmacyFavoriteUpdateResponse,
  PharmacyProfile,
  PharmacyProfileRequest,
  PharmacyProfileResponse,
  PharmacyReferenceListResponse,
  PharmacySnapshotListRequest,
  PharmacySnapshotListResponse,
} from "../model/pharmacy";
import pharmacyProfilesJson from "./data/pharmacy-profiles.json";
import therapeuticAreasJson from "./data/therapeutic-areas.json";
import servicesJson from "./data/services.json";
import payerParticipationJson from "./data/payer-participation.json";
import biopharmaParticipationJson from "./data/biopharma-participation.json";
import supportTopicsJson from "./data/support-topics.json";
import { AreaListResponse } from "../model/area";
import { ServiceListResponse } from "../model/service";
import { PayerParticipationListResponse } from "../model/payer";
import { BiopharmaParticipationListResponse } from "../model/biopharma";
import { isNotEmpty } from "../common/utils/common";
import { SupportRequest, SupportTopicListResponse } from "../model/support";
import _ from "lodash";

const user: UserAccount = {
  givenName: "Jennifer",
};

export class FakeApi implements RestApi {
  private readonly timeout: () => number = () => _.random(1, 3) * 1000;

  private promiseOf<T>(data: T): Promise<T> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(data);
      }, this.timeout());
    });
  }

  /**
   * Therapeutic Area
   */
  findAreas(): Promise<AreaListResponse> {
    const areas = therapeuticAreasJson as AreaListResponse;
    return this.promiseOf(areas);
  }

  /**
   * Service
   */
  findServices(): Promise<ServiceListResponse> {
    const services = servicesJson as ServiceListResponse;
    return this.promiseOf(services);
  }

  /**
   * Payer Network Participation
   */
  findPayerParticipation(): Promise<PayerParticipationListResponse> {
    const payerParticipation = payerParticipationJson as PayerParticipationListResponse;
    return this.promiseOf(payerParticipation);
  }

  /**
   * Biopharma Network Participation
   */
  findBiopharmaParticipation(): Promise<BiopharmaParticipationListResponse> {
    const biopharmaParticipation = biopharmaParticipationJson as BiopharmaParticipationListResponse;
    return this.promiseOf(biopharmaParticipation);
  }

  /**
   * Pharmacy Profile
   */
  findPharmacyProfile(
    request: PharmacyProfileRequest
  ): Promise<PharmacyProfileResponse> {
    const pharmacyProfiles = pharmacyProfilesJson as PharmacyProfile[];
    return this.promiseOf(
      pharmacyProfiles[
        _.findIndex(pharmacyProfiles, (profile) => profile.id === request.id)
      ]
    );
  }

  /**
   * Pharmacy Snapshot
   */
  findPharmacySnapshots(
    request: PharmacySnapshotListRequest,
    id?: number
  ): Promise<PharmacySnapshotListResponse> {
    const pharmacySnapshots = pharmacyProfilesJson as PharmacyProfile[];
    const snapshots = _.filter(pharmacySnapshots, (pharmacySnapshot) => {
      // TODO: check also areaIds pnp, bnp and so on
      return !(
        (isNotEmpty(request.ta) &&
          !_.filter(pharmacySnapshot.therapeuticAreas, (area) =>
            _.includes(request.ta, area.content?.id)
          ).length) ||
        (request.f === OnlyFavoritesParams.Yes &&
          !pharmacySnapshot.basicInfo?.isFavorite) ||
        id === pharmacySnapshot.id
      );
    });
    return this.promiseOf({
      snapshots: _.drop(snapshots, request.os).slice(0, request.li),
      total: snapshots.length,
    });
  }

  /**
   * Pharmacy Favorite
   */
  updatePharmacyFavorite(
    request: PharmacyFavoriteUpdateRequest
  ): Promise<PharmacyFavoriteUpdateResponse> {
    return this.promiseOf(request.isFavorite);
  }

  /**
   * Custom Analysis
   */
  requestCustomAnalysis(request: CustomAnalysisRequest): Promise<string> {
    return this.promiseOf(""); // TODO:
  }

  /**
   * Support
   */
  requestSupport(request: SupportRequest): Promise<string> {
    return this.promiseOf(""); // TODO:
  }
  findSupportTopics(): Promise<SupportTopicListResponse> {
    const topics = supportTopicsJson as SupportTopicListResponse;
    return this.promiseOf(topics);
  }

  /**
   * User
   */
  findUser(): Promise<UserAccount> {
    return this.promiseOf(user);
  }

  getAllPharmacies(): Promise<PharmacyReferenceListResponse> {
    return this.promiseOf([]);
  }
}

export function randomString() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function randomNumber(max?: number) {
  return max !== undefined
    ? Math.floor(Math.random() * Math.floor(max + 1))
    : new Date().getTime();
}
