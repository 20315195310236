import React, {useEffect, useState} from 'react';
import baseStyles from '../../styles/Base.module.css';
import {RouteComponentProps, useLocation, withRouter} from 'react-router';
import TopBar from "../../common/components/TopBar";
import ActionButton from "../../common/components/ActionButton";
import PageHeader, {PageHeaderProps} from "../../common/components/page/PageHeader";
import PageFooter from "../../common/components/page/PageFooter";
import PageContent from "../../common/components/page/PageContent";
import styles from '../../styles/components/pages/DefaultPage.module.css';
import {useErrorContext, useErrorUpdateContext} from "../../contexts/ErrorContext";
import ModalError from "../../common/components/ModalError";
import {useUserContext} from "../../contexts/UserContext";
import ImgLogo from "../../common/components/logo/ImgLogo";
import LogoSPO from '../../assets/images/spo_logo.svg';
import {ReactComponent as ArrowUpWhiteIcon} from '../../common/assets/icons/arrow_up_white.svg';
import {ReactComponent as ArrowUpGreyIcon} from '../../common/assets/icons/arrow_up_grey.svg';
import {Events, scroller} from 'react-scroll/modules';
import {anchorListener} from "../../helpers/router";
import {isNotEmpty} from "../../common/utils/common";
import {useUISettingsContext, useUISettingsUpdateContext} from "../../contexts/UISettingsContext";
import {usePageHistoryContext} from "../../contexts/PageHistoryContext";
import RedirectButton from "../../common/components/RedirectButton";
import ScrollToTopButton from "../../common/components/ScrollToTopButton";
import {SectionPopupActionType, useSectionPopupDispatchContext} from "../../contexts/SectionPopupContext";
import VisibilitySensor from 'react-visibility-sensor';
import {action, SVGComponent} from "../../common/utils/types";
import {getWindowSearchParam, redirectWindow, redirectWindowToAuth, redirectWindowToLogout} from "../../helpers/window";
import {ErrorType} from "../../model/error";
import {getById} from "../../utils/dom";
import config from "../../config";

export interface DefaultPageProps {
  name: string
  header?: PageHeaderProps
  scrollToTop?: boolean
  wideContent?: boolean
}

const DefaultPage: React.FC<DefaultPageProps & RouteComponentProps> = ({
  name,
  header,
  scrollToTop = false,
  wideContent = false,
  children,
  history
}) => {
  const { pathname, search, hash } = useLocation();

  const uiSettings = useUISettingsContext();
  const updateUISettings = useUISettingsUpdateContext();
  const sectionPopupsDispatch = useSectionPopupDispatchContext();
  const pageHistory = usePageHistoryContext();
  const user = useUserContext();
  const error = useErrorContext();
  const updateError = useErrorUpdateContext();

  const [ScrollToTopIcon, setScrollToTopIcon] = useState<SVGComponent | undefined>();

  const currentPage = {name, path: pathname + search + (isNotEmpty(hash) ? hash : '')};

  useEffect(() => {
    Events.scrollEvent.register('end', to => {
      if (to && history.location.hash !== ('#' + to)) {
        history.replace(
          history.location.pathname + history.location.search + '#' + to
        );
      }
    });

    const unregisterAnchorListener = anchorListener(name => {
      if (isNotEmpty(name)) {
        const element = scroller.get(name);
        if (element) {
          scroller.scrollTo(name, {
            duration: 400,
            delay: 0,
            offset: 0,
            smooth: 'easeInOutQuart',
          });
        }
      }
    });

    return () => {
      unregisterAnchorListener();
      Events.scrollEvent.remove('end');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    // push previous page into history stack
    if(!uiSettings.redirectedBack && uiSettings.currentPage) {
      pageHistory.push(uiSettings.currentPage);
    }
    //console.log('history stack:');
    //_.map(pageHistory.pages, (page, idx) => console.log(`${idx + 1}. "${page.name}" : ${page.path}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateUISettings({...uiSettings, currentPage, redirectedBack: undefined});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search, hash]);

  // TODO: move to separate context-component (e.g. PostLoginHandler)
  const status = getWindowSearchParam('status');
  if(status) {
    if(status === 'ok') {
      sectionPopupsDispatch(action(SectionPopupActionType.PopupShowed, false));
      redirectWindow(window.location.pathname + window.location.hash, ['status']);
    } else {
      redirectWindow(config.publicUrl  + '/#/notAuth', ['status']);
    }
    return <></>;
  }

  return (
    <div id="page" className={baseStyles.pageContainer}>
      {error && error.type !== ErrorType.AuthApi && (
        <ModalError
          error={error}
          onClose={() => updateError(null)}
        />
      )}

      <VisibilitySensor
        partialVisibility={true}
        onChange={!scrollToTop ? undefined : (isVisible: boolean) => setScrollToTopIcon(isVisible ? undefined : ArrowUpGreyIcon)}
      >
        <div id="header" className={baseStyles.headerContainer}>
          <TopBar
            logo={
              <ImgLogo source={LogoSPO} alt="SPOPTIMIZER" onClick={() =>
                history.push('/home')
              }/>
            }
            shadowed={false}
            leftItems={
              user ? [
                <RedirectButton
                  key="1"
                  text="Support"
                  type="link"
                  to="/support"
                />
              ] : []}
            rightItems={
              user ? [
                <div className={styles.welcome}>Welcome back, {user.givenName}</div>,
                <ActionButton
                  key="2"
                  text="Log-Out"
                  type="secondary"
                  onClick={redirectWindowToLogout}
                />
              ] : [
                <ActionButton
                  key="2"
                  text="Log-In"
                  type="secondary"
                  onClick={redirectWindowToAuth}
                />
              ]
            }
          />
          { header && <PageHeader {...header} /> }
        </div>
      </VisibilitySensor>

      <div
        id="content"
        className={
          wideContent ? baseStyles.wideContentContainer : baseStyles.contentContainer
        }
      >
        <PageContent>{children}</PageContent>
      </div>

      <VisibilitySensor
        partialVisibility={true}
        offset={{
          bottom: 50
        }}
        onChange={!scrollToTop ? undefined : (isVisible: boolean) => {
          const scrollTopY = document.documentElement.scrollTop || document.body.scrollTop;
          const headerHeight = getById('header')?.offsetHeight || 0;
          if(scrollTopY > headerHeight) {
            setScrollToTopIcon(isVisible ? ArrowUpWhiteIcon : ArrowUpGreyIcon);
          } else {
            setScrollToTopIcon(undefined);
          }
        }}
      >
        <div id="footer" className={baseStyles.footerContainer}>
          <PageFooter />
        </div>
      </VisibilitySensor>

      <ScrollToTopButton
        show={!!ScrollToTopIcon}
        icon={ScrollToTopIcon}
        className={styles.scrollToTop}
      />
    </div>
  );
};

export default withRouter(DefaultPage);
