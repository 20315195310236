import React from 'react';
import styles from '../styles/components/CommaList.module.css';
import _ from 'lodash';

export interface RowListProps {
  items: React.ReactNode[]
}

const CommaList: React.FC<RowListProps> = ({ items }) => {
  return (
    <div className={styles.container}>
      {_.map(items, (item, index) =>
        <span key={index} className={styles.commaWrapper}>
          <span className={styles.sectionItem}>{item}</span>
        </span>
      )}
    </div>
  )
};

export default CommaList;
