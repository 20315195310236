import React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import DefaultPage from "../DefaultPage";
import styles from "../../../styles/components/pages/error/NotFoundPage.module.css";

const NotFoundPage: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <DefaultPage
      name='Not Found'
    >
      <div className={styles.container}>
        <span className={styles.title}>Not found</span>
        <span className={styles.description}>
          You navigated to a wrong URL.
        </span>
      </div>
    </DefaultPage>
  )
};

export default withRouter(NotFoundPage);
