import * as React from "react";

/**
 * Section
 */
export type Section<T> = SinglePlainSection<T> | SingleSection<T> | MultipleSection<T>

export type SinglePlainSection<T> = {
  [P in keyof T]: T[P];
}
export type SingleSection<T> = SectionItem<T>
export type MultipleSection<T> = SectionItem<T>[]

export type SectionItem<T> = PartialNullable<{
  content: T
  attributes: {
    isMatch: 'Match' | 'NonMatch' | 'NA'
  }
}>

/**
 * Image
 */
export type SVGComponent = React.FC<React.SVGProps<SVGSVGElement>>

/**
 * State Action
 */
export type PayloadAction<T, P> = {
  type: T
  payload: P
};
export const action: <T, P>(type: T, payload: P) => PayloadAction<T, P> = (type, payload) => ({
  type, payload
});

/**
 * Common
 */
export type PartialNullable<T> = {
  [P in keyof T]?: T[P] | null
};
export type Cancelable = {
  cancel(): void
}

/**
 * Style
 */
export type Padding = {
  top?: number
  left?: number
  right?: number
  bottom?: number
}