import React, {createContext, Dispatch, useReducer} from 'react';
import { getContextFactory } from './ContextFactory';
import {Store} from "../model/store";
import {Categories} from "../model/category";
import {Service} from "../model/service";
import {PayerParticipation} from "../model/payer";
import {BiopharmaParticipation} from "../model/biopharma";
import {PayloadAction} from "../common/utils/types";

export enum ActionType {
  InitCategories, InitServices, InitPayerParticipation, InitBiopharmaParticipation
}

export type Action<P> = PayloadAction<ActionType, P>

export type CategoryAction = Action<Categories>
export type ServicesAction = Action<Service[]>
export type PayerParticipationAction = Action<PayerParticipation[]>
export type BiopharmaParticipationAction = Action<BiopharmaParticipation[]>

type StoreAction = CategoryAction | ServicesAction | PayerParticipationAction | BiopharmaParticipationAction

const reducer = (state: Store, action: StoreAction): Store => {
  const { categories, services, payerParticipation, biopharmaParticipation } = state;
  // TODO: how to get rid of 'as'?
  switch (action.type) {
    case ActionType.InitCategories: {
      return categories.loaded ? state : {
        ...state, categories: {data: action.payload as Categories, loaded: true}
      }
    }
    case ActionType.InitServices: {
      return services.loaded ? state : {
        ...state, services: {data: action.payload as Service[], loaded: true}
      }
    }
    case ActionType.InitPayerParticipation: {
      return payerParticipation.loaded ? state : {
        ...state, payerParticipation: {data: action.payload as PayerParticipation[], loaded: true}
      }
    }
    case ActionType.InitBiopharmaParticipation: {
      return biopharmaParticipation.loaded ? state : {
        ...state, biopharmaParticipation: {data: action.payload as BiopharmaParticipation[], loaded: true}
      }
    }
    default: {
      throw new Error('Unknown Store Action type');
    }
  }
};

const initialStore: Store = {
  categories: { data: {}, loaded: false },
  services: { data: [], loaded: false },
  payerParticipation: { data: [], loaded: false },
  biopharmaParticipation: { data: [], loaded: false },
};

const StoreContext = createContext<Store>(initialStore);
const StoreDispatchContext = createContext<Dispatch<StoreAction>>(
  () => undefined
);

export const useStoreContext = getContextFactory(StoreContext);
export const useStoreDispatchContext = getContextFactory(StoreDispatchContext);

const StoreContextProvider: React.FC = props => {
  const [store, dispatch] = useReducer(reducer, initialStore);
  return (
    <StoreContext.Provider value={store}>
      <StoreDispatchContext.Provider value={dispatch}>
        {props.children}
      </StoreDispatchContext.Provider>
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
