import React, { createContext, useState } from 'react';
import { getContextFactory } from './ContextFactory';
import PageHistory, {Page} from "../model/history";

// TODO: remove the default initialization
const defaultPage: Page = {name: 'Search', path: '/home'};

const PageHistoryContext = createContext<PageHistory>(new PageHistory(defaultPage));

export const usePageHistoryContext = getContextFactory(PageHistoryContext);

export interface PageHistoryContextProviderProps {
  defaultPage: Page
}

const PageHistoryContextProvider: React.FC<PageHistoryContextProviderProps> = ({ defaultPage, children }) => {
  const [pageHistory] = useState<PageHistory>(new PageHistory(defaultPage));
  return (
    <PageHistoryContext.Provider value={pageHistory}>
      {children}
    </PageHistoryContext.Provider>
  );
};

export default PageHistoryContextProvider;
