import React, {useEffect, useState} from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import formStyles from '../../common/styles/components/form/Forms.module.css';
import pageStyles from '../../styles/components/pages/CustomAnalysisPage.module.css';
import DefaultPage from './DefaultPage';
import TextField from '../../common/components/form/TextField';
import TextArea from '../../common/components/form/TextArea';
import {useUserContext} from '../../contexts/UserContext';
import ActionButton from "../../common/components/ActionButton";
import Box from "../../common/components/Box";
import Loading from "../../common/components/Loading";
import {useErrorUpdateContext} from "../../contexts/ErrorContext";
import {ErrorType} from "../../model/error";
import {PharmacyId, PharmacySnapshot} from "../../model/pharmacy";
import {CustomAnalysisRouterParams} from "../../router/params/CustomAnalysisRouterParams";
import {isNotEmpty} from "../../common/utils/common";
import {parseNumber} from "../../common/utils/number";
import api from "../../rest/api";
import _ from 'lodash';

interface CustomAnalysisFormValues {
  firstName: string
  lastName: string
  email: string
  txt: string
}

const customAnalysisSchema = () => Yup.object<CustomAnalysisFormValues>().shape({
  firstName: Yup.string()
    .trim()
    .min(1, 'Too short')
    .max(50, 'Too long')
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .min(1, 'Too short')
    .max(50, 'Too long')
    .required('Last name is required'),
  email: Yup.string()
    .trim()
    .email('Must be a valid email')
    .required('Email is required'),
  txt: Yup.string()
    .trim()
    .min(10, 'Please provide more detailed description')
    .required('Description is required'),
});

export interface CustomAnalysisFromProps {
  pharmacyId: PharmacyId
  firstName?: string
  lastName?: string
  email?: string
  onComplete?: () => void
}

export const CustomAnalysisForm: React.FC<CustomAnalysisFromProps> = ({
  pharmacyId,
  firstName,
  lastName,
  email,
  onComplete
}) => {
  const updateError = useErrorUpdateContext();

  const [pharmacySnapshot, setPharmacySnapshot] = useState<PharmacySnapshot | null>(null);

  const [pharmacySnapshotLoading, setPharmacySnapshotLoading] = useState(true);

  const initialValues: CustomAnalysisFormValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    txt: '',
  };

  const { basicInfo } = pharmacySnapshot || {};

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        if(pharmacyId) {
          const data = await api.findPharmacyProfile({ id: pharmacyId });
          if(!ignore) {
            setPharmacySnapshot(data);
            setPharmacySnapshotLoading(false);
          }
        }
      }
      catch(error) {
        if(!ignore) {
          setPharmacySnapshotLoading(false);
          updateError(error);
        }
      }
    }
    fetchData();
    return () => {
      ignore = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={customAnalysisSchema()}
      onSubmit={(values, { setSubmitting }) => {
        async function sendData() {
          try {
            setSubmitting(true);
            await api.requestCustomAnalysis({
              pharmacyId: pharmacyId,
              firstName: _.trim(values.firstName),
              lastName: _.trim(values.lastName),
              email: _.trim(values.email),
              txt: _.trim(values.txt),
            });
            setSubmitting(false);
            onComplete && onComplete();
          }
          catch(error) {
            setSubmitting(false);
            updateError({
              ...error,
              text: error.type === ErrorType.Network ? 'Network Issue. Please try again.' : error.text
            });
          }
        }
        sendData();
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className={pageStyles.form}>
          <span className={pageStyles.title}>
            IDN-Owned SP Custom Analysis
          </span>
          <span className={pageStyles.description}>
            Fill out the information below to receive a custom analysis for the following pharmacy.
          </span>
          <form onSubmit={handleSubmit} className={formStyles.form}>
            <div className={formStyles.formItems}>
              {pharmacySnapshotLoading ? <Loading size={10} paddingVertical={8} /> : pharmacySnapshot && (
                <div className={`${pageStyles.disabledField} ${formStyles.formItemFullWidth}`}>
                  <span className={pageStyles.disabledFieldLabel}>Specialty Pharmacy</span>
                  <span className={pageStyles.disabledFieldValue}>{isNotEmpty(basicInfo?.name) ? basicInfo?.name : 'Other'}</span>
                </div>
              )}
              <TextField
                name="firstName"
                requiredValue={true}
                placeholderText="First Name"
                wrapperClassName={formStyles.formItemFullWidth}
              />
              <TextField
                name="lastName"
                requiredValue={true}
                placeholderText="Last Name"
                wrapperClassName={formStyles.formItemFullWidth}
              />
              <TextField
                name="email"
                requiredValue={true}
                placeholderText="Company Email"
                wrapperClassName={formStyles.formItemFullWidth}
              />
              <TextArea
                name="txt"
                requiredValue={true}
                placeholderText="Description of Your Analysis Needs"
                wrapperClassName={`${formStyles.formItemFullWidth} ${pageStyles.txt}`}
                rows={4}
              />
            </div>
            <div className={formStyles.formButtons}>
              <ActionButton
                onClick={() => !isSubmitting && handleSubmit()}
                disabled={!pharmacySnapshot}
                loading={isSubmitting}
                type="primaryAlt"
                text="Submit"
              />
            </div>
            <div className={pageStyles.formNote}>
              <span className={pageStyles.required}>*</span>&nbsp;Required
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

type CustomAnalysisPageProps = RouteComponentProps<CustomAnalysisRouterParams>;

const CustomAnalysisPage: React.FC<CustomAnalysisPageProps> = ({ history, match }) => {

  const user = useUserContext();

  const [complete, setComplete] = useState(false);

  const pharmacyId = parseNumber(match.params.pharmacyId);

  if(!pharmacyId) {
    return <Redirect to="/notFound" />
  }

  return (
    <DefaultPage
      name='Form'
      header={{
        title: 'Custom Analysis',
        backAction: true
      }}
      wideContent={true}
    >
        <div className={pageStyles.containerWrapper}>
          <div className={pageStyles.container}>
            <Box>
              <div className={pageStyles.content}>
                {complete ? (
                  <p className={pageStyles.completeText}>
                    Thank you for requesting a custom analysis!
                  </p>
                ) : (
                  <CustomAnalysisForm
                    pharmacyId={pharmacyId}
                    firstName={(user?.givenName) || ''}
                    onComplete={() => setComplete(true)}
                  />
                )}
              </div>
            </Box>
          </div>
        </div>
    </DefaultPage>
  );
};

export default withRouter(CustomAnalysisPage);
