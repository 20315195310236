import _ from 'lodash';

/**
 * Page History
 */
export default class PageHistory {

  readonly pages: Page[];
  readonly defaultPage: Page;

  public constructor(defaultPage: Page) {
    this.pages = [];
    this.defaultPage = defaultPage;
  }

  peek(): Page | undefined {
    return _.head(this.pages);
  }

  peekOrDefault(): Page {
    return this.peek() || this.defaultPage;
  }

  push(page: Page) {
    const headPage = this.peek();
    if(headPage && headPage.name === page.name) {
      this.pop();
    }
    this.pages.unshift(page);
  }

  pop(): Page | undefined {
    return this.pages.shift();
  }

  popOrDefault(): Page {
    return this.pop() || this.defaultPage;
  }

  clear() {
    this.pages.splice(0, this.pages.length);
  }
}

/**
 * Page
 */
export interface Page {
  name: string,
  path: string
}