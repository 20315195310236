import React from 'react';
import styles from "../../styles/components/table/Table.module.css";
import {isNotEmpty} from "../../utils/common";
import ContentElement, {HighlightedItem, ElementItem, StringItem} from "../Content";
import _ from 'lodash';

// TODO: refactor 'label' picking
export type TableItem =
  StringItem |
  HighlightedItem |
  ElementItem |
  Pick<StringItem | HighlightedItem, 'label'> |
  null |
  undefined

export interface TableLimit {
  rows: number
  label: string
  className?: string
}

export interface TableProps {
  columns?: number
  headers?: string[]
  bulleted?: boolean
  items: (TableItem | TableItem[])[]
  limit?: TableLimit,
  className?: string
}
export type ListTableProps = Omit<TableProps, 'columns'>
export type BulletedTableProps = Omit<TableProps, 'bulleted'>
export type BulletedListTableProps = Omit<BulletedTableProps, 'columns'>

export const Table: React.FC<TableProps> = ({
  columns,
  headers = [],
  bulleted = false,
  items = [],
  limit,
  className
}) => {
  const columnNumber = columns || headers.length || 1;
  const rows = _.chunk(items, columnNumber);
  return (
    <div className={styles.container}>
      <table className={`${styles.table} ${className || ''}`}>
        <colgroup>
          {
            _.chain(columnNumber)
              .range()
              .map(number => (
                <col
                  key={number}
                  style={{
                    width: `calc(100% / ${columnNumber})`
                  }}
                />
              ))
              .value()
          }
        </colgroup>
        {isNotEmpty(headers) && (
          <thead>
            <tr className={styles.tr}>
              {_.map(headers, (header, index) => (
                <th key={index}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
        {
          _.chain(rows)
            .take(limit ? limit.rows : items.length)
            .map((rowItems, index) => (
              <tr key={index} className={styles.tr}>
                {_.map(rowItems, (item, index) => (
                  <td key={index} className={styles.td}>
                    <div className={styles.item}>
                      {bulleted && (
                        <span className={styles.icon}>&#8226;</span>
                      )}
                      {item && (
                        _.isArray(item) ? (
                          <div className={styles.multipleContent}>
                            {_.map(item, (i, idx) => <ContentElement key={idx} {...i} />)}
                          </div>
                        ) : (
                          <div className={styles.content}>
                            <ContentElement {...item} />
                          </div>
                        )
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))
            .value()
        }
        </tbody>
      </table>
      {limit && (rows.length > limit.rows) && (
        <div className={`${styles.limit} ${limit.className || ''}`}>
          {limit.label}
        </div>
      )}
    </div>
  )
};

export const ListTable: React.FC<ListTableProps> = (props) => (
  <Table
    columns={1}
    {...props}
  />
);

export const BulletedTable: React.FC<BulletedTableProps> = (props) => (
  <Table
    bulleted={true}
    {...props}
  />
);

export const BulletedListTable: React.FC<BulletedListTableProps> = (props) => (
  <BulletedTable
    columns={1}
    {...props}
  />
);