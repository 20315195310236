import React from "react";
import styles from "../../../styles/components/pharmacy/autocompletion/AutocompletionTextField.module.css";

export interface AutocompletionTextFieldProps {
  placeholderText?: string;
  onInput: (input: string) => void,
  onKeyDown?: (event: React.KeyboardEvent) => void
}

export const AutocompletionTextField: React.FC<AutocompletionTextFieldProps> = ({
  placeholderText,
  onInput,
  onKeyDown
}) => {
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInput(event.target.value)
  }
  return (
    <div className={styles.container}>
      <input 
      className={styles.input}
      onKeyDown={onKeyDown}
      type="text"
      placeholder={placeholderText}
      onChange={handleChange}
      autoFocus={true}
       />

    </div>
  );
};
