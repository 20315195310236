import { useContext, Context } from 'react';

export const getContextFactory = <T>(context: Context<T>): (() => T) => {
  return () => {
    return useContext(context);
  };
};

export const getDefinedContextFactory = <T>(
  name: string,
  context: Context<T | undefined>
): (() => T) => {
  return () => {
    const ctx = useContext(context);
    if (ctx === undefined) {
      throw new Error(
        `getDefinedContextFactory for ${name} context must be used withing a ContextProvider that provide well-defined context.`
      );
    }
    return ctx;
  };
};
