import React, { createContext } from 'react';
import { getContextFactory } from './ContextFactory';
import {
  defaultPharmacyListPaginationParams as defaultPaginationParams,
  pharmacyFilterSelectedItemsLimit as selectedItemsLimit,
  OnlyFavoritesParams,
  OwnershipTypeParams,
  PharmacyListFilterParams
} from "../model/pharmacy";
import useParams from "../common/hooks/useParams";
import {isNotEmpty} from "../common/utils/common";
import _ from 'lodash';

const PharmacyListFilterContext = createContext<PharmacyListFilterParams>(defaultPaginationParams);
const PharmacyListFilterUpdateContext = createContext<(filter: PharmacyListFilterParams) => void>(
  () => undefined
);

export const usePharmacyListFilterContext = getContextFactory(PharmacyListFilterContext);
export const usePharmacyListFilterUpdateContext = getContextFactory(PharmacyListFilterUpdateContext);

const PharmacyListFilterContextProvider: React.FC = props => {

  const { Independent, IDN, All } = OwnershipTypeParams;
  const { Yes } = OnlyFavoritesParams;

  const [listFilter, setListFilter] = useParams<PharmacyListFilterParams>((params) => {
    const handledParams =
      _.mapValues(params, (value, key) => {
        switch (key) {
          case 'ta':
          case 's':
          case 'pnp':
          case 'bnp':
            return _.take(_.isArray(value) ? value : (value || value === 0 ? [value] : []), selectedItemsLimit);
          case 'ot':
          case 'f':
          case 'os':
          case 'li':
            return _.isNumber(value) ? value : undefined;
          default:
            return value;
        }
      });
    return {
      ...handledParams,
      ...{
        ot: _.includes([Independent, IDN, All], handledParams.ot) ? handledParams.ot : All,
        f: handledParams.f === Yes ? handledParams.f : undefined,
        os: isNotEmpty(handledParams.os) ? handledParams.os : defaultPaginationParams.os,
        li: isNotEmpty(handledParams.li) ? handledParams.li : defaultPaginationParams.li
      }
    }
  });

  return (
    <PharmacyListFilterContext.Provider value={listFilter}>
      <PharmacyListFilterUpdateContext.Provider value={setListFilter}>
        {props.children}
      </PharmacyListFilterUpdateContext.Provider>
    </PharmacyListFilterContext.Provider>
  );
};

export default PharmacyListFilterContextProvider;
