import _ from 'lodash';

export const isClient = typeof window === 'object';

export type FnReturningPromise = (...args: any[]) => Promise<any>;

export type FnEmpty = () => void;

export type PromiseType<P extends Promise<any>> = P extends Promise<infer T> ? T : never;

export const isEmpty = <T>(value: T): boolean =>
  value === undefined ||
  value === null ||
  (_.isString(value) && _.trim(value) === '') ||
  ((_.isArray(value) || _.isObject(value)) && _.isEmpty(value));

export const isNotEmpty = <T>(value: T): boolean =>
  !isEmpty(value);

export const reallyNotEmpty = <T>(value: T | null | undefined): value is T => {
  return isNotEmpty(value)
}  

export const isEven = (value: number): boolean =>
  value % 2 === 0;

export const isOdd = (value: number): boolean =>
  !isEven(value);

export const updateIfNull = <T>(oldValue: T | null, newValue: T) => {
  return oldValue === null ? newValue : oldValue
};  

export const nullable = <T>(a: T | null) => a as T | null;
