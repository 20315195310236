import React from 'react';
import styles from '../styles/components/Loading.module.css';
import {BeatLoader} from "react-spinners";

export interface LoadingProps {
  theme?: 'blue' | 'white'
  size?: number
  margin?: number
  paddingVertical?: number
  paddingHorizontal?: number
  fullWidth?: boolean
  fullHeight?: boolean
}

const Loading: React.FC<LoadingProps> = ({
  theme = 'blue',
  size = 15,
  margin = 5,
  paddingVertical = 24,
  paddingHorizontal = 24,
  fullWidth = false,
  fullHeight = true
}) => {
  return (
    <div
      style={{ padding: `${paddingVertical}px ${paddingHorizontal}px` }}
      className={`
        ${styles.container} 
        ${fullWidth ? styles.fullWidth : ''} 
        ${fullHeight ? styles.fullHeight : ''}
      `}
    >
      <div className={styles.spinner}>
        <BeatLoader color={theme === 'blue' ? '#002F6C' : '#FFFFFF'} size={size} margin={margin} />
      </div>
    </div>
  )
};

export default Loading;
