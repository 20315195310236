import useLocalStorage from "../common/hooks/useLocalStorage";
import {PharmacySectionName as SectionName} from "../model/pharmacy";
import {Dispatch, SetStateAction, useEffect} from "react";
import {PartialNullable} from "../common/utils/types";
import _ from 'lodash';

const { Insights, AccountSummary, BusinessPerformance, Services, LimitedDistributionNetwork } = SectionName;

export const defaultSectionPopups: SectionPopups = {
  popups:
    _.chain([Insights, AccountSummary, BusinessPerformance, Services, LimitedDistributionNetwork])
      .map(name => ([name, false]))
      .fromPairs()
      .value(),
  popupShowed: false
};

export type SectionPopups = PartialNullable<{
  popups: {
    [name: string]: boolean
  }
  popupShowed: boolean
}>

const useSectionPopups = (): [
  SectionPopups | undefined,
  Dispatch<SetStateAction<SectionPopups | undefined>>
] => {
  const [sectionPopups, setSectionPopups] = useLocalStorage<SectionPopups>('sectionPopups', defaultSectionPopups);

  useEffect(() => {
    if(_.every(sectionPopups?.popups, popup => popup)) {
      setSectionPopups({...sectionPopups, popups: defaultSectionPopups.popups});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionPopups?.popups]);

  return [sectionPopups, setSectionPopups];
};

export default useSectionPopups;