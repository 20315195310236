import React from 'react';
import {
  BulletedTable, BulletedTableProps,
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import _ from 'lodash';

export type BiopharmaNetworkListProps = Pick<BulletedTableProps, 'columns' | 'limit' | 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const BiopharmaNetworkList: React.FC<BiopharmaNetworkListProps> = ({ pharmacyProfile, ...props }) => {
  const { biopharmaNetwork } = pharmacyProfile;
  return biopharmaNetwork && isNotEmpty(biopharmaNetwork) ? (
    <BulletedTable
      items={
        _.map(biopharmaNetwork, network => ({content: network.content?.name || '', attributes: network.attributes}))
      }
      {...props}
    />
  ) : <></>
};

export default BiopharmaNetworkList;