import React from 'react';
import ActionButton, {ActionButtonProps} from "../common/components/ActionButton";
import {useUISettingsContext, useUISettingsUpdateContext} from "../contexts/UISettingsContext";
import {usePageHistoryContext} from "../contexts/PageHistoryContext";
import {redirect} from "../helpers/router";

export type HistoryBackButtonProps = Omit<ActionButtonProps, 'text' | 'onClick'> & {
  render?: (text: string) => string | undefined
}

const HistoryBackButton: React.FC<HistoryBackButtonProps> = ({ render, ...props }) => {

  const uiSettings = useUISettingsContext();
  const updateUISettings = useUISettingsUpdateContext();
  const pageHistory = usePageHistoryContext();

  const previousPage = pageHistory.peekOrDefault();
  return (
    <ActionButton
      text={render ? render(previousPage.name) : undefined}
      onClick={() => {
        pageHistory.pop();
        updateUISettings({...uiSettings, redirectedBack: true});
        redirect(previousPage.path);
      }}
      {...props}
    />
  )
};

export default HistoryBackButton;