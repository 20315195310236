import React from 'react';
import {
  Table, TableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import _ from 'lodash';

export type BusinessPerformanceTableProps = Pick<TableProps, 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const BusinessPerformanceTable: React.FC<BusinessPerformanceTableProps> = ({ pharmacyProfile, ...props }) => {
  const { businessPerformance } = pharmacyProfile;
  return businessPerformance && isNotEmpty(businessPerformance) ? (
    <Table
      headers={['Financial Year', 'Description']}
      items={
        _.chain(businessPerformance)
          .orderBy(performance => performance.content?.financialYear || '', 'desc')
          .flatMap(performance => (_.map(performance.content, (value, key) => ({content: value, attributes: performance.attributes}))))
          .value()
      }
      {...props}
    />
  ) : <></>
};

export default BusinessPerformanceTable;