import React from 'react';
import {
  BulletedListTable, BulletedListTableProps
} from "../../../common/components/table/Table";
import {PharmacyProfile} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import _ from 'lodash';

export type ServiceListProps = Pick<BulletedListTableProps, 'limit' | 'className'> & {
  pharmacyProfile: PharmacyProfile
}

const ServiceList: React.FC<ServiceListProps> = ({ pharmacyProfile, ...props }) => {
  const { services } = pharmacyProfile;
  return services && isNotEmpty(services) ? (
    <BulletedListTable
      items={
        _.map(services, service => ({content: service.content?.name || '', attributes: service.attributes}))
      }
      {...props}
    />
  ) : <></>
};

export default ServiceList;