import config from "../config";
import {isNotEmpty} from "../common/utils/common";
import _ from 'lodash';

//this method store SearchParam during redirect
export const redirectWindow = (
  path: string,
  excludeParams?: string[]
) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  _.forEach(excludeParams, p => urlSearchParams.delete(p));
  window.location.href = path + (isNotEmpty(urlSearchParams.toString()) ? ('?' + urlSearchParams.toString()) : '');
};

export const getWindowSearchParam = (key: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(key);
};

export const redirectWindowToAuth = () => {
  window.location.href = config.apiUrl + "/api/v1/auth/authorize?redirect_url=" + encodeURIComponent(window.location.href);
};

export const redirectWindowToLogout = () => {
  window.location.href = config.apiUrl + "/api/v1/auth/logout?redirect_url=" + config.publicUrl + "/";
};