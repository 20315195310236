import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SearchRouterParams } from "../../router/params/SearchRouterParams";
import DefaultPage from "./DefaultPage";
import styles from "../../styles/components/pages/SearchPage.module.css";
import { HomeTabs } from "../pharmacy/tabs/HomeTabs";
import { AutoCompletion } from "../pharmacy/autocompletion/Autocompletion";

type SearchPageProps = RouteComponentProps<SearchRouterParams>;

export const SearchPage: React.FC<SearchPageProps> = ({ history, match }) => {
  return (
    <DefaultPage
      name="Search"
      header={{
        title: (
          <span className={styles.headerTitle}>
            My <span className={styles.headerTitleAbbreviation}>SP</span>
            OPTIMIZER
          </span>
        ),
        subtitle:
          "Find the right specialty pharmacy based on your business requirements.",
      }}
      wideContent={true}
    >
      <HomeTabs activeTab="search"></HomeTabs>
      <div className={styles.container}>
        <div className={styles.searchList}>
          <div className={styles.image}>
            <img
              src={require("../../assets/images/listing_search.png")}
              alt=""
            />
          </div>
        </div>
        <div className={styles.title}>
          Specialty Pharmacy Profile Listing Search
        </div>
        <div className={styles.description}>
          Already know the specialty pharmacy you're looking for? <br />
          Type its name below or look through the full listing of specialty
          pharmacies.
        </div>
        <AutoCompletion/>
      </div>
    </DefaultPage>
  );
};
