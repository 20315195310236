import { some, trim } from "lodash";
import {
  isEven,
  isNotEmpty,
  nullable,
  reallyNotEmpty,
  updateIfNull,
} from "../../common/utils/common";
import { PartialNullable } from "../../common/utils/types";
import {
  BasicInfo,
  OrganizationOverview,
  PharmacyProfile,
  PharmacySectionTocName,
} from "../../model/pharmacy";
import { findLastWordWithDot, in2pt, splitAcquisitionContent } from "../common";

export const exportPptx = async (
  profile: PharmacyProfile,
  name: string,
  date: string
): Promise<void> => {
  const PptxGenJS = await import("pptxgenjs");
  const pres = new PptxGenJS.default();

  const setupMetadata = () => {
    pres.author = "EVERSANA";
    pres.company = "EVERSANA";
    pres.revision = "1";
    pres.subject = "SP Profile";
    pres.title = name;
    pres.layout = "LAYOUT_WIDE";
  };

  setupMetadata();
  defineMasterSlides(pres, name, date);
  addTitleSlide(pres, name, date);

  const tocSlide = pres.addSlide({ masterName: "MASTER" });

  const tocRows: { name: string; page: number }[] = [];

  // Insights
  if (isNotEmpty(profile?.insights?.content)) {
    const pageNum = addInsightsSlides(pres, profile!.insights!.content!);
    if (pageNum != null) {
      tocRows.push({ name: PharmacySectionTocName.Insights, page: pageNum });
    }
  }
  // Account summary
  if (isNotEmpty(profile?.accountSummary?.content)) {
    const pageNum = addOneColumnListSlides(
      pres,
      "Account Summary and Analysis",
      profile!.accountSummary!.content!,
      18
    ); // title differs from PharmacySectionTocName.AccountSummary, hardcode is used
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.AccountSummary,
        page: pageNum,
      });
    }
  }
  // SWOT Analysis
  if (isNotEmpty(profile?.swotAnalysis)) {
    const strengths = trim(profile?.swotAnalysis?.strength?.content ?? "");
    const weaknesses = trim(profile?.swotAnalysis?.weakness?.content ?? "");
    const opportunities = trim(
      profile?.swotAnalysis?.opportunity?.content ?? ""
    );
    const threats = trim(profile?.swotAnalysis?.threat?.content ?? "");

    const pageNum = addSwotAnalysisSlides(pres, {
      strengths,
      weaknesses,
      opportunities,
      threats,
    });
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.SwotAnalysis,
        page: pageNum,
      });
    }
  }
  // Organization Overview
  const orgOverview = profile?.organizationOverview;
  const basicInfo = profile?.basicInfo;
  const pageNum = addOrganizationOverviewSlides(pres, orgOverview, basicInfo);
  if (pageNum != null) {
    tocRows.push({
      name: PharmacySectionTocName.Overview,
      page: pageNum,
    });
  }

  // Corporate Strategy
  if (isNotEmpty(profile?.corporateStrategy)) {
    const content = profile!.corporateStrategy!.map((x) => ({
      goal: (x?.content?.goal ?? "").replace(/\r?\n|\r/gm, " "),
      tactic: (x?.content?.tactic ?? "").replace(/\r?\n|\r/gm, " "),
    }));
    const pageNum = addCorporateStrategySlides(pres, content);
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.CorporateStrategy,
        page: pageNum,
      });
    }
  }
  // Business Performance
  if (isNotEmpty(profile?.businessPerformance)) {
    const content = profile!.businessPerformance!.map((x) => ({
      year: x?.content?.financialYear?.toString() ?? "",
      description: x?.content?.description ?? "",
    })); // .replace(/\r?\n|\r/g, ' ')
    const pageNum = addBusinessPerformanceSlide(pres, content);
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.BusinessPerformance,
        page: pageNum,
      });
    }
  }
  // TAs
  if (isNotEmpty(profile?.therapeuticAreas)) {
    const content = profile!.therapeuticAreas!.map((x) => ({
      name: x?.content?.name ?? "",
      treatmentCategory: x?.content?.treatmentCategory ?? "",
    }));
    const pageNum = addTherapeuticAreasSlides(pres, content);
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.TherapeuticAreas,
        page: pageNum,
      });
    }
  }
  // Services
  if (isNotEmpty(profile?.services)) {
    const content = profile!
      .services!.map((x) => x?.content?.name)
      .filter(reallyNotEmpty);
    const pageNum = addOneColumnListSlides(
      pres,
      PharmacySectionTocName.Services,
      content,
      20
    );
    if (pageNum != null) {
      tocRows.push({ name: PharmacySectionTocName.Services, page: pageNum });
    }
  }
  // Technology Offerings
  if (isNotEmpty(profile?.technologyOfferings)) {
    const content = profile!.technologyOfferings!.map((x) => ({
      name: x?.content?.name ?? "",
      description: x?.content?.description ?? "",
    }));
    const pageNum = addTechnologyOfferingsSlides(pres, content);
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.TechnologyOfferings,
        page: pageNum,
      });
    }
  }
  // PNP
  if (isNotEmpty(profile?.payerNetwork)) {
    const content = profile!
      .payerNetwork!.map((x) => x?.content?.name)
      .filter(reallyNotEmpty);
    const pageNum = addOneColumnListSlides(
      pres,
      PharmacySectionTocName.PayerNetwork,
      content,
      20
    );
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.PayerNetwork,
        page: pageNum,
      });
    }
  }
  // BNP
  if (isNotEmpty(profile?.biopharmaNetwork)) {
    const content = profile!
      .biopharmaNetwork!.map((x) => x?.content?.name)
      .filter(reallyNotEmpty);
    const pageNum = addOneColumnListSlides(
      pres,
      PharmacySectionTocName.BiopharmaNetwork,
      content,
      20
    );
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.BiopharmaNetwork,
        page: pageNum,
      });
    }
  }
  // LDN
  if (isNotEmpty(profile?.ldn)) {
    const content = profile!.ldn!.map((x) => ({
      brandName: x?.content?.brandName ?? "",
      genericName: x?.content?.genericName ?? "",
      manufacturer: x?.content?.manufacturer ?? "",
    }));
    const pageNum = addLimitedDistributionNetworkSlides(pres, content);
    if (pageNum != null) {
      tocRows.push({
        name: PharmacySectionTocName.LimitedDistributionNetwork,
        page: pageNum,
      });
    }
  }

  addEndingSlide(pres);

  console.log(tocRows);
  populateTocSlide(tocSlide, tocRows);
  pres.writeFile(`${name.trim()}.pptx`);
};

// commonly used colors
const COLOR1 = "eb8a23";
const COLOR2 = "565a5c";
const COLOR3 = "04306A";
const COLOR4 = "848787";
const COLOR5 = "ffffff";
const COLOR6 = "f2f2f2";
// ending slide colors
const COLOR7 = "74A7B8";
const COLOR8 = "0F7496";

const slideNums = {
  insights: nullable<number>(null),
  accountSummary: nullable<number>(null),
  swot: nullable<number>(null),
  overview: nullable<number>(null),
  corporateStrategy: nullable<number>(null),
  businessPerformance: nullable<number>(null),
  therapeuticAreas: nullable<number>(null),
  services: nullable<number>(null),
  technologyOfferings: nullable<number>(null),
  payerNetwork: nullable<number>(null),
  biopharmaNetwork: nullable<number>(null),
  ldn: nullable<number>(null),
};

const populateTocSlide = (
  tocSlide: PptxGenJS.default.Slide,
  tocRows: { name: string; page: number }[]
) => {
  const options: PptxGenJS.default.TableCellProps = {
    fontFace: "Arial",
    fontSize: 16,
    color: COLOR2,
  };
  const preparedRows = tocRows.map(({ name, page }, index) => {
    const bgColor = isEven(index) ? COLOR6 : COLOR5;
    const c1 = {
      text: name,
      options: { ...options, fill: { color: bgColor } },
    };
    const c2 = {
      text: page.toString(),
      options: {
        ...options,
        fill: { color: bgColor },
        align: "right",
      } as PptxGenJS.default.TableCellProps,
    };
    return [c1, c2];
  });
  tocSlide.addText("Table of Contents", {
    x: 0.67,
    y: 0.56,
    w: 12,
    h: 0.44,
    fontFace: "Arial",
    fontSize: 26,
    color: COLOR3,
    bold: true,
    valign: "bottom",
    margin: [in2pt(0.1), 0, 0, 0], // margin is LRBT
  });
  tocSlide.addTable(preparedRows, {
    x: 0.75,
    y: 1.17,
    h: 5.5,
    w: 12,
    rowH: 0.37,
    autoPage: false,
  });
};

const getObjectsForMasterSlideWithTitle = (
  name: string,
  title: string,
  date: string
): (
  | { image: {} }
  | { line: {} }
  | { rect: {} }
  | { text: PptxGenJS.default.TextProps }
)[] => {
  return [
    // title
    {
      text: {
        text: title,
        options: {
          x: 0.67,
          y: 0.97,
          w: 12,
          h: 0.44,
          fontFace: "Arial",
          fontSize: 26,
          color: COLOR3,
          bold: true,
        },
      },
    },
    {
      text: {
        text: name,
        options: {
          x: 0.67,
          y: 0.14,
          w: 12,
          h: 0.28,
          fontFace: "Arial",
          fontSize: 12,
          color: COLOR2,
          italic: true,
        },
      },
    },
    {
      text: {
        text: "Retrieved in " + date,
        options: {
          x: 10.5,
          y: 0.14,
          w: 2.7,
          h: 0.28,
          fontFace: "Arial",
          fontSize: 12,
          color: COLOR2,
          italic: true,
        },
      },
    },
    {
      text: {
        text: "© 2020 EVERSANA.  All Rights Reserved",
        options: {
          x: 1.2,
          y: 7.01,
          w: 3.15,
          h: 0.34,
          fontFace: "Arial",
          fontSize: 11,
          color: COLOR4,
          margin: [9.75, 9.75, 5.25, 5.25],
          valign: "top",
        },
      },
    },
    {
      image: {
        x: 10.85,
        y: 7.01,
        h: 0.32,
        w: 1.82,
        path: require("../../assets/images/eversana_logo_main.png"),
      },
    },
  ];
};
// needed for table auto-pagination to work as expected, unfortunately, placeholders are not enough here.
const defineMasterSlideWithTitle = (
  pres: PptxGenJS.default,
  name: string,
  title: string,
  handle: string,
  date: string
) => {
  pres.defineSlideMaster({
    title: handle,
    objects: getObjectsForMasterSlideWithTitle(name, title, date),
    slideNumber: {
      x: 0.7,
      y: 7.03,
      h: 0.34,
      w: 0.54,
      fontFace: "Arial",
      fontSize: 11,
      color: COLOR4,
    },
  });
};

const defineOrgOverviewSlide = (
  pres: PptxGenJS.default,
  name: string,
  title: string,
  subtitle: string,
  handle: string,
  date: string
) => {
  pres.defineSlideMaster({
    title: handle,
    objects: [
      ...getObjectsForMasterSlideWithTitle(name, title, date),
      {
        text: {
          text: subtitle,
          options: orgOverviewSubtitleOptions,
        },
      },
    ],
    slideNumber: {
      x: 0.7,
      y: 7.03,
      h: 0.34,
      w: 0.54,
      fontFace: "Arial",
      fontSize: 11,
      color: COLOR4,
    },
  });
};

const defineMasterSlides = (
  pres: PptxGenJS.default,
  name: string,
  date: string
) => {
  pres.defineSlideMaster({
    title: "MASTER",
    objects: [
      {
        text: {
          text: "© 2020 EVERSANA.  All Rights Reserved",
          options: {
            x: 1.2,
            y: 7.01,
            w: 3.15,
            h: 0.34,
            fontFace: "Arial",
            fontSize: 11,
            color: COLOR4,
            margin: [9.75, 9.75, 5.25, 5.25],
            valign: "top",
          },
        },
      },
      {
        image: {
          x: 10.85,
          y: 7.01,
          h: 0.32,
          w: 1.82,
          path: require("../../assets/images/eversana_logo_main.png"),
        },
      },
    ],
    slideNumber: {
      x: 0.7,
      y: 7.03,
      h: 0.34,
      w: 0.54,
      fontFace: "Arial",
      fontSize: 11,
      color: COLOR4,
    },
  });

  pres.defineSlideMaster({
    title: "MASTER_WITH_EYEBROW",
    objects: [
      /*       {
        placeholder: {
          options: {
            name: 'title',
            // @ts-ignore
            type: 'title',
            x: 0.67,
            y: 0.97,
            w: 12,
            h: 0.44
          },
          text: 'Placeholder'
        }
      }, */
      {
        text: {
          text: name,
          options: {
            x: 0.67,
            y: 0.14,
            w: 12,
            h: 0.28,
            fontFace: "Arial",
            fontSize: 12,
            color: COLOR2,
            italic: true,
          },
        },
      },
      {
        text: {
          text: "Retrieved in " + date,
          options: {
            x: 10.5,
            y: 0.14,
            w: 2.7,
            h: 0.28,
            fontFace: "Arial",
            fontSize: 12,
            color: COLOR2,
            italic: true,
          },
        },
      },
      {
        text: {
          text: "© 2020 EVERSANA.  All Rights Reserved",
          options: {
            x: 1.2,
            y: 7.01,
            w: 3.15,
            h: 0.34,
            fontFace: "Arial",
            fontSize: 11,
            color: COLOR4,
            margin: [9.75, 9.75, 5.25, 5.25],
            valign: "top",
          },
        },
      },
      {
        image: {
          x: 10.85,
          y: 7.01,
          h: 0.32,
          w: 1.82,
          path: require("../../assets/images/eversana_logo_main.png"),
        },
      },
    ],
    slideNumber: {
      x: 0.7,
      y: 7.03,
      h: 0.34,
      w: 0.54,
      fontFace: "Arial",
      fontSize: 11,
      color: COLOR4,
    },
  });

  pres.defineSlideMaster({
    title: "ENDING",
    background: { fill: COLOR8 },
    objects: [
      {
        text: {
          text: "© 2020 EVERSANA.  All Rights Reserved",
          options: {
            x: 1.2,
            y: 7.01,
            w: 3.13,
            h: 0.34,
            fontFace: "Arial",
            fontSize: 11,
            color: COLOR7,
            margin: [9.75, 9.75, 5.25, 5.25],
            valign: "top",
          },
        },
      },
      {
        image: {
          x: -0.79,
          y: 1.3,
          h: 4.3,
          w: 4.61,
          path: require("../../assets/images/eversana_ending_bg_left.png"),
        },
      },
      {
        image: {
          x: 7.66,
          y: 1.55,
          h: 5.81,
          w: 5.58,
          path: require("../../assets/images/eversana_ending_bg_right.png"),
        },
      },
      {
        image: {
          x: 10.83,
          y: 7.01,
          h: 0.3,
          w: 1.76,
          path: require("../../assets/images/eversana_logo_white.png"),
        },
      },
      {
        image: {
          x: 2.8,
          y: 3.08,
          h: 1.33,
          w: 7.74,
          path: require("../../assets/images/hsi_logo_white.png"),
        },
      },
    ],
    slideNumber: {
      x: 0.7,
      y: 7.03,
      h: 0.34,
      w: 0.54,
      fontFace: "Arial",
      fontSize: 11,
      color: COLOR7,
    },
  });

  defineMasterSlideWithTitle(
    pres,
    name,
    PharmacySectionTocName.SwotAnalysis,
    "SWOT_ANALYSIS_TABLE",
    date
  );
  defineMasterSlideWithTitle(
    pres,
    name,
    PharmacySectionTocName.TherapeuticAreas,
    "THERAPEUTIC_AREAS_TABLE",
    date
  );
  defineMasterSlideWithTitle(
    pres,
    name,
    PharmacySectionTocName.BusinessPerformance,
    "BUSINESS_PERFORMANCE_TABLE",
    date
  );
  defineMasterSlideWithTitle(
    pres,
    name,
    PharmacySectionTocName.TechnologyOfferings,
    "TECHNOLOGY_OFFERINGS_TABLE",
    date
  );
  defineMasterSlideWithTitle(
    pres,
    name,
    PharmacySectionTocName.LimitedDistributionNetwork,
    "LDN_TABLE",
    date
  );
  defineMasterSlideWithTitle(
    pres,
    name,
    PharmacySectionTocName.CorporateStrategy,
    "CORPORATE_STRATEGY_TABLE",
    date
  );
  defineMasterSlideWithTitle(
    pres,
    name,
    PharmacySectionTocName.Overview,
    "ORG_OVERVIEW",
    date
  );

  defineOrgOverviewSlide(
    pres,
    name,
    PharmacySectionTocName.Overview,
    "Market Coverage",
    "MARKET_COVERAGE",
    date
  );
  defineOrgOverviewSlide(
    pres,
    name,
    PharmacySectionTocName.Overview,
    "Organization",
    "ORG_DATA",
    date
  );
  defineOrgOverviewSlide(
    pres,
    name,
    PharmacySectionTocName.Overview,
    "Accreditation Type",
    "ORG_ACCREDITATION",
    date
  );
};

const addEndingSlide = (pres: PptxGenJS.default) => {
  const slide = pres.addSlide({ masterName: "ENDING" });
  slide.addText(
    [
      { text: "Office:", options: { bold: true } },
      {
        text:
          " 790 Township Line Road, Suite 300, Yardley, PA 19067  |  v: 609.397.5282  f: 609.397.5283",
        options: { breakLine: true },
      },
      { text: "EVERSANA.com", options: { lineSpacing: 20 } },
    ],
    {
      x: 3.92,
      y: 4.4,
      h: 0.77,
      w: 7,
      valign: "top",
      fontFace: "Arial",
      color: COLOR5,
      fontSize: 9,
    }
  );
  slide.addText(
    `This Report is licensed only to the original party licensed by Health Strategies Insights, (respectively the “Licensee” and “Health Strategies Insights”) and is subject to a binding license agreement between Licensee and Health Strategies Insights. Health Strategies Insights continues to retain title to and ownership of this Report.   All copies and portions of this Report, in any form, belong to Health Strategies Insights, which retains all rights not expressly granted. Licensee is entitled to use this Report solely for its own internal business purposes and is prohibited from modifying, translating, or otherwise creating derivative works based on this Report. Licensee is further prohibited from licensing, selling, leasing, distributing, lending or otherwise transferring this Report to any third party. Licensee may not make any copies of Health Strategies Insights' Reports except for internal distribution purposes as described and agreed to in the license agreement provided that all such copies are reproduced with and incorporate all of Health Strategies Insights' protective notices, including this and all copyright notices. Nothing in this Report and/or any license agreement applicable thereto constitute a waiver of Health Strategies Insights' rights under United States copyright law or any other law.  This information has been obtained from sources which Health Strategies Insights believes to be reliable but we do not guarantee the accuracy or completeness of this information.`,
    {
      x: 0.75,
      y: 5.33,
      h: 1.42,
      w: 11.92,
      fontFace: "Arial",
      color: COLOR5,
      fontSize: 8,
    }
  );
};

const addTitleSlide = (pres: PptxGenJS.default, name: string, date: string) => {
  const slide = pres.addSlide();

  // add logo
  slide.addImage({
    path: require("../../assets/images/eversana_logo_main.png"),
    x: 0.66,
    y: 1.94,
    h: 1.2,
    w: 6.91,
  });
  // add background image
  slide.addImage({
    path: require("../../assets/images/eversana_logo_large_br.png"),
    x: 8.61,
    y: 2.27,
    h: 5.23,
    w: 4.73,
  });
  // add title
  slide.addText(name, {
    x: 0.67,
    y: 3.76,
    h: 1.33,
    w: 7.35,
    fontFace: "Arial",
    fontSize: 26,
    color: COLOR3,
    valign: "bottom",
    margin: [in2pt(0.1), 0, 0, 0], // margin is LRBT
    bold: true,
  });
  // add subtitle 1
  slide.addText("Specialty Pharmacy Optimizer", {
    x: 0.67,
    y: 5.51,
    h: 0.4,
    w: 7.34,
    fontFace: "Arial",
    fontSize: 20,
    color: COLOR1,
    valign: "bottom",
    autoFit: true,
    margin: [in2pt(0.1), in2pt(0.1), in2pt(0.05), in2pt(0.05)], // margin is LRBT
  });
  // add subtitle 2
  slide.addText("Specialty Pharmacy Profile", {
    x: 0.67,
    y: 5.93,
    h: 0.4,
    w: 7.34,
    fontFace: "Arial",
    fontSize: 20,
    color: COLOR2,
    valign: "top",
    autoFit: true,
    margin: [in2pt(0.1), in2pt(0.1), in2pt(0.05), in2pt(0.05)], // margin is LRBT
  });
  // add date
  slide.addText("Retrieved in " + date, {
    x: 0.67,
    y: 6.59,
    h: 0.4,
    w: 7.34,
    fontFace: "Arial",
    fontSize: 16,
    color: COLOR2,
    valign: "top",
    autoFit: true,
    margin: [in2pt(0.1), in2pt(0.1), in2pt(0.05), in2pt(0.05)], // margin is LRBT
  });

  // add footer
  slide.addText("Health Strategies Insights by EVERSANA™", {
    x: 4.42,
    y: 7.04,
    h: 0.27,
    w: 4.52,
    fontFace: "Arial",
    fontSize: 16,
    color: COLOR3,
    valign: "top",
    autoFit: true,
    margin: [in2pt(0.1), 0, 0, 0], // margin is LRBT
  });
};

const addInsightsSlides = (
  pres: PptxGenJS.default,
  content: string
): number | null => {
  const words = content.split(" ");
  const maxSize = 150;
  let slideContentSize = words.length;
  let numSlides = 1;
  let globalSlideNum: number | null = null;
  while (slideContentSize > maxSize) {
    numSlides++;
    slideContentSize = Math.ceil(slideContentSize / numSlides);
  }

  for (let i = 0; i < words.length; i += slideContentSize) {
    const slideContent = words.slice(i, i + slideContentSize).join(" ");
    const currGlobalSlideNum = addInsightsSlide(pres, slideContent);
    globalSlideNum = updateIfNull(globalSlideNum, currGlobalSlideNum);
  }

  return globalSlideNum;
};

const addInsightsSlide = (pres: PptxGenJS.default, content: string): number => {
  const slide = pres.addSlide({ masterName: "MASTER" });

  // add blue background rectangle
  slide.addShape(pres.ShapeType.rect, {
    x: 0,
    y: 0,
    h: 6.75,
    w: 4.76,
    fill: { color: COLOR3 },
  });
  // add bottom line
  slide.addShape(pres.ShapeType.line, {
    x: 0,
    y: 6.75,
    h: 0.0,
    w: 13.33,
    rotate: 0,
    line: { color: COLOR3, width: 4 },
  });

  // add background image
  slide.addImage({
    path: require("../../assets/images/eversana_logo_large_bl.png"),
    x: 0,
    y: 2.64,
    h: 4.16,
    w: 3.41,
  });

  // add slide title
  slide.addText("EVERSANA's Insights", {
    x: 0.67,
    y: 1.44,
    h: 4,
    w: 3.51,
    fontFace: "Arial",
    fontSize: 26,
    color: COLOR5,
    margin: [in2pt(0.1), 0, 0, 0], // margin is LRBT
    bold: true,
    align: "center",
  });
  // add slide content
  slide.addText(content, {
    x: 5.34,
    y: 0.33,
    h: 6.04,
    w: 7.44,
    fontFace: "Arial",
    fontSize: 18,
    color: COLOR3,
    margin: [in2pt(0.1), in2pt(0.1), in2pt(0.05), in2pt(0.05)], // margin is LRBT
  });
  let num = 0;
  // @ts-ignore
  num = slide["_slideNum"];
  return num;
};

const addOneColumnListSlides = (
  pres: PptxGenJS.default,
  title: string,
  content: string[],
  fontSize: number
): number | null => {
  const countLinesInItem = (item: string) => {
    const charsInLine = 80;
    return Math.ceil(item.length / charsInLine);
  };

  const countLines = (content: string[]) => {
    return content.reduce((acc, x) => {
      return acc + countLinesInItem(x);
    }, 0);
  };

  const linesPerSlide = 10;

  // const linesInContent = countLines(content);
  // const isSingleSlide = linesInContent <= linesPerSlide;

  const addSlide = (
    slideContent: string[],
    title: string,
    slideNum: number
  ): number => {
    let t = title;
    /* if (!isSingleSlide) {
      t = title + ' (' + slideNum.toString() + ')'
    }; */
    return addOneColumnListSlide(pres, t, slideContent, fontSize);
  };

  let slideContent: string[] = [];
  let linesInCurrentSlide = 0;
  let slideNum = 0;
  let globalSlideNum: number | null = null;

  content.forEach((item) => {
    const lines = countLinesInItem(item);
    if (
      linesInCurrentSlide + lines > linesPerSlide &&
      isNotEmpty(slideContent)
    ) {
      const currGlobalSlideNum = addSlide(slideContent, title, slideNum + 1);
      globalSlideNum = updateIfNull(globalSlideNum, currGlobalSlideNum);
      slideContent = [];
      linesInCurrentSlide = 0;
      slideNum++;
    }
    linesInCurrentSlide += lines;
    slideContent.push(item);
  });
  // push whatever remains in buffer when we finished traversing the contents
  if (isNotEmpty(slideContent)) {
    const currGlobalSlideNum = addSlide(slideContent, title, slideNum + 1);
    globalSlideNum = updateIfNull(globalSlideNum, currGlobalSlideNum);
    slideNum++;
  }

  return globalSlideNum;
};

const addOneColumnListSlide = (
  pres: PptxGenJS.default,
  title: string,
  content: string[],
  fontSize: number
): number => {
  const slide = pres.addSlide({ masterName: "MASTER_WITH_EYEBROW" });
  let text = content.map((x) => {
    const t = {
      text: x,
      options: {
        bullet: true,
        color: COLOR2,
        fontFace: "Arial",
        fontSize: fontSize,
        lineSpacing: 35,
      },
    };
    return t;
  });
  addSlideTitle(slide, title);
  // add content
  slide.addText(text, { x: 0.67, y: 1.54, w: 12, h: 4.85, valign: "top" });
  let num = 0;
  // @ts-ignore
  num = slide["_slideNum"];
  return num;
};

const addTherapeuticAreasSlides = (
  pres: PptxGenJS.default,
  content: { name: string; treatmentCategory: string }[]
): number | null => {
  const slide = pres.addSlide({ masterName: "THERAPEUTIC_AREAS_TABLE" });
  const rows = content.map(({ name, treatmentCategory }, index) => {
    const bgColor = isEven(index) ? COLOR6 : COLOR5;
    const contentOptions: PptxGenJS.default.TableCellProps = {
      fontFace: "Arial",
      fill: { color: bgColor },
      color: COLOR2,
      fontSize: 20,
    };
    const cell1 = { text: treatmentCategory, options: contentOptions };
    const cell2 = { text: name, options: contentOptions };
    return [cell1, cell2];
  });
  const headerOptions: PptxGenJS.default.TableCellProps = {
    bold: true,
    fontFace: "Arial",
    fontSize: 20,
    color: COLOR2,
  };
  const headerRow = [
    { text: "Therapeutic Category", options: headerOptions },
    { text: "Disease Area", options: headerOptions },
  ];
  rows.unshift(headerRow);

  // add content
  slide.addTable(rows, {
    x: 0.67,
    y: 1.54,
    h: 4.6,
    w: 12,
    rowH: 0.42,
    autoPage: true,
    autoPageRepeatHeader: true,
    newSlideStartY: 1.54,
    autoPageCharWeight: 0,
    autoPageLineWeight: 0,
  });
  let num = 0;
  // @ts-ignore
  num = slide["_slideNum"];
  return num;
};

const addLimitedDistributionNetworkSlides = (
  pres: PptxGenJS.default,
  content: { brandName: string; genericName: string; manufacturer: string }[]
): number | null => {
  const rows = content.map(
    ({ brandName, genericName, manufacturer }, index) => {
      const bgColor = isEven(index) ? COLOR6 : COLOR5;
      const contentOptions: PptxGenJS.default.TableCellProps = {
        fontFace: "Arial",
        fill: { color: bgColor },
        color: COLOR2,
        fontSize: 20,
      };
      const cell1 = { text: brandName, options: contentOptions };
      const cell2 = { text: genericName, options: contentOptions };
      const cell3 = { text: manufacturer, options: contentOptions };
      return [cell1, cell2, cell3];
    }
  );
  const headerOptions: PptxGenJS.default.TableCellProps = {
    bold: true,
    fontFace: "Arial",
    fontSize: 20,
    color: COLOR2,
  };
  const headerRow = [
    { text: "Brand Name", options: headerOptions },
    { text: "Generic Name", options: headerOptions },
    { text: "Biopharmaceutical Company", options: headerOptions },
  ];
  // add content
  const maxContentLengthPerSlide = 135;
  const splittedRows = splitToPages(rows, maxContentLengthPerSlide);
  let globalSlideNum: number | null = null;
  let currGlobalSlideNum: number = 0;
  splittedRows.forEach((rs) => {
    const slide = pres.addSlide({ masterName: "LDN_TABLE" });
    // @ts-ignore
    currGlobalSlideNum = slide["_slideNum"];
    globalSlideNum = updateIfNull(globalSlideNum, currGlobalSlideNum);
    rs.unshift(headerRow);
    slide.addTable(rs, {
      x: 0.67,
      y: 1.54,
      h: 4.6,
      w: 12,
      rowH: 0.42,
    });
  });
  return globalSlideNum;
};

const addTechnologyOfferingsSlides = (
  pres: PptxGenJS.default,
  content: { name: string; description: string }[]
) => {
  const rows = content.map(({ name, description }, index) => {
    const bgColor = isEven(index) ? COLOR5 : COLOR6;
    const rowOptions: PptxGenJS.default.TableCellProps = {
      fontFace: "Arial",
      fill: { color: bgColor },
      color: COLOR2,
      fontSize: 16,
    };

    const cell1 = { text: name, options: { ...rowOptions, bold: true } };
    const cell2 = { text: description, options: rowOptions };
    return [cell1, cell2];
  });
  const maxContentLengthPerSlide = 1200;
  const splitted = splitToPages(rows, maxContentLengthPerSlide);
  normalizeBgColors(splitted);

  let globalSlideNum: number | null = null;
  let currGlobalSlideNum: number = 0;

  splitted.forEach((rs) => {
    const slide = pres.addSlide({ masterName: "TECHNOLOGY_OFFERINGS_TABLE" });
    // @ts-ignore
    currGlobalSlideNum = slide["_slideNum"];
    globalSlideNum = updateIfNull(globalSlideNum, currGlobalSlideNum);
    slide.addTable(rs, {
      x: 0.67,
      y: 1.54,
      h: 4.6,
      w: 12,
      rowH: 0.42,
      colW: [3, 9],
    });
  });

  return globalSlideNum;
};

const addBusinessPerformanceSlide = (
  pres: PptxGenJS.default,
  content: { year: string; description: string }[]
): number | null => {
  const slide = pres.addSlide({ masterName: "BUSINESS_PERFORMANCE_TABLE" });
  const rowOptions: PptxGenJS.default.TableCellProps = {
    fontFace: "Arial",
    fontSize: 16,
    color: COLOR2,
  };

  const firstRowOptions: PptxGenJS.default.TableCellProps = {
    ...rowOptions,
    align: "left",
    valign: "middle",
    fill: { color: COLOR5 },
    bold: true,
  };
  const secondRowOptions: PptxGenJS.default.TableCellProps = {
    ...rowOptions,
    fill: { color: COLOR5 },
  };
  const rows = content.map(({ year, description }, index) => {
    const cell1 = { text: year, options: firstRowOptions };
    const cell2 = { text: description, options: secondRowOptions };
    return [cell1, cell2];
  });

  slide.addTable(rows, {
    x: 0.67,
    y: 1.54,
    h: 4.6,
    w: 12,
    rowH: 0.42,
    colW: [2, 10],
    autoPage: true,
    newSlideStartY: 1.54,
    autoPageCharWeight: -0.1,
    autoPageLineWeight: 0,
  });

  let num = 0;
  // @ts-ignore
  num = slide["_slideNum"];
  return num;
};

const addSwotAnalysisSlides = (
  pres: PptxGenJS.default,
  content: {
    strengths: string;
    weaknesses: string;
    opportunities: string;
    threats: string;
  }
): number | null => {
  const makeSwotCell = (
    subtitle: string,
    content: string,
    fillColor: string
  ) => {
    const cell = {
      text: [
        {
          text: subtitle,
          options: {
            fontFace: "Arial",
            fontSize: 18,
            color: COLOR3,
            breakLine: true,
            lineSpacing: 26,
            bold: true,
          },
        },
        {
          text: content,
          options: {
            fontFace: "Arial",
            fontSize: 16,
            color: COLOR2,
            bullet: true,
          },
        },
      ],
      options: {
        fill: { color: fillColor },
      },
    };
    return cell;
  };
  const sCell = makeSwotCell("Strengths", content.strengths, COLOR5);
  const wCell = makeSwotCell("Weaknesses", content.weaknesses, COLOR5);
  const oCell = makeSwotCell("Opportunities", content.opportunities, COLOR6);
  const tCell = makeSwotCell("Threats", content.threats, COLOR6);
  const rows = [
    [sCell, wCell],
    [oCell, tCell],
  ];
  const maxContentLengthPerSlide = 700;
  const maybeUngroupedRows = ungroupCellsIfNeeded(
    rows,
    maxContentLengthPerSlide
  );
  const splittedRows = splitToPages(
    maybeUngroupedRows,
    maxContentLengthPerSlide
  );

  normalizeBgColors(splittedRows);

  let globalSlideNum: number | null = null;
  let currGlobalSlideNum: number = 0;
  splittedRows.forEach((rs) => {
    const slide = pres.addSlide({ masterName: "SWOT_ANALYSIS_TABLE" });
    // @ts-ignore
    currGlobalSlideNum = slide["_slideNum"];
    globalSlideNum = updateIfNull(globalSlideNum, currGlobalSlideNum);
    slide.addTable(rs, {
      x: 0.67,
      y: 1.54,
      h: 0.1,
      w: 12,
      rowH: 0.42,
      autoPage: false,
    });
  });
  return globalSlideNum;
};

const addCorporateStrategySlides = (
  pres: PptxGenJS.default,
  content: {
    goal: string;
    tactic: string;
  }[]
): number | null => {
  const contentOptions: PptxGenJS.default.TableCellProps = {
    fontFace: "Arial",
    color: COLOR2,
    fontSize: 18,
    bullet: true,
  };
  const headerOptions: PptxGenJS.default.TableCellProps = {
    fontFace: "Arial",
    fontSize: 18,
    color: COLOR3,
    bold: true,
  };
  const rows = content.map(({ goal, tactic }, index) => {
    const bgColor = isEven(index) ? COLOR6 : COLOR5;
    const cell1: PptxGenJS.default.TableCell = {
      text: goal,
      options: {
        fill: { color: bgColor },
        margin: [in2pt(0.1), in2pt(0.1), in2pt(0.05), in2pt(0.05)], // margin is LRBT
        ...contentOptions,
      },
    };
    const cell2: PptxGenJS.default.TableCell = {
      text: tactic,
      options: { fill: { color: bgColor }, ...contentOptions },
    };
    return [cell1, cell2];
  });
  const headerRow = [
    { text: "Goals", options: headerOptions },
    { text: "Tactics", options: headerOptions },
  ];
  const maxContentLengthPerSlide = 600;
  const splittedRows = splitToPages(rows, maxContentLengthPerSlide);
  normalizeBgColors(splittedRows);
  let globalSlideNum: number | null = null;
  let currGlobalSlideNum: number = 0;
  splittedRows.forEach((rs) => {
    rs.unshift(headerRow);
    const slide = pres.addSlide({ masterName: "CORPORATE_STRATEGY_TABLE" });
    // @ts-ignore
    currGlobalSlideNum = slide["_slideNum"];
    globalSlideNum = updateIfNull(globalSlideNum, currGlobalSlideNum);
    slide.addTable(rs, {
      x: 0.67,
      y: 1.54,
      h: 4.6,
      w: 12,
      rowH: 0.42,
      colW: [3, 9],
      autoPage: false,
    });
  });
  return globalSlideNum;
};

const addOrganizationOverviewSlides = (
  pres: PptxGenJS.default,
  orgOverview: OrganizationOverview | null | undefined,
  basicInfo: BasicInfo | null | undefined
): number | null => {
  const genericPlaceholder = "Information not currently available";

  const slideNum = addParentOrgAndDescriptionSlides(
    pres,
    orgOverview,
    basicInfo
  );
  addMarketCoverageSlides(pres, genericPlaceholder, orgOverview);
  addOrganizationSlides(pres, genericPlaceholder, basicInfo);

  let accreditationTypes = [genericPlaceholder];
  if (isNotEmpty(orgOverview?.accreditationType)) {
    accreditationTypes = orgOverview!
      .accreditationType!.map((x) => x.content)
      .filter(reallyNotEmpty);
  }
  addAccreditationSlides(pres, accreditationTypes, genericPlaceholder);

  return slideNum;
};

const orgOverviewSubtitleOptions: PptxGenJS.default.TextPropsOptions = {
  x: 0.67,
  y: 1.54,
  w: 5.83,
  h: 0.42,
  color: COLOR3,
  bold: true,
  fontFace: "Arial",
  fontSize: 20,
  valign: "top",
};

const orgOverviewRegularContentOptions: PptxGenJS.default.TableCellProps = {
  fontFace: "Arial",
  fontSize: 18,
  valign: "top",
  color: COLOR2,
  // breakLine: true
};

const orgOverviewBulletedContentOptions: PptxGenJS.default.TableCellProps = {
  ...orgOverviewRegularContentOptions,
  bold: true,
  margin: [in2pt(0.1), 0, 0, 0],
  bullet: { indent: 20 },
};

const addParentOrgAndDescriptionSlides = (
  pres: PptxGenJS.default,
  orgOverview: OrganizationOverview | null | undefined,
  basicInfo: BasicInfo | null | undefined
): number | null => {
  const genericPlaceholder = "Not Applicable";

  const mergersPlaceholder =
    "No mergers or acquisitions in 2018 or 2019 were identified for this company.";

  let slideNum = null;

  const slide = pres.addSlide({ masterName: "ORG_OVERVIEW" });

  slide.addText(
    "Parent Organization and Description",
    orgOverviewSubtitleOptions
  );

  const parentOrgAndDescriptionData = [];

  const parentOrgName = isNotEmpty(orgOverview?.parentOrganizationName)
    ? orgOverview!.parentOrganizationName!
    : genericPlaceholder;
  const parentOrgNameContent = prepareOrgOverviewRegularSectionContent(
    "Parent Organization:",
    parentOrgName
  );
  parentOrgAndDescriptionData.push(...parentOrgNameContent);

  const ownershipType = isNotEmpty(basicInfo?.ownershipType)
    ? basicInfo!.ownershipType!
    : genericPlaceholder;
  const ownershipTypeContent = prepareOrgOverviewRegularSectionContent(
    "Ownership Type:",
    ownershipType
  );
  parentOrgAndDescriptionData.push(...ownershipTypeContent);

  let acquisitionContentRemainder: string[] = [];
  const prepareAcContent = (x: string) => {
    return {
      text: x,
      options: {
        ...orgOverviewRegularContentOptions,
        margin: [0, 0, 0, in2pt(0.3)],
      } as PptxGenJS.default.TableCellProps,
    };
  };

  const acquisition = isNotEmpty(orgOverview?.acquisition)
    ? orgOverview!.acquisition!
    : mergersPlaceholder;
  const ac = acquisition.replace(/\r?\n|\r/gm, " \n").split(" ");

  let firstSlideLimit = findLastWordWithDot(ac.slice(0, 100)) + 1;
  const doesFitIntoFirstSlide = ac.length < firstSlideLimit;
  let firstSlideAcContent: string[] = [];

  if (doesFitIntoFirstSlide) {
    firstSlideAcContent = ac;
  } else {
    firstSlideAcContent = ac.slice(0, firstSlideLimit);
    acquisitionContentRemainder = splitAcquisitionContent(
      ac.slice(firstSlideLimit, ac.length)
    );
  }

  const firstSlideAc = prepareAcContent(firstSlideAcContent.join(" "));
  const acquisitionContent = [
    {
      text: "Mergers and Acquisition:",
      options: orgOverviewBulletedContentOptions,
    },
    firstSlideAc,
  ];
  parentOrgAndDescriptionData.push(...acquisitionContent);

  slide.addTable(
    parentOrgAndDescriptionData.map((x) => [x]),
    {
      x: 0.67,
      y: 2.1,
      w: 12,
      h: 4.46,
      rowH: 0.37,
    }
  );
  // @ts-ignore
  slideNum = slide["_slideNum"];

  if (isNotEmpty(acquisitionContentRemainder)) {
    const prepared = acquisitionContentRemainder.map((x) => [
      [prepareAcContent(x)],
    ]);

    prepared.forEach((r) => {
      const slide = pres.addSlide({ masterName: "ORG_OVERVIEW" });
      slide.addText(
        "Parent Organization and Description",
        orgOverviewSubtitleOptions
      );
      r.unshift([
        {
          text: "Mergers and Acquisition:",
          options: orgOverviewBulletedContentOptions,
        },
      ]);
      slide.addTable(r, {
        x: 0.67,
        y: 2.1,
        h: 0.42,
        w: 12,
        rowH: 0.37,
        autoPage: false,
      });
    });
  }

  return slideNum;
};

const prepareOrgOverviewRegularSectionContent = (
  subtitle: string,
  content: string
): { text: string; options: PptxGenJS.default.TableCellProps }[] => {
  const c = [
    {
      text: subtitle,
      options: orgOverviewBulletedContentOptions,
    },
    {
      text: content,
      options: {
        ...orgOverviewRegularContentOptions,
        margin: [0, 0, 0, 21.5],
      } as PptxGenJS.default.TableCellProps,
    },
  ];
  return c;
};

const addMarketCoverageSlides = (
  pres: PptxGenJS.default,
  genericPlaceholder: string,
  orgOverview: OrganizationOverview | null | undefined
) => {
  const content = [];

  const marketCoverageDescription = isNotEmpty(
    orgOverview?.marketCoverageDescription
  )
    ? orgOverview!.marketCoverageDescription!
    : genericPlaceholder;
  const mcDesc = prepareOrgOverviewRegularSectionContent(
    "Description:",
    marketCoverageDescription
  );
  content.push(...mcDesc);

  const orgFacility = isNotEmpty(orgOverview?.organizationFacility)
    ? orgOverview!.organizationFacility!
    : genericPlaceholder;
  const orgF = prepareOrgOverviewRegularSectionContent(
    "Organization Facility:",
    orgFacility
  );
  content.push(...orgF);

  const facilityName = isNotEmpty(orgOverview?.facilityName)
    ? orgOverview!.facilityName!
    : genericPlaceholder;
  const facN = prepareOrgOverviewRegularSectionContent(
    "Facility Name:",
    facilityName
  );
  content.push(...facN);

  const marketCoverageCount = isNotEmpty(orgOverview?.marketCoverageCount)
    ? orgOverview!.marketCoverageCount!.toString()
    : genericPlaceholder;
  const mcc = prepareOrgOverviewRegularSectionContent(
    "Count:",
    marketCoverageCount
  );
  content.push(...mcc);

  const splitted = splitToPages(
    content.map((x) => [x]),
    700
  );
  splitted.forEach((rs) => {
    const slide = pres.addSlide({ masterName: "MARKET_COVERAGE" });
    slide.addTable(rs, {
      x: 0.67,
      y: 2.1,
      w: 12,
      h: 4.46,
      rowH: 0.37,
    });
  });
};

const addOrganizationSlides = (
  pres: PptxGenJS.default,
  genericPlaceholder: string,
  basicInfo: BasicInfo | null | undefined
) => {
  const content = [];
  // address
  let address = genericPlaceholder;
  if (isNotEmpty(basicInfo?.address1)) {
    const a2 = basicInfo?.address2 ? basicInfo.address2 + "\n" : "";
    const cityStateZip =
      "" +
      (basicInfo?.city ?? "") +
      " " +
      (basicInfo?.state ?? "") +
      " " +
      (basicInfo?.zipcode ?? "");
    address = basicInfo?.address1! + `\n` + a2 + cityStateZip;
  }
  const addrContent = prepareOrgOverviewRegularSectionContent(
    "Address:",
    address
  );
  content.push(...addrContent);

  const website = isNotEmpty(basicInfo?.website)
    ? basicInfo?.website!
    : genericPlaceholder;
  const wsContent = prepareOrgOverviewRegularSectionContent(
    "Website:",
    website
  );
  content.push(...wsContent);

  const phone1 = isNotEmpty(basicInfo?.phone1)
    ? basicInfo?.phone1!
    : genericPlaceholder;
  const phoneContent = prepareOrgOverviewRegularSectionContent(
    "Phone:",
    phone1
  );
  content.push(...phoneContent);

  const foundedYear = isNotEmpty(basicInfo?.foundedYear)
    ? basicInfo!.foundedYear!.toString()
    : genericPlaceholder;
  const fyContent = prepareOrgOverviewRegularSectionContent(
    "Founded Year:",
    foundedYear
  );
  content.push(...fyContent);

  const splitted = splitToPages(
    content.map((x) => [x]),
    700
  );
  splitted.forEach((rs) => {
    const slide = pres.addSlide({ masterName: "ORG_DATA" });
    slide.addTable(rs, {
      x: 0.67,
      y: 2.1,
      w: 12,
      h: 4.46,
      rowH: 0.37,
    });
  });
};

const addAccreditationSlides = (
  pres: PptxGenJS.default,
  accreditationTypes: string[],
  genericPlaceholder: string
) => {
  const slide = pres.addSlide({ masterName: "ORG_ACCREDITATION" });

  let text = accreditationTypes.map((x) => {
    return {
      text: x,
      options: {
        bullet: true,
        color: COLOR2,
        fontFace: "Arial",
        fontSize: 18,
      },
    };
  });
  slide.addText(text, { x: 0.67, y: 2.1, w: 12, h: 4.85, valign: "top" });
};

const splitToPages = (
  rows: PptxGenJS.default.TableCell[][],
  maxContentLengthPerSlide: number
): PptxGenJS.default.TableCell[][][] => {
  let currSlideLength = 0;
  let slidesContent: PptxGenJS.default.TableCell[][][] = [];
  let currentSlideContent: PptxGenJS.default.TableCell[][] = [];

  rows.forEach((row) => {
    const maxLength = getMaxLengthInRow(row);
    if (
      currSlideLength + maxLength > maxContentLengthPerSlide &&
      isNotEmpty(currentSlideContent)
    ) {
      slidesContent.push(currentSlideContent);
      currentSlideContent = [];
      currSlideLength = 0;
    }
    currentSlideContent.push(row);
    currSlideLength += maxLength;
  });
  if (isNotEmpty(currentSlideContent)) {
    slidesContent.push(currentSlideContent);
  }
  return slidesContent;
};

const getMaxLengthInRow = (row: PptxGenJS.default.TableCell[]): number => {
  const contents = row.map(getTextFromCell);
  const contentLengths = contents.map((x) => x.length);
  const maxLength = Math.max(...contentLengths);
  return maxLength;
};

const getTextFromCell = (cell: PptxGenJS.default.TableCell): string => {
  if (cell.text === undefined || cell.text === null) {
    return "";
  } else if (typeof cell.text === "string") {
    return cell.text;
  } else {
    const cells = cell.text as PptxGenJS.default.TableCell[];
    return cells.reduce<string>((acc, x) => {
      const text = getTextFromCell(x);
      return acc + "|" + text;
    }, "");
  }
};

const ungroupCellsIfNeeded = (
  rows: PptxGenJS.default.TableCell[][],
  maxContentLengthPerSlide: number
): PptxGenJS.default.TableCell[][] => {
  const isNeeded = some(rows, (row) => {
    const maxLength = getMaxLengthInRow(row);
    return maxLength > maxContentLengthPerSlide;
  });
  const result = isNeeded ? ungroupCells(rows) : rows;
  return result;
};

const ungroupCells = (
  rows: PptxGenJS.default.TableCell[][]
): PptxGenJS.default.TableCell[][] => {
  const flattened = rows.flat();
  const ungrouped = flattened.map((x) => [x]);
  return ungrouped;
};

const addSlideTitle = (slide: PptxGenJS.default.Slide, title: string) => {
  slide.addText(title, {
    x: 0.67,
    y: 0.97,
    w: 12,
    h: 0.44,
    fontFace: "Arial",
    fontSize: 26,
    color: COLOR3,
    bold: true,
  });
};

const normalizeBgColors = (splittedRows: PptxGenJS.default.TableCell[][][]) => {
  splittedRows.map((rows) => {
    if (rows.length === 1) {
      rows.forEach((row) =>
        row.forEach((cell) => {
          if (cell.options?.fill?.color != null) {
            cell.options!.fill!.color! = COLOR5;
          }
        })
      );
    }
  });
};
